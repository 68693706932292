import { createApi } from '@reduxjs/toolkit/query/react';
import { HomePageData, Taxon } from 'types/types';
import { Action, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { RootState } from 'redux/appStore';
import gatewayBaseQuery from 'lib/gateway/gatewayBaseQuery';
import { apiGatewayEndpoints } from 'lib/gateway/endpoints';
import transformHomePageData from 'lib/transforms/homepage';
import { GeoCity } from 'types/geolocation';
import { transformProductCitiesJson } from 'lib/transforms/location';
import { transformTaxonJson } from 'lib/transforms';

export function isHydrateAction(action: Action): action is PayloadAction<RootState> {
  return action.type === HYDRATE;
}

const commonApi = createApi({
  reducerPath: 'commonApi',
  baseQuery: gatewayBaseQuery(),
  extractRehydrationInfo(action, { reducerPath }): any {
    if (isHydrateAction(action)) return action.payload[reducerPath];
  },
  endpoints: (builder) => ({
    fetchHomepageData: builder.query<HomePageData, void>({
      query: () => apiGatewayEndpoints.GetHomepage,
      transformResponse: transformHomePageData,
    }),
    fetchGeoCities: builder.query<GeoCity[], void>({
      query: () => apiGatewayEndpoints.GetCities,
      transformResponse: transformProductCitiesJson,
    }),
    fetchTaxons: builder.query<Taxon[], void>({
      query: () => apiGatewayEndpoints.GetCategories,
      transformResponse: (data: any) => data?.map(transformTaxonJson),
    }),
  }),
});

export const { useFetchHomepageDataQuery, useFetchGeoCitiesQuery, useFetchTaxonsQuery } = commonApi;

export default commonApi;
