import { useEffect } from 'react';
import useStore from 'store';
import { trackUserRegister } from '../modules/tracking/events/events';

const UserRegistrationProvider = () => {
  const [newRegistration, setNewRegistration] = useStore('newRegistration');
  const [customer] = useStore('customer');

  useEffect(() => {
    if (!newRegistration || !customer) {
      return;
    }

    (async () => {
      if (!customer) {
        return;
      }

      await trackUserRegister({
        customer,
      });
    })();

    setNewRegistration(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, newRegistration]);

  return null;
};

export default UserRegistrationProvider;
