import { NotNullCoordinates } from 'types/geolocation';
import { EARTH_RADIUS_IN_KM } from 'constants/geolocation';

// It calculates the distance between two coordinates in kilometers
const getDistance = (p1: NotNullCoordinates, p2: NotNullCoordinates): number => {
  const distanceLatitudes = getRadians(p2.latitude - p1.latitude);
  const distanceLongitudes = getRadians(p2.longitude - p1.longitude);
  const lat1 = getRadians(p1.latitude);
  const lat2 = getRadians(p2.latitude);

  const a =
    Math.sin(distanceLatitudes / 2) * Math.sin(distanceLatitudes / 2) +
    Math.sin(distanceLongitudes / 2) *
      Math.sin(distanceLongitudes / 2) *
      Math.cos(lat1) *
      Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return EARTH_RADIUS_IN_KM * c;
};

function getRadians(value: number): number {
  return (value * Math.PI) / 180;
}

export default getDistance;
