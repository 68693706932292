import AbstractTag from './AbstractTag';

export default class TaxonClickTag extends AbstractTag {
  constructor() {
    super();

    this.addProp(AbstractTag.EVENT, 'navigation_click');
    this.addProp(AbstractTag.EVENT_NAME, 'navigation_click');
  }

  set navigationLabel(value: string) {
    this.addProp('navigation_label', value);
  }
}
