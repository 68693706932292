import { GEOFILTER_TYPE } from 'constants/geolocation';
import { getPublicRuntimeConfig } from 'lib/api';
import { mapCityToSlug, mapSlugToCity } from 'lib/geolocation/mapToGeoCity';
import { Coordinates, GeoCity, GeoFilter } from 'types/geolocation';
import { ProductAddress } from 'types/types';

interface createGeoFilterProps {
  label: string | null;
  coordinates: Coordinates | null;
  type: GEOFILTER_TYPE;
  listHeading: string | null;
  city: string | null;
}

export const createGeoFilter = ({
  label,
  coordinates,
  type,
  listHeading,
  city,
}: createGeoFilterProps): GeoFilter => {
  return {
    label,
    query: {
      latitude: coordinates?.latitude || null,
      longitude: coordinates?.longitude || null,
    },
    type,
    listHeading,
    city,
  };
};

export const mapAddressToGeoFilter = (address: ProductAddress): GeoFilter => {
  const citySlug = mapCityToSlug(address.city);
  return getGeoFilterFromCoordinatesAndSlug(
    { latitude: address.latitude, longitude: address.longitude },
    citySlug
  );
};

export const mapIpCoordinatesToGeoFilter = (coordinates: Coordinates) =>
  createGeoFilter({
    label: null,
    listHeading: getPublicRuntimeConfig().ipLocationConfig.header,
    coordinates: coordinates,
    type: GEOFILTER_TYPE.IP,
    city: null,
  });

export const getDefaultLocationFilter = () => {
  return mapIpCoordinatesToGeoFilter(getPublicRuntimeConfig().defaultLocation);
};

export const mapGeoCityToGeoFilter = (geoCity: GeoCity): GeoFilter => {
  return createGeoFilter({
    label: geoCity.name,
    listHeading: geoCity.name,
    coordinates: { latitude: geoCity.latitude, longitude: geoCity.longitude },
    type: GEOFILTER_TYPE.CITY,
    city: geoCity.name,
  });
};

export const mapPlaceResultToGeoFilter = (place: google.maps.places.PlaceResult): GeoFilter => {
  const mapsLatLng: google.maps.LatLng | null = place.geometry?.location || null;

  const coordinates: Coordinates | null = mapsLatLng
    ? { latitude: mapsLatLng.lat(), longitude: mapsLatLng.lng() }
    : null;

  return createGeoFilter({
    label: place?.name || null,
    coordinates,
    type: GEOFILTER_TYPE.AUTOCOMPLETE,
    listHeading: getCityFromPlaceResult(place),
    city: getCityFromPlaceResult(place),
  });
};

export const getCityFromPlaceResult = (place: google.maps.places.PlaceResult): string | null => {
  // Ensure that address_components exist
  if (!place.address_components) {
    return place?.name || null;
  }

  // Search for the city in address_components
  for (const component of place.address_components) {
    if (component.types.includes('locality')) {
      return component.long_name;
    }
  }

  // If we can't find "locality", we can check for other types like administrative_area_level_2 or 3
  for (const component of place.address_components) {
    if (
      component.types.includes('administrative_area_level_2') ||
      component.types.includes('administrative_area_level_3')
    ) {
      return component.long_name;
    }
  }

  return place?.name || null;
};

export function getGeoFilterFromCoordinatesAndSlug(
  coordinates: Coordinates,
  locationSlug: string
): GeoFilter {
  const city = mapSlugToCity(locationSlug);

  return createGeoFilter({
    label: city,
    coordinates: coordinates,
    type: GEOFILTER_TYPE.AUTOCOMPLETE,
    listHeading: city,
    city: city,
  });
}

export const mapCookieValuesToGeoFilter = ({
  city,
  latitude,
  longitude,
  label,
}: {
  city: string;
  latitude: string;
  longitude: string;
  label: string;
}): GeoFilter => {
  return createGeoFilter({
    label,
    coordinates: { latitude: parseFloat(latitude), longitude: parseFloat(longitude) },
    type: GEOFILTER_TYPE.CITY,
    listHeading: city,
    city,
  });
};
