import { FaqArticle } from 'types/types';
import { doFetch, resolveApiUrl } from 'lib/api';
import { captureTypeErrorIfInvalid } from 'lib/error';
import API_ENDPOINTS from 'constants/routes/api';

export default async function fetchFaqArticles(
  page: number | null,
  itemsPerPage: number | null,
  channel?: string | null
): Promise<FaqArticle[]> {
  const endpoint = resolveApiUrl(API_ENDPOINTS.GetFaqArticles(page, itemsPerPage));

  const res = await doFetch(endpoint, {
    headers: channel ? { 'x-sylius-channel': channel } : {},
  });

  const articles: FaqArticle[] = await res.json();

  if (!articles || articles.length === 0) {
    return [];
  }

  articles.forEach((a) => captureTypeErrorIfInvalid('FaqArticle', a));
  return articles;
}
