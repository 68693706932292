import { doPost, resolveApiUrl } from 'lib/api';
import API_ENDPOINTS from 'constants/routes/api';

export async function updatePassword(
  customerId: number,
  token: string,
  currentPassword: string,
  newPassword: string
) {
  const endpoint = resolveApiUrl(API_ENDPOINTS.UpdatePassword);
  const res = await doPost(
    endpoint,
    { newPassword, currentPassword },
    { headers: { 'X-AUTH-TOKEN': token } }
  );

  return res.ok;
}

export default updatePassword;
