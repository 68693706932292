import { doPost, resolveApiUrl } from 'lib/api';
import API_ENDPOINTS from 'constants/routes/api';

export async function updateUser(
  token: string,
  fields: {
    firstName?: string;
    lastName?: string;
    email?: string;
    gender?: string;
    birthDate?: string | null;
    phoneNumber?: string | null;
  }
) {
  const endpoint = resolveApiUrl(API_ENDPOINTS.UpdateUser);

  const response = await doPost(endpoint, fields, { headers: { 'X-AUTH-TOKEN': token } });
  if (!response.ok) {
    throw new Error();
  }
}

export default updateUser;
