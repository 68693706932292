import { useState } from 'react';
import { CookieSettings } from 'types/types';
import SubmitButton from './SubmitButton';
import Dialog from './Dialog';
import { useCookies } from 'react-cookie';
import { isBrowser } from 'lib/env';
import { Trans, useTranslation } from 'react-i18next';
import { isSafari } from 'lib/browser';
import useStore from 'store';
import Toggle from './Toggle';
import Link from 'next/link';
import { PLACEHOLDER_TRANS_TEXT, Z_INDEX } from 'constants/common';
import { TALPA_COOKIES_LINK, TALPA_PRIVACY_LINK } from 'constants/business';

const settings = [
  {
    key: 'NECESSARY',
    title: 'Noodzakelijk',
    description:
      'Deze scripts en cookies zijn noodzakelijk voor een correcte werking van de site, om o.a. in te loggen en om te betalen. Daarnaast meten we gebruik. Deze cookies plaatsen we altijd.',
    defaultValue: true,
  },
  {
    key: 'FUNCTIONAL',
    title: 'Functioneel',
    description:
      'Deze scripts en cookies maken functionaliteiten mogelijk om onze diensten beter te gebruiken.',
  },
  {
    key: 'ANALYTICS',
    title: 'Analytisch',
    description:
      'Analytische scripts en cookies helpen ons je gedrag inzichtelijk te maken en om de site te verbeteren. Ook zorgen ze ervoor dat je feedback kunt doorsturen.',
  },

  {
    key: 'MARKETING',
    title: 'Marketing',
    description:
      'Deze scripts en tracking cookies worden geladen zodat wij en derden onze advertentiecampagnes kunnen optimaliseren en doormeten. Ze maken o.a. retargeting mogelijk. Daarnaast kunnen we meer persoonlijke content aanbieden.',
  },
  {
    key: 'SOCIAL_MEDIA',
    title: 'Sociale Media',
    description:
      'Deze scripts en tracking cookies maken het o.a. mogelijk dat je kunt inloggen met de accounts van sociale netwerken. De sociale netwerken kunnen hierdoor zien dat je gebruikt maakt van onze site en je buiten onze site volgen. Ze gebruiken deze informatie om te bepalen welke advertenties ze aan je tonen.',
  },
];

interface CookiePreferences {
  NECESSARY: true;
  FUNCTIONAL: false;
  ANALYTICS: false;
  MARKETING: false;
  SOCIAL_MEDIA: false;
}

export const CookiePopupDialog = ({
  dialogOpen,
  setDialogOpen,
  setCookiesAccepted,
  currentPreferences,
}: {
  dialogOpen: boolean;
  setDialogOpen: (value: boolean) => void;
  setCookiesAccepted: (value: CookieSettings) => void;
  currentPreferences?: CookiePreferences;
}) => {
  const [cookiesAcceptedLocal, setCookiesAcceptedLocal] = useState<CookiePreferences>(
    currentPreferences ?? {
      NECESSARY: true,
      FUNCTIONAL: false,
      ANALYTICS: false,
      MARKETING: false,
      SOCIAL_MEDIA: false,
    }
  );

  return (
    <Dialog
      setDialogOpen={setDialogOpen}
      dialogOpen={dialogOpen}
      title="Jouw Cookievoorkeuren "
      className=""
    >
      <div className="mt-2">
        {settings.map((setting) => {
          const key = setting.key as keyof CookieSettings;
          const toggleId = `toggle${key}`;
          return (
            <div key={setting.key} className="pb-4">
              <div className="flex flex-row items-center justify-between">
                <label htmlFor={toggleId} className="font-bold">
                  {setting.title}
                </label>
                <Toggle
                  disabled={setting.key === 'NECESSARY'}
                  id={toggleId}
                  className="scale-150"
                  checked={cookiesAcceptedLocal[key]}
                  onChange={(newValue) =>
                    setCookiesAcceptedLocal({
                      ...cookiesAcceptedLocal,
                      [setting.key]: newValue,
                    })
                  }
                />
              </div>
              <label htmlFor={toggleId} className="text-sm text-gray-500">
                {setting.description}
              </label>
            </div>
          );
        })}
      </div>

      <div className="flex flex-row mt-4">
        <SubmitButton
          className="mr-6"
          onClick={() => setCookiesAccepted(cookiesAcceptedLocal)}
          bgColorClass="bg-gray-400 hover:bg-gray-500"
        >
          Opslaan
        </SubmitButton>
        <SubmitButton
          onClick={() =>
            setCookiesAccepted({
              NECESSARY: true,
              FUNCTIONAL: true,
              ANALYTICS: true,
              MARKETING: true,
              SOCIAL_MEDIA: true,
            })
          }
          className="ml-6"
        >
          <Trans i18nKey="app.ui.agree_all" />
        </SubmitButton>
      </div>
    </Dialog>
  );
};

export const CookieFooterDialog = ({
  setDialogOpen,
  setCookiesAccepted,
}: {
  setDialogOpen: (value: boolean) => void;
  setCookiesAccepted: (value: CookieSettings) => void;
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const { t } = useTranslation();
  return (
    <div
      className={`fixed ${Z_INDEX.levelAboveMuiDrawer} bottom-zero left-0 right-0 py-4 bg-black bg-opacity-80 accept-cookies`}
    >
      <div className="container px-4 mb-4 text-sm text-white">
        {t('app.ui.cookie.first')}{' '}
        {!expanded ? (
          <div className="underline py-2 text-primary" onClick={() => setExpanded(!expanded)}>
            {t('app.ui.cookie.expand')}
          </div>
        ) : (
          <span>
            <Trans i18nKey="app.ui.cookie.second">
              {PLACEHOLDER_TRANS_TEXT}
              <Link href={TALPA_COOKIES_LINK} target="_blank" rel="noreferrer">
                {PLACEHOLDER_TRANS_TEXT}
              </Link>
              {PLACEHOLDER_TRANS_TEXT}
              <Link href={TALPA_PRIVACY_LINK} target="_blank" rel="noreferrer">
                {PLACEHOLDER_TRANS_TEXT}
              </Link>
              {PLACEHOLDER_TRANS_TEXT}
            </Trans>
          </span>
        )}
      </div>

      <div className="container flex flex-row px-4">
        <SubmitButton
          className="mr-6 underline"
          onClick={() => setDialogOpen(true)}
          hoverEnabled={false}
          bgColorClass="bg-transparent"
        >
          {t('app.ui.settingsButton')}
        </SubmitButton>

        <SubmitButton
          onClick={() => {
            setCookiesAccepted({
              NECESSARY: true,
              FUNCTIONAL: true,
              ANALYTICS: true,
              MARKETING: true,
              SOCIAL_MEDIA: true,
            });
          }}
          className="ml-6"
        >
          <Trans i18nKey="app.ui.agree" />
        </SubmitButton>
      </div>
    </div>
  );
};
export const CookieProvider = () => {
  const [cookies, setCookie] = useCookies(['GdprConsentByUser']);
  const [cookiePreferencesModalOpen, setCookiePreferencesModalOpen] = useStore(
    'cookiePreferencesModalOpen'
  );

  const onSetCookiesAccepted = (value: CookieSettings | null) => {
    setCookie(
      'GdprConsentByUser',
      { purposes: value },
      {
        expires: new Date(new Date().getTime() + 365 * 2 * 24 * 60 * 60 * 1000),
        secure: true,
        path: '/',
      }
    );

    setCookiePreferencesModalOpen(false);
    isBrowser() && window.location.reload();
  };

  const isDev = process.env.NODE_ENV === 'development';
  // NB: the cookies don't seem to work on Safari on localhost, probably due to http/https CORS constraints or something
  const isSafariDev = isSafari && isDev;

  if (isBrowser() && !cookies.GdprConsentByUser && !isSafariDev) {
    const footerDialog = (
      <CookieFooterDialog
        setDialogOpen={setCookiePreferencesModalOpen}
        setCookiesAccepted={onSetCookiesAccepted}
      />
    );

    return (
      <div>
        {!cookiePreferencesModalOpen && footerDialog}
        <CookiePopupDialog
          dialogOpen={cookiePreferencesModalOpen}
          setDialogOpen={setCookiePreferencesModalOpen}
          setCookiesAccepted={onSetCookiesAccepted}
        />
      </div>
    );
  }

  if (isBrowser() && cookiePreferencesModalOpen) {
    return (
      <div>
        <CookiePopupDialog
          dialogOpen={cookiePreferencesModalOpen}
          setDialogOpen={setCookiePreferencesModalOpen}
          setCookiesAccepted={onSetCookiesAccepted}
          currentPreferences={cookies.GdprConsentByUser && cookies.GdprConsentByUser.purposes}
        />
      </div>
    );
  }

  return null;
};
