import React from 'react';
import Script from 'next/script';
import config from 'next/config';

const { publicRuntimeConfig } = config();

const TealiumScript = () => {
  return (
    <Script
      id="tealium-js"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
              window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};
              window.utag_cfg_ovrd.noview = true;
              (function(a,b,c,d) {
              a='https://tags.tiqcdn.com/utag/talpa/${publicRuntimeConfig.tealiumLabelEnv}/utag.js';
              b=document;c='script';d=b.createElement(c);d.src=a;
              d.type='text/java'+c;d.async=true;
              a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a)})();
              `,
      }}
    />
  );
};

export default TealiumScript;
