import { Z_INDEX } from 'constants/common';
import useResponsive from 'src/hooks/useResponsive';
import classNames from 'classnames';
import { Fragment, useCallback, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { XIcon } from '@heroicons/react/outline';
import { useCookies } from 'react-cookie';
import config from 'next/config';
import { AppPromoMobile } from 'components/appPromoBanner/AppPromoMobile';
import { AppPromoDesktop } from 'components/appPromoBanner/AppPromoDesktop';
import { APP_PROMO_SHOWN_COOKIE, APP_PROMO_SHOWN_COOKIE_EXPIRATION } from 'constants/appStores';
import { Transition } from '@headlessui/react';

const routesInWhichToDisplayPromo = {
  homeWithLocation: '/in/',
  listingPage: '/aanbiedingen/',
};

const shouldDisplayPromo = (router: any) => {
  const asPath = router.asPath;
  if (asPath === '/') {
    return true;
  }

  return Object.values(routesInWhichToDisplayPromo).some((value: string) =>
    asPath.startsWith(value)
  );
};

export default function AppPromoBanner() {
  const {
    publicRuntimeConfig: { isAppPromoEnabled },
  } = config();
  const router = useRouter();
  const { isMobile } = useResponsive();
  const [cookies, setCookie] = useCookies([APP_PROMO_SHOWN_COOKIE]);
  const bannerRef = useRef<HTMLDivElement>(null);

  const [isShowing, setIsShowing] = useState<boolean>(true);

  const isInAllowedRoutes = useCallback((): boolean => {
    return shouldDisplayPromo(router);
  }, [router]);

  const handleClose = useCallback(() => {
    setIsShowing(false);

    setCookie(
      APP_PROMO_SHOWN_COOKIE,
      { hasClosedPromo: true, setAt: new Date().toISOString() },
      {
        expires: new Date(new Date().getTime() + APP_PROMO_SHOWN_COOKIE_EXPIRATION),
        secure: true,
        path: '/',
      }
    );
  }, [setCookie]);

  if (Boolean(cookies[APP_PROMO_SHOWN_COOKIE]) || !isInAllowedRoutes() || !isAppPromoEnabled) {
    return null;
  }

  const wrapperClass = classNames(
    `fixed ${Z_INDEX.level35} mb-0 bottom-0 rounded-tl-2xl rounded-tr-2xl rounded-br-none rounded-bl-none bg-white drop-shadow-[0_-5px_10px_rgba(0,0,0,0.25)] py-2 px-4`,
    { 'left-0 right-0': isMobile },
    { 'right-20 w-[36rem] mx-4': !isMobile }
  );

  const containerClassNames = classNames(
    `mb-2`,
    { 'mr-4 mt-[-1rem]': isMobile },
    { 'mx-4 mt-[-0.5rem]': !isMobile }
  );

  return (
    <Transition
      show={isShowing}
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <div className={wrapperClass} ref={bannerRef}>
        <div className={'flex justify-end mt-2'}>
          <XIcon onClick={handleClose} className="h-6 w-6 cursor-pointer" aria-hidden="true" />
        </div>
        <div className={containerClassNames}>
          {isMobile ? <AppPromoMobile /> : <AppPromoDesktop />}
        </div>
      </div>
    </Transition>
  );
}
