import { doFetch, resolveApiUrl } from 'lib/api';
import API_ENDPOINTS from 'constants/routes/api';

export default async function fetchSitemap(): Promise<string> {
  const endpoint = resolveApiUrl(API_ENDPOINTS.GetSitemap);

  const res = await doFetch(endpoint);

  return await res.text();
}
