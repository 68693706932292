export function omitUndefinedRecursively(obj: { [key: string]: any }) {
  Object.keys(obj).forEach(function (key) {
    // Get this value and its type
    var value = obj[key];
    var type = typeof value;
    if (type === 'object' && value !== null) {
      // Recurse...
      omitUndefinedRecursively(value);
    } else if (type === 'undefined') {
      // Undefined, remove it
      delete obj[key];
    }
  });
}

export function removeUndefined(obj: { [key: string]: any }): Object {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === undefined) {
      delete obj[key];
    }
  });

  return obj;
}

export function removeNullValueKeys(obj: { [key: string]: any }): Object {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null) {
      delete obj[key];
    }
  });

  return obj;
}
