import 'tailwindcss/tailwind.css';
import '../styles/globals.css';
import '../lib/i18n';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import App, { AppProps } from 'next/app';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ProgressBar from '@badrap/bar-of-progress';
import Router from 'next/router';
import { StoreProvider } from 'store';
import { CookieProvider } from 'components/CookieProvider';
import NewsletterSubscribeProvider from 'components/newsletter/NewsletterSubscribeProvider';
import AccountProvider from 'components/AccountProvider';
import config from 'next/config';
import UserRegistrationProvider from 'components/UserRegistrationProvider';
import TrackingCustomerProvider from 'components/TrackingCustomerProvider';
import AuthContext from 'components/auth/AuthContext';
import TealiumScript from 'components/TealiumScript';
import { Z_INDEX } from 'constants/common';
import { Provider as ReduxProvider } from 'react-redux';
import { wrapper } from 'redux/appStore';
import { IncomingMessage } from 'http';
import GlobalNextFont from 'components/common/GlobalNextFont';
import AppPromoBanner from 'components/appPromoBanner/AppPromoBanner';
import NoSSR from 'components/noSSR/NoSSR';
import { NotificationProvider } from 'src/contexts/notifications/ToastContext';
import { GrowthBookProvider } from 'components/growthbook/GrowthBookProvider';
import { getGrowthBookData } from 'components/growthbook/growthbook.utils';
import { GrowthBookSSRData } from '@growthbook/growthbook-react';
import { ResizeProvider } from 'src/contexts/Resize/ResizeContext';
import { PreserveInfiniteScrollProvider } from 'src/contexts/TaxonListing/PreserveInfiniteScroll.provider';

const { publicRuntimeConfig } = config();

//Progress Bar between pages
const progress = new ProgressBar({
  size: 4,
  color: '#60A4FB',
  className: Z_INDEX.level45,
  delay: 100,
});

//Binding events.
Router.events.on('routeChangeStart', progress.start);
Router.events.on('routeChangeComplete', progress.finish);
Router.events.on('routeChangeError', progress.finish);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      structuralSharing: false,
    },
  },
});

interface PageProps {
  growthBookData: GrowthBookSSRData;
}

interface Props extends Omit<AppProps, 'pageProps'> {
  pageProps: AppProps['pageProps'] & PageProps;
}

const MyApp = ({ Component, ...rest }: Props) => {
  const { store, props } = wrapper.useWrappedStore(rest);
  const pageProps = props.pageProps;
  const growthBookData = props.growthBookData;
  const { isNewsletterEnabled, googleCaptchaSiteKey } = publicRuntimeConfig;
  return (
    <>
      <GlobalNextFont />
      <ReduxProvider store={store}>
        <GrowthBookProvider data={growthBookData}>
          <AuthContext session={pageProps?.session}>
            <QueryClientProvider client={queryClient}>
              <StoreProvider>
                <GoogleReCaptchaProvider reCaptchaKey={googleCaptchaSiteKey} scriptProps={{}}>
                  <TealiumScript />
                  <ResizeProvider>
                    <NotificationProvider>
                      <PreserveInfiniteScrollProvider>
                        <Component {...pageProps} />
                      </PreserveInfiniteScrollProvider>
                      {/* NoSSR is a component which only renders on the client side. Needed to stop 'hydration warnings' on */}
                      {/* dev due to both child components being rendered based on cookies which are not available on the */}
                      {/* server side. Proper fix should be implemented in https://emesadev.atlassian.net/browse/AVDD-8601 */}
                      <NoSSR>
                        <AppPromoBanner />
                        <CookieProvider />
                      </NoSSR>
                    </NotificationProvider>
                  </ResizeProvider>
                  {isNewsletterEnabled && <NewsletterSubscribeProvider />}
                  <UserRegistrationProvider />
                  <AccountProvider />
                  <TrackingCustomerProvider />
                </GoogleReCaptchaProvider>
              </StoreProvider>
            </QueryClientProvider>
          </AuthContext>
        </GrowthBookProvider>
      </ReduxProvider>
    </>
  );
};

MyApp.getInitialProps = wrapper.getInitialAppProps(() => async (context): Promise<Props> => {
  const { ctx } = context;
  // // If we hit this during a client-side render, we fake the items we look for
  if (!ctx.req) {
    ctx.req = {
      url: window.location.pathname,
      headers: {
        host: window.location.host,
        cookie: document.cookie,
        'user-agent': navigator.userAgent,
      },
    } as IncomingMessage;
  }

  const {
    req: {
      headers: { cookie },
    },
  } = ctx;
  const growthBookData = await getGrowthBookData(cookie);

  ctx.req.growthBookData = growthBookData;

  return {
    ...(await App.getInitialProps(context)).pageProps,
    growthBookData,
  };
});

export default MyApp;
