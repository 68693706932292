import { ReservationAvailability } from 'types/product';
import api from 'constants/routes/api';
import { doFetch, resolveApiUrl } from 'lib/api';
import { transformReservationAvailabilities } from 'lib/transforms/product';

export interface FetchVariantAvailabilitiesForMonthQuery extends FetchAvailabilitiesForMonthQuery {
  variantId: number;
}

export interface FetchAvailabilitiesForMonthQuery {
  quantity: number;
  date?: string;
  showFirstAvailability?: boolean;
}

export async function fetchAvailabilitiesForMonth({
  variantId,
  quantity,
  date,
  showFirstAvailability = false,
}: FetchVariantAvailabilitiesForMonthQuery): Promise<ReservationAvailability[]> {
  const endpoint = resolveApiUrl(api.GetReservationAvailabilitiesForMonth(variantId));

  const res = await doFetch(endpoint, {}, { persons: quantity, date, showFirstAvailability });

  if (!res.ok) {
    console.warn('Failed to fetch reservation availabilities');
    return [];
  }

  const reservations = await res.json();

  return transformReservationAvailabilities(reservations);
}

export default fetchAvailabilitiesForMonth;
