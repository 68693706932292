import { ExtendedProduct } from 'types/types';
import { doFetch, resolveApiUrl } from 'lib/api';
import { transformExtendedProductJson } from 'lib/transforms';
import { captureTypeErrorIfInvalid } from 'lib/error';
import API_ENDPOINTS from 'constants/routes/api';

export type FetchProductPreview = {
  token: string;
};

export async function fetchProductPreview({
  token,
}: FetchProductPreview): Promise<ExtendedProduct | null> {
  const url = resolveApiUrl(API_ENDPOINTS.GetProductPreview(token));
  const res = await doFetch(url);

  if (!res.ok) {
    throw new Error();
  }
  const productJson = await res.json();

  const data = transformExtendedProductJson(productJson);
  captureTypeErrorIfInvalid('ExtendedProduct', data);

  return data;
}

export default fetchProductPreview;
