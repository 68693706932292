const parseCookieString = (str: string | undefined) => {
  if (typeof str !== 'string' || str.length === 0) {
    return {};
  }

  return str
    .split(';')
    .map((v: string) => v.split('='))
    .reduce((acc: { [key: string]: string }, v: string[]) => {
      acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
      return acc;
    }, {});
};

export { parseCookieString };
