import { FaqArticle } from 'types/types';
import { doFetch, resolveApiUrl } from 'lib/api';
import { captureTypeErrorIfInvalid } from 'lib/error';
import API_ENDPOINTS from 'constants/routes/api';

export default async function fetchFaqArticle(id: number): Promise<FaqArticle> {
  const endpoint = resolveApiUrl(API_ENDPOINTS.GetFaqArticle(id));
  const res = await doFetch(endpoint);

  const article = await res.json();

  captureTypeErrorIfInvalid('FaqArticle', article);
  return article;
}
