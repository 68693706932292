import { doPost, resolveApiUrl } from 'lib/api';
import { CONTENT_TYPES } from 'constants/enums';
import API_ENDPOINTS from 'constants/routes/api';

export async function resetPassword(
  passwordResetToken?: string,
  newPassword?: string,
  captcha?: string | null
): Promise<{ email: string }> {
  if (newPassword && passwordResetToken && captcha) {
    const endpoint = resolveApiUrl(API_ENDPOINTS.ResetPassword);
    const response = await doPost(
      endpoint,
      { passwordResetToken, newPassword, captcha },
      {
        headers: {
          accept: CONTENT_TYPES.APPLICATION_JSON,
          'Content-Type': CONTENT_TYPES.APPLICATION_JSON,
        },
        mode: 'cors',
      }
    );

    if (response.ok) {
      const result = await response.json();

      return {
        email: result.email,
      };
    }
  }

  return { email: '' };
}

export default resetPassword;
