import { Customer } from './types';

export enum TrackingPageType {
  home = 'home',
  listing = 'category',
  maps = 'maps',
  search = 'search',
  product = 'product',
  login = 'login',
  account = 'account',
  blog = 'blog',
  checkout = 'checkout',
  purchase = 'purchase',
  faq = 'faq',
  subscribed = 'subscribed',
  subscribe = 'subscribe',
  unsubscribe = 'unsubscribe',
  extraatjeVoorJou = 'extraatjeVoorJou',
  unknown = 'unknown',
  jumboLanding = 'jumboLanding',
}

export enum TrackingProductList {
  mainProducts = 'main-products',
  mainDeal = 'dikke dag deal',
  popularProducts = 'meest populair',
  newProducts = 'nieuwe producten',
  specialForYouProducts = 'speciaal voor jou',
  lastSeenProducts = 'laatst bekeken deals',
  cartReminderProducts = 'cart reminder',
  pageProducts = 'page-products',
  mostSoldProducts = 'meest verkochte producten',
  relatedProducts = 'anderen kochten ook',
  home = 'home',
  category = 'category',
  maps = 'maps',
  search = 'search',
  product = 'product',
  cart = 'cart',
  checkout = 'checkout',
  purchase = 'purchase',
  accountOrder = 'account-order',
  noResultsOtherDeals = 'populaire deals',
  recommendedProducts = 'recommended products',
  locationProducts = 'location products',
  unknown = 'unknown',
  jumboLanding = 'jumboLanding',
}

export interface TrackingContext {
  customer: Customer | null;
  hasAcceptedMarketingCookies: boolean | null;
}

export interface TrackingValue {}

export interface TrackingPromoImpression extends TrackingValue {
  promo_id: string;
  promo_name: string | null;
  promo_creative: string;
  promo_position: number;
}

export interface TrackingProduct extends TrackingValue {
  product_id: string;
  product_name: string;
  product_category_id: string | null;
  product_category: string | null;
  product_price: string | null;
  original_product_price: string | null;
  product_type: string;
  product_sku?: string | null;
  product_variant?: string | null;
  product_position?: string;
  product_list?: string;
  tradetracker_category_id: string | null;
}

export interface CartProduct extends TrackingProduct {
  product_quantity: string;
}

export interface UserId extends TrackingValue {
  user_id: string | null;
}

export interface UserGigyaUid extends TrackingValue {}
