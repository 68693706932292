import fetchSitemap from 'api/static-content/fetchSitemap';
import fetchBannersForTaxon from 'api/static-content/fetchBannersForTaxon';
import fetchTextBannerLocationMap from 'api/static-content/fetchTextBannerLocationMap';
import subscribeToNewsletter from 'api/static-content/subscribeToNewsletter';
import fetchFaqArticle from 'api/static-content/fetchFaqArticle';
import fetchFaqArticles from 'api/static-content/fetchFaqArticles';
import fetchFaqCategories from 'api/static-content/fetchFaqCategories';
import fetchFaqCategory from 'api/static-content/fetchFaqCategory';

const staticContentApi = {
  fetchSitemap,
  fetchBannersForTaxon,
  fetchTextBannerLocationMap,
  subscribeToNewsletter,
  fetchFaqArticle,
  fetchFaqArticles,
  fetchFaqCategories,
  fetchFaqCategory,
};

export default staticContentApi;
