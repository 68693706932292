import { getPublicRuntimeConfig } from 'lib/api';

export const APP_PROMO_SHOWN_COOKIE = 'appPromoShown';
export const APP_PROMO_SHOWN_COOKIE_EXPIRATION = 21 * 24 * 60 * 60 * 1000; // 3 weeks

const { appleStoreId, googlePlayStoreId } = getPublicRuntimeConfig();

export const APP_PROMO_BANNER_ID = '-1';

export const APP_PROMO_TRACKING_CODES = {
  imageGoogle: 'app promo - google play - via logo',
  buttonApple: 'app promo - apple store - via button',
  imageApple: 'app promo - apple store - via logo',
  buttonGoogle: 'app promo - google play - via button',
  desktopApple: 'app promo - desktop - apple logo',
  desktopGoogle: 'app promo - desktop - google play logo',
  bannerClosed: 'app promo - banner closed',
  desktopButton: 'app promo - desktop - via button',
  imageDesktop: 'app promo - desktop - via logo',
} as const;

export const APP_STORE_CAMPAIGNS = {
  footer: 'FooterAppStoreButton',
  popOver: 'PopOver_bottom',
} as const;

export const APP_STORE_EXTERNAL_REDIRECT = 'https://app.adjust.com/1a7mwegr';
export const APPSTORE_CAMPAIGN_KEY = 'campaign';

export const APPLE_STORE_APP_URL = `https://apps.apple.com/nl/app/actievandedag/${appleStoreId}`;
export const GOOGLE_PLAY_STORE_APP_URL = `https://play.google.com/store/apps/details?id=${googlePlayStoreId}`;
export const DESKTOP_APP_URL = 'https://www.actievandedag.nl/blog/app';

type AppStoreBannerCode = (typeof APP_PROMO_TRACKING_CODES)[keyof typeof APP_PROMO_TRACKING_CODES];

type ApStoreBannerTrackingCode = {
  logo: AppStoreBannerCode;
  button: AppStoreBannerCode;
};

export type AppStoreCampaigns = (typeof APP_STORE_CAMPAIGNS)[keyof typeof APP_STORE_CAMPAIGNS];

export type AppStoreDetails = {
  url: string;
  imgSrc: string;
  description: string;
  trackingCodes: ApStoreBannerTrackingCode;
};

export enum AppStoreKeys {
  googlePlay = 'GOOGLE_PLAY',
  appleStore = 'APPLE_STORE',
  desktop = 'DEFAULT',
}

export const APP_STORES: Record<AppStoreKeys, AppStoreDetails> = {
  [AppStoreKeys.googlePlay]: {
    url: APP_STORE_EXTERNAL_REDIRECT,
    imgSrc: '/google-play-badge.png',
    description: 'app.app_promo.download.on_google_play',
    trackingCodes: {
      logo: APP_PROMO_TRACKING_CODES.imageGoogle,
      button: APP_PROMO_TRACKING_CODES.buttonGoogle,
    },
  },
  [AppStoreKeys.appleStore]: {
    url: APP_STORE_EXTERNAL_REDIRECT,
    imgSrc: '/apple-store-badge.svg',
    description: 'app.app_promo.download.on_apple_store',
    trackingCodes: {
      logo: APP_PROMO_TRACKING_CODES.imageApple,
      button: APP_PROMO_TRACKING_CODES.buttonApple,
    },
  },
  [AppStoreKeys.desktop]: {
    url: APP_STORE_EXTERNAL_REDIRECT,
    imgSrc: '',
    description: 'app.app_promo.textual_link',
    trackingCodes: {
      logo: APP_PROMO_TRACKING_CODES.desktopButton,
      button: APP_PROMO_TRACKING_CODES.imageDesktop,
    },
  },
};
