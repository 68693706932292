import AbstractTag from '../AbstractTag';

export default class NewsletterSubscribe extends AbstractTag {
  constructor() {
    super();

    this.addProp(AbstractTag.EVENT, 'newsletter_subscribe');
  }

  set location(value: string) {
    this.addProp('event_location', value);
  }
}
