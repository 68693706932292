import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BareTaxon, Taxon } from 'types/taxons';
import { RootState } from 'redux/appStore';
import { GeoPlace } from 'types/geolocation';
import { APP_HYDRATE } from 'redux/actions';
import { Product, TextBanner } from 'types/types';
import { Loading } from 'constants/common';

export interface CommonState {
  taxons: Taxon[];
  textBanner: TextBanner | null;
  activeTaxon: BareTaxon | null;
  newsletterSubscriptionLocation: GeoPlace | null;
  lastSeenProducts: {
    status: Loading;
    products: Product[];
  };
}

const initialState: CommonState = {
  taxons: [],
  activeTaxon: null,
  textBanner: null,
  newsletterSubscriptionLocation: null,
  lastSeenProducts: {
    status: Loading.IDLE,
    products: [],
  },
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setTaxons: (state, { payload }: PayloadAction<Taxon[]>) => {
      state.taxons = payload;
    },
    setTextBanner: (state, { payload }: PayloadAction<TextBanner | null>) => {
      state.textBanner = payload;
    },
    setActiveTaxon: (state, { payload }: PayloadAction<BareTaxon | null>) => {
      state.activeTaxon = payload;
    },
    setNewsletterSubscriptionLocation: (state, { payload }: PayloadAction<GeoPlace>) => {
      state.newsletterSubscriptionLocation = payload;
    },
    setLastSeenProducts: (state, { payload }: PayloadAction<Product[]>) => {
      state.lastSeenProducts.products = payload;
    },
    setLastSeenProductsStatus: (state, { payload }: PayloadAction<Loading>) => {
      state.lastSeenProducts.status = payload;
    },
    resetActiveTaxon: (state) => {
      state.activeTaxon = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(APP_HYDRATE, (clientState, { payload: { common } }) => {
      const newState = {
        ...clientState,
        ...common,
      };

      const isTaxonChanged =
        common.activeTaxon && common.activeTaxon.id !== clientState?.activeTaxon?.id;

      if (
        clientState.lastSeenProducts.products.length > 0 &&
        common.lastSeenProducts.status === Loading.IDLE &&
        !isTaxonChanged
      ) {
        return;
      }

      return newState;
    });
  },
});

export const {
  setTaxons,
  setActiveTaxon,
  setNewsletterSubscriptionLocation,
  resetActiveTaxon,
  setTextBanner,
  setLastSeenProducts,
  setLastSeenProductsStatus,
} = commonSlice.actions;

const slice = (state: RootState) => state.common;

export const selectTaxons = createSelector(slice, (s) => s.taxons);
export const selectTextBanner = createSelector(slice, (s) => s.textBanner);
export const getActiveTaxon = createSelector(slice, (state): BareTaxon | null => state.activeTaxon);

export const getNewsletterSubscriptionLocation = createSelector(
  slice,
  (s) => s.newsletterSubscriptionLocation
);
export const getSubscriptionLocationLabel = createSelector(
  slice,
  (s) => s.newsletterSubscriptionLocation?.label || null
);

export const selectLastSeenProducts = createSelector(slice, (s) => s.lastSeenProducts.products);
export const selectHasLastSeenProducts = createSelector(
  slice,
  (s) => s.lastSeenProducts.products.length > 0
);
export const selectLastSeenProductsStatus = createSelector(slice, (s) => s.lastSeenProducts.status);

export const selectIsLastSeenProductsIdle = createSelector(
  selectLastSeenProductsStatus,
  (status) => status === Loading.IDLE
);

export const selectIsLastSeenProductsLoading = createSelector(
  selectLastSeenProductsStatus,
  (status) => status === Loading.PENDING
);

export const selectIsLastSeenProductsFinished = createSelector(
  selectLastSeenProductsStatus,
  (status) => status === Loading.SUCCEEDED || status === Loading.FAILED
);

export default commonSlice.reducer;
