import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/appStore';
import { E164Number } from 'libphonenumber-js';
import { CartDraftReservation, Variant } from 'types/types';
import { fetchAvailableDates, fetchAvailableTimeslots } from 'redux/cart/reservation.thunks';
import { APP_HYDRATE } from 'redux/actions';
import { Loading } from 'constants/common';
import { PRODUCT_MINIMUM_CART_QUANTITY_DEFAULT } from 'constants/product';

export interface ReservationState {
  variantId: number | null;
  isReservationAvailable: boolean;
  selectedDate: string | null;
  selectedQuantity: number | null;
  selectedTimeslot: string | null;
  availableDates: string[];
  availableTimeslots: string[];
  phone: E164Number | null;
  comments: string;
  activeStartDate: string | null;
  error: boolean;
  previousReservation: CartDraftReservation | null;
  loading: Loading;
}

export const RESERVATION_SLICE_BASE_NAME = 'cart/reservation';

const initialState: ReservationState = {
  variantId: null,
  isReservationAvailable: false,
  selectedDate: null,
  selectedTimeslot: null,
  selectedQuantity: PRODUCT_MINIMUM_CART_QUANTITY_DEFAULT,
  phone: null,
  comments: '',
  availableDates: [],
  availableTimeslots: [],
  activeStartDate: null,
  error: false,
  previousReservation: null,
  loading: Loading.IDLE,
};

export const reservationSlice = createSlice({
  name: RESERVATION_SLICE_BASE_NAME,
  initialState,
  reducers: {
    setReservationForVariantAndDates: (
      state,
      { payload }: PayloadAction<{ variant: Variant; dates: string[] }>
    ) => {
      if (payload.dates.length > 0) {
        state.variantId = payload.variant.id;
        state.availableDates = payload.dates;
      } else {
        state.variantId = null;
        state.availableDates = [];
      }

      state.isReservationAvailable = payload.dates.length > 0;
    },
    setVariantId: (state, { payload }: PayloadAction<number | null>) => {
      state.variantId = payload;
    },
    setLoading: (state, { payload }: PayloadAction<Loading>) => {
      state.loading = payload;
    },
    setIsReservationAvailable: (state, { payload }: PayloadAction<boolean>) => {
      state.isReservationAvailable = payload;
    },
    setSelectedDate: (state, { payload }: PayloadAction<string | null>) => {
      state.selectedDate = payload;
    },
    setSelectedTimeslot: (state, { payload }: PayloadAction<string | null>) => {
      state.selectedTimeslot = payload;
    },
    setAvailableDates: (state, { payload }: PayloadAction<string[]>) => {
      state.availableDates = payload;
    },
    setAvailableTimeslots: (state, { payload }: PayloadAction<string[]>) => {
      state.availableTimeslots = payload;
    },
    setActiveStartDate: (state, { payload }: PayloadAction<string | null>) => {
      state.activeStartDate = payload;
    },
    setReservationError: (state, { payload }: PayloadAction<boolean>) => {
      state.error = payload;
    },
    setPhone: (state, { payload }: PayloadAction<E164Number | null>) => {
      state.phone = payload;
    },
    setComments: (state, { payload }: PayloadAction<string>) => {
      state.comments = payload;
    },
    setReservationSelectedQuantity: (state, { payload }: PayloadAction<number>) => {
      state.selectedQuantity = payload;
    },
    setReservation: (state, { payload }: PayloadAction<CartDraftReservation>) => {
      const { date, time, comments, phone, quantity } = payload;
      state.selectedDate = date;
      state.selectedTimeslot = time;
      state.phone = phone;
      state.comments = comments;
      state.selectedQuantity = quantity;
    },
    setPreviousReservation: (state, { payload }: PayloadAction<CartDraftReservation | null>) => {
      state.previousReservation = payload;
    },
    resetReservation: (state) => {
      state.variantId = null;
      state.isReservationAvailable = false;
      state.selectedDate = null;
      state.selectedTimeslot = null;
      state.availableDates = [];
      state.availableTimeslots = [];
      state.activeStartDate = null;
      state.phone = null;
      state.comments = '';
      state.error = false;
      state.previousReservation = null;
      state.selectedQuantity = PRODUCT_MINIMUM_CART_QUANTITY_DEFAULT;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAvailableDates.fulfilled, (state, { payload }) => {
        const dates = payload?.map(({ date }) => date);
        state.availableDates = payload?.map(({ date }) => date);
        if (!dates.length) state.error = true;
      })
      .addCase(fetchAvailableDates.rejected, (state) => {
        state.error = true;
      })
      .addCase(fetchAvailableTimeslots.fulfilled, (state, { payload }) => {
        const timeslots = payload?.timeslots || [];
        state.availableTimeslots = timeslots;
        if (!timeslots.length) state.error = true;
      })
      .addCase(fetchAvailableTimeslots.rejected, (state) => {
        state.error = true;
      })
      .addCase(APP_HYDRATE, (clientState, { payload }) => ({
        ...clientState,
        ...payload.reservation,
      }));
  },
});

export const ReservationActions = reservationSlice.actions;
export const {
  setReservationForVariantAndDates,
  setVariantId,
  setIsReservationAvailable,
  setSelectedDate,
  setSelectedTimeslot,
  setAvailableDates,
  setAvailableTimeslots,
  setActiveStartDate,
  setReservationError,
  setReservation,
  setPreviousReservation,
  setReservationSelectedQuantity,
  setLoading,
  setPhone,
  setComments,
  resetReservation,
} = reservationSlice.actions;

const slice = (state: RootState): ReservationState => state.reservation;

export const getVariantId = createSelector(slice, (state) => state.variantId);
export const getIsReservationAvailable = createSelector(
  slice,
  (state) => state.isReservationAvailable
);
export const getSelectedDate = createSelector(slice, (state) => state.selectedDate);
export const getSelectedTimeslot = createSelector(slice, (state) => state.selectedTimeslot);
export const getAvailableDates = createSelector(
  slice,
  (state: ReservationState) => state.availableDates
);
export const getAvailableTimeslots = createSelector(slice, (state) => state.availableTimeslots);
export const getActiveStartDate = createSelector(slice, (state) => state.activeStartDate);
export const getReservationError = createSelector(slice, (state) => state.error);
export const getPhone = createSelector(slice, (state) => state.phone);
export const getComments = createSelector(slice, (state) => state.comments);
export const getReservationQuantity = createSelector(slice, (state) => state.selectedQuantity);
export const getPreviousReservation = createSelector(slice, (state) => state.previousReservation);
export const selectReservationIsLoading = createSelector(
  slice,
  (state) => state.loading === Loading.PENDING
);

export default reservationSlice.reducer;
