import { getPublicRuntimeConfig } from 'lib/api';

export const apiGatewayEndpoints = {
  // Homepage
  GetHomepage: '/homepage',
  // Categories/Taxons
  GetCategories: '/categories',
  // cities
  GetCities: '/cities',
  // Cart
  CreateCart: '/carts',
  GetCart: (tokenValue: string) => `/carts/${tokenValue}`,
  DeleteCart: (tokenValue: string) => `/carts/${tokenValue}`,
  CreatePromotion: (tokenValue: string) => `/carts/${tokenValue}/promotion`,
  DeletePromotion: (tokenValue: string) => `/carts/${tokenValue}/promotion`,
  // Orders
  GetOrders: (page?: number, itemsPerPage?: number): string => {
    const url = new URL('/orders', getPublicRuntimeConfig().apiGateway.url);
    if (page) url.searchParams.set('page', page.toString());
    if (itemsPerPage) url.searchParams.set('itemsPerPage', itemsPerPage.toString());
    return url.toString();
  },
  GetOrder: (tokenValue: string) => `orders/${tokenValue}`,
  // Product
  AddToProductViewCount: (productId: number) => `/product/${productId}/track-view`,
  // Newsletter
  CreateNewsletterSubscription: '/newsletter/subscribe',
  DeleteNewsletterSubscription: '/newsletter/unsubscribe',
};

type APIGatewayEndpointKeys = keyof typeof apiGatewayEndpoints;
export type APIGatewayEndpoint = (typeof apiGatewayEndpoints)[APIGatewayEndpointKeys];
