import { CheckIcon } from '@heroicons/react/solid';
import * as React from 'react';

type UspProps = {
  usp: string;
  justify?: 'start' | 'center' | 'end' | 'between' | 'around' | 'evenly';
};

export default function Usp({ usp, justify = 'center' }: UspProps) {
  return (
    <div className={`flex justify-${justify}`}>
      <CheckIcon className="w-6 h-6 mr-1 text-primary" />
      <div className="py-1">{usp}</div>
    </div>
  );
}
