import AbstractTag from './AbstractTag';

export default class SortingSelectorTag extends AbstractTag {
  constructor({ sortingOption }: { sortingOption: string }) {
    super();

    this.addProp(AbstractTag.EVENT, 'sorting_interaction');
    this.addProp('sorting_option', sortingOption);
  }
}
