import { createApi } from '@reduxjs/toolkit/query/react';
import gatewayBaseQuery from 'lib/gateway/gatewayBaseQuery';
import { apiGatewayEndpoints } from 'lib/gateway/endpoints';
import { isHydrateAction } from 'redux/query/common.query';
import { HTTP_METHODS } from 'constants/api';
import { NewsletterSubscriptionLocation } from 'constants/newsletterSubscriptionLocation';

interface NewsletterSubscriptionPayload {
  email: string;
  location: NewsletterSubscriptionLocation;
}

const newsletterApi = createApi({
  reducerPath: 'newsletterApi',
  baseQuery: gatewayBaseQuery(),
  extractRehydrationInfo(action, { reducerPath }): any {
    if (isHydrateAction(action)) return action.payload[reducerPath];
  },
  endpoints: (builder) => ({
    subscribeToNewsletter: builder.mutation<string, NewsletterSubscriptionPayload>({
      query: (payload: NewsletterSubscriptionPayload) => ({
        url: apiGatewayEndpoints.CreateNewsletterSubscription,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
    }),
    unsubscribeToNewsletter: builder.mutation<string, NewsletterSubscriptionPayload>({
      query: (payload: NewsletterSubscriptionPayload) => ({
        url: apiGatewayEndpoints.DeleteNewsletterSubscription,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
    }),
  }),
});

export const { useSubscribeToNewsletterMutation, useUnsubscribeToNewsletterMutation } =
  newsletterApi;

export default newsletterApi;
