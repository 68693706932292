import { useRouter } from 'next/router';
import useStore from '../store';

const useCallbackUrl = () => {
  const router = useRouter();
  const [callbackUrl, setCallbackUrl] = useStore('callbackUrl');

  function handleSetCallbackUrl(url: string | null) {
    if (url === '/login') {
      return;
    }

    setCallbackUrl(url);
  }

  return {
    callbackUrl: router.query['from'] ? callbackUrl || '/account/dashboard' : '/account/dashboard',
    setCallbackUrl: handleSetCallbackUrl,
    clearCallbackUrl: () => setCallbackUrl(null),
  };
};

export default useCallbackUrl;
