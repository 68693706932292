export function capitalizeFirstLetter(string: string, lowerRest = true): string {
  const rest = lowerRest ? string.slice(1).toLowerCase() : string.slice(1);

  return string.charAt(0).toUpperCase() + rest;
}

// see https://stackoverflow.com/questions/14430633/how-to-convert-text-to-binary-code-in-javascript
export function convertToBinary(string: string) {
  return string
    .split('')
    .map((char) => char.charCodeAt(0).toString(2))
    .join(' ');
}

// see https://stackoverflow.com/questions/7860392/determine-if-string-is-in-base64-using-javascript
export function isBase64(string: string) {
  const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
  return base64regex.test(string);
}

// see https://melvingeorge.me/blog/check-if-string-is-valid-sha256-hash-javascript
export function isSha256(string: string) {
  const regexExp = /^[a-f0-9]{64}$/gi;
  return regexExp.test(string);
}

// see https://stackoverflow.com/questions/18338890/are-there-any-sha-256-javascript-implementations-that-are-generally-considered-t
export async function sha256(string: string) {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(string);

  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
}

export function base64Encode(string: string) {
  return Buffer.from(string).toString('base64');
}

export function base64Decode(string: string) {
  return Buffer.from(string, 'base64').toString('utf-8');
}
