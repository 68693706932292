import AbstractTag from './AbstractTag';

export type LocationEventActions =
  | typeof LocationSelectionEventTag.EVENT_ACTION_LOCATION_SELECT
  | typeof LocationSelectionEventTag.EVENT_ACTION_NEWSLETTER_LOCATION;

export class LocationSelectionEventTag extends AbstractTag {
  static EVENT_ACTION_LOCATION_SELECT = 'dropdown-select-region';
  static EVENT_ACTION_NEWSLETTER_LOCATION = 'dropdown-select-newsletter-region';

  constructor({
    location,
    eventAction,
  }: {
    location: string;
    eventAction:
      | typeof LocationSelectionEventTag.EVENT_ACTION_LOCATION_SELECT
      | typeof LocationSelectionEventTag.EVENT_ACTION_NEWSLETTER_LOCATION;
  }) {
    super();

    this.addProp(AbstractTag.EVENT, 'generic');
    this.addProp(AbstractTag.EVENT_ACTION, eventAction);
    this.addProp(AbstractTag.EVENT_LABEL, location);
  }
}
