import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GeoCity, ProductMarker } from 'types/geolocation';
import { RootState } from 'redux/appStore';
import { APP_HYDRATE } from 'redux/actions';
import { getGeoFilterQuery } from 'redux/geolocation/geolocation.slice';
import resolveClosestAddressToUser from 'lib/address/resolveClosestAddressToUser';
import { selectProductAddresses } from 'redux/products/productPage.slice';

export interface GeoProductsState {
  selectedProductMarker: ProductMarker | null;
  cities: GeoCity[];
  markers: ProductMarker[];
}

const initialState: GeoProductsState = {
  selectedProductMarker: null,
  cities: [],
  markers: [],
};

export const geoProductsSlice = createSlice({
  name: 'geoProducts',
  initialState,
  reducers: {
    setSelectedProductMarker: (state, { payload }: PayloadAction<ProductMarker | null>) => {
      state.selectedProductMarker = payload;
    },
    setGeoCities: (state, { payload }: PayloadAction<GeoCity[]>) => {
      state.cities = payload;
    },
    setMarkers: (state, { payload }: PayloadAction<ProductMarker[]>) => {
      state.markers = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(APP_HYDRATE, (clientState, { payload }) => ({
      ...clientState,
      ...payload.geoProducts,
    }));
  },
});

export const { setSelectedProductMarker, setGeoCities, setMarkers } = geoProductsSlice.actions;

const slice = (state: RootState) => state.geoProducts;

export const getGeoCities = createSelector(slice, (s) => s.cities);
export const selectMarkers = createSelector(slice, (s) => s.markers);
export const selectSelectedProductMarker = createSelector(slice, (s) => s.selectedProductMarker);
export const selectProductDetailCenterMarker = createSelector(
  slice,
  getGeoFilterQuery,
  selectProductAddresses,
  (geoProductsSlice, geoLocationState, productAddresses) => {
    if (geoProductsSlice.selectedProductMarker) {
      return {
        lat: geoProductsSlice.selectedProductMarker.address.latitude,
        lng: geoProductsSlice.selectedProductMarker.address.longitude,
      };
    }

    if (geoProductsSlice.markers.length === 1) {
      const marker = geoProductsSlice.markers[0];
      return {
        lat: marker.address.latitude,
        lng: marker.address.longitude,
      };
    }

    const closestAddress = resolveClosestAddressToUser(productAddresses, geoLocationState);

    return {
      lat: closestAddress.latitude,
      lng: closestAddress.longitude,
    };
  }
);

export default geoProductsSlice.reducer;
