export const LOCATION_CHECKOUT = 'checkout';
export const LOCATION_FOOTER = 'footer';
export const LOCATION_POPUP = 'popup';
export const LOCATION_ALTERNATE_REGISTRATION = 'alternate_registration';
export const LOCATION_LISTING_PAGE_HEADER = 'listing_page_header';

export const newsletterSignupLocations = {
  LOCATION_CHECKOUT,
  LOCATION_FOOTER,
  LOCATION_POPUP,
  LOCATION_ALTERNATE_REGISTRATION,
  LOCATION_LISTING_PAGE_HEADER,
} as const;

type NewsletterSubscriptionLocationKeys = keyof typeof newsletterSignupLocations;
export type NewsletterSubscriptionLocation =
  (typeof newsletterSignupLocations)[NewsletterSubscriptionLocationKeys];

export const DISPLAY_NEWSLETTER_SIGNUP_RADIUS = 20000; // 20km
