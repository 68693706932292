import AbstractTag from 'modules/tracking/tags/AbstractTag';

export default class SovendusBannerEventTag extends AbstractTag {
  static EVENT_CATEGORY_VALUE = 'SovendusAffiliate';
  static EVENT_ACTION_VALUE = 'SovendusClickOut';
  static EVENT_LABEL_VALUE = 'SovendusClickOut';

  static EVENT_POSITION = 'position';

  constructor({ position }: { position: string }) {
    super();

    this.addProp(AbstractTag.EVENT_CATEGORY, SovendusBannerEventTag.EVENT_CATEGORY_VALUE);
    this.addProp(AbstractTag.EVENT_ACTION, SovendusBannerEventTag.EVENT_ACTION_VALUE);
    this.addProp(AbstractTag.EVENT_LABEL, SovendusBannerEventTag.EVENT_LABEL_VALUE);
    this.addProp(SovendusBannerEventTag.EVENT_POSITION, position);
  }
}
