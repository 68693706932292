import Publisher from './Publisher';
import AbstractTag from '../tags/AbstractTag';
import { sha256 } from 'lib/strings';
import { TrackingContext } from 'types/tracking';

type TealiumEvent = {
  [key: string]: any; // 👈️ variable key
  timestamp: string;
  new_platform: string;
  eid_sha256_base64?: string;
};

export default class TealiumPublisher implements Publisher {
  private readonly data: any[];

  constructor() {
    // @ts-ignore
    window.digitalData = window.digitalData || [];
    // @ts-ignore
    this.data = window.digitalData;
  }

  async publish(tag: AbstractTag, context: TrackingContext): Promise<void> {
    const data = {
      ...tag.getData(),
      timestamp: Date.now().toString(),
      new_platform: 'true',
    } as TealiumEvent;

    if (context.customer?.email && context.hasAcceptedMarketingCookies) {
      data.eid_sha256 = await sha256(context.customer.email);
    }

    this.data.push(data);

    return Promise.resolve(undefined);
  }
}
