import { Address, AddressValue, CountryCode, PostalAddress } from 'src/types/types';

export const backendAddressToAddress = (value: any): Address | null => {
  if (!value) return null;
  const { postcode, ...rest } = value;
  return { postalCode: postcode, ...rest };
};

export const transformPostalCodeJson = (postalCodeJson: any): PostalAddress | null => {
  if (!postalCodeJson || postalCodeJson.error) {
    console.error({ error: postalCodeJson?.error });
    return null;
  }

  return {
    postcode: postalCodeJson.postcode,
    houseNumber: postalCodeJson.houseNumber,
    latitude: postalCodeJson.latitude,
    longitude: postalCodeJson.longitude,
    city: postalCodeJson.city,
  };
};

export const makeAddressValue = (address: Address | null | undefined): AddressValue => {
  address = address || {};
  return {
    houseNumber:
      typeof address.houseNumber === 'number'
        ? String(address.houseNumber)
        : typeof address.houseNumber === 'string'
        ? address.houseNumber
        : '',
    city: address.city || '',
    countryCode: (address.countryCode || 'NL') as CountryCode,
    houseNumberExtension: address.houseNumberExtension || '',
    postalCode: address.postalCode || '',
    street: address.street || '',
  };
};

export const createAddressBody = (customer: string, address: Address) => {
  return {
    houseNumber: parseInt(address.houseNumber as string),
    houseNumberExtension: address.houseNumberExtension,
    customer: `/api/v2/shop/customers/${customer}`,
    firstName: address.firstName,
    lastName: address.lastName,
    phoneNumber: address.phoneNumber,
    company: address.company,
    countryCode: address.countryCode,
    street: address.street,
    city: address.city,
    postcode: address.postalCode,
  };
};

export const createAddressFromPostCodeBody = (address: Address) => {
  return {
    countryCode: address.countryCode,
    street: address.street,
    city: address.city,
    postcode: address.postalCode,
    houseNumber: parseInt(address.houseNumber as string),
    houseNumberExtension: address.houseNumberExtension,
  };
};

export const mapAddressResponseToAddress = (response: any): Address => {
  return {
    houseNumber: response.houseNumber,
    houseNumberExtension: response.houseNumberExtension,
    id: response.id,
    firstName: response.firstName,
    lastName: response.lastName,
    phoneNumber: response.phoneNumber,
    company: response.company,
    countryCode: response.countryCode,
    street: response.street,
    city: response.city,
    postalCode: response.postcode,
  };
};
