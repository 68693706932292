import React, { DetailedHTMLProps, InputHTMLAttributes } from 'react';

export type ToggleType = Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'onChange'
> & {
  onChange: (value: boolean) => void;
};

const Toggle = ({ onChange, checked, ...props }: ToggleType) => {
  return (
    <input
      type="checkbox"
      checked={checked}
      onChange={(e) => onChange(e.target.checked)}
      {...props}
    />
  );
};

export default Toggle;
