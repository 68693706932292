import AbstractTag from './AbstractTag';

export default class LoginErrorTag extends AbstractTag {
  constructor() {
    super();

    this.addProp(AbstractTag.EVENT, 'error');
    this.addProp(AbstractTag.EVENT_NAME, 'error');
    this.addProp('error_type', 'login');
    this.addProp('event_message', 'invalid password');
  }
}
