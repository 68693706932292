import { Alexandria } from 'next/font/google';
import React from 'react';

export const primaryFont = Alexandria({
  weight: ['300', '400', '500', '600', '700'],
  style: 'normal',
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-primary',
});

const GlobalNextFont = () => {
  // it's not pretty, but it fixes issue with font not being applied for portals like modals
  // see: https://nextjs.org/docs/pages/building-your-application/optimizing/fonts#apply-the-font-in-head
  return (
    <style jsx global>
      {`
        html {
          font-family: ${primaryFont.style.fontFamily};
        }
      `}
    </style>
  );
};

export default GlobalNextFont;
