import AbstractTag from './AbstractTag';

export default class BannerEventTag extends AbstractTag {
  constructor({
    promoId,
    promoName,
    promoCreative,
    promoPosition,
  }: {
    promoId: string;
    promoName: string;
    promoCreative: string;
    promoPosition: string;
  }) {
    super();

    this.addProp(AbstractTag.EVENT, 'promo_click');

    this.addProp('promo_id', promoId);
    this.addProp('promo_name', promoName);
    this.addProp('promo_creative', promoCreative);
    this.addProp('promo_position', promoPosition);
  }
}
