import AbstractTag from '../AbstractTag';

export default class AffiliateProductClickTag extends AbstractTag {
  constructor(buttonText: string) {
    super();

    this.addProp(AbstractTag.EVENT, 'click');
    this.addProp(AbstractTag.EVENT_CATEGORY, 'click');
    this.addProp(AbstractTag.EVENT_ACTION, 'button_click');
    this.addProp(AbstractTag.EVENT_LABEL, buttonText.toLowerCase());
  }
}
