import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import BigFilledButton from 'components/BigFilledButton';
import { trackBannerClick } from 'modules/tracking/events/events';
import { Banner } from 'types/types';
import { AppPromoUsps } from 'components/appPromoBanner/AppPromoUsps';
import { AppPromoStoreLink } from 'components/appPromoBanner/AppPromoStoreLink';
import {
  APP_STORE_CAMPAIGNS,
  APP_PROMO_TRACKING_CODES,
  APP_PROMO_BANNER_ID,
} from 'src/constants/appStores';
import { getAppStoreByUserDevice } from 'lib/appPromo/appPromo';

export function AppPromoMobile() {
  const { t } = useTranslation();

  const appStore = useMemo(() => {
    return getAppStoreByUserDevice({ campaign: APP_STORE_CAMPAIGNS.popOver });
  }, []);

  const handleClick = () => {
    void trackBannerClick({
      banner: {
        id: APP_PROMO_BANNER_ID,
        code: APP_PROMO_TRACKING_CODES.buttonApple,
      } as Banner,
      width: '120',
      height: '40',
      position: 2,
    });
  };

  return (
    <>
      <div className={`flex flex-row items-center justify-start`}>
        <Image src={'/logo-turned-9-degrees.svg'} width={100} height={100} alt="Logo" />
        <h2 className={'text-primary text-2xl ml-4'}>{t('app.app_promo.title')}</h2>
      </div>
      <div className="flex flex-col mt-5">
        <div className={'ml-3'}>
          <AppPromoUsps />
        </div>
        <div className="flex items-center justify-between w-full mt-5">
          <div className="flex justify-center items-center px-2">
            <AppPromoStoreLink appStore={appStore} trackingPosition={1} width={200} height={150} />
          </div>
          <div className="flex-grow justify-end items-center">
            <BigFilledButton
              onClick={handleClick}
              href={appStore.url}
              text={t('app.app_promo.download_now')}
              rightArrowIcon={true}
              width={'w-full px-3 mx-4'}
            />
          </div>
        </div>
      </div>
    </>
  );
}
