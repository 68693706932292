import AbstractTag from './AbstractTag';

export default class JumboPromoSubmitEventTag extends AbstractTag {
  constructor({ promocode }: { promocode: string }) {
    super();

    this.addProp(AbstractTag.EVENT, 'jumbo_promo_submit_click');
    this.addProp('coupon', promocode);
  }
}
