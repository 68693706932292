import { Coordinates, GeoCity } from 'types/geolocation';

export const transformUserCoordinatesJson = (json: any): Coordinates => {
  return {
    latitude: parseFloat(json.latitude),
    longitude: parseFloat(json.longitude),
  };
};

export const transformProductCitiesJson = (citiesJson: any): GeoCity[] => {
  if (!citiesJson?.length) return [];

  return citiesJson
    .map((city: any) => city as GeoCity)
    .sort((a: GeoCity, b: GeoCity) => a.name.localeCompare(b.name));
};
