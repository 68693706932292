import { Address } from 'src/types/types';
import { Coordinates } from 'types/geolocation';
import { isPointWithinRadius } from 'geolib';
import { GeolibInputCoordinates } from 'geolib/es/types';

export function isAddressWithinRadius(centerPoint: Coordinates, address: Address, radius: number) {
  if (!centerPoint.longitude || !centerPoint.latitude) return false;

  if (!address) return false;

  return isPointWithinRadius(
    centerPoint as GeolibInputCoordinates,
    address as GeolibInputCoordinates,
    radius
  );
}
