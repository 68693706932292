import { RecommendedProductLocation } from 'constants/product';

const API_VERSION = 'v2';
const BASE_PATH = `api/${API_VERSION}`;

const API_ENDPOINTS = {
  // Address
  AutocompleteAddress: `${BASE_PATH}/shop/autocomplete-address`,
  CreateAddress: `${BASE_PATH}/shop/account/addresses`,
  GetAddress: `${BASE_PATH}/shop/get-address`,
  DeleteAddress: (id: number) => `${BASE_PATH}/shop/account/addresses/${id}`,
  PostalAddress: (customerId: string) => `${BASE_PATH}/shop/customers/${customerId}/postal-address`,

  // Auth
  GetAccountStatus: `${BASE_PATH}/shop/check-account`,
  Login: `${BASE_PATH}/shop/user-login`,
  Register: `${BASE_PATH}/shop/user-register`,
  CreateGuestRegistration: `${BASE_PATH}/shop/user-register-from-guest-session`,

  // Cart
  CartItems: (cartId: string) => `${BASE_PATH}/shop/orders/${cartId}/items`,
  BookingCartItems: (cartId: string) => `${BASE_PATH}/shop/orders/${cartId}/booking`,
  UpdateCart: (cartToken: string, cartItemId: number) =>
    `${BASE_PATH}/shop/orders/${cartToken}/items/${cartItemId}`,
  GetPickUpCart: `${BASE_PATH}/shop/orders`,
  RefreshCart: (tokenValue: string) => `${BASE_PATH}/shop/carts/${tokenValue}`,
  DeleteCartItem: (cartToken: string, cartItemId: number) =>
    `${BASE_PATH}/shop/orders/${cartToken}/items/${cartItemId}`,

  // Customer
  GetSavings: `${BASE_PATH}/shop/get-savings`,
  UpdateDefaultAddress: (customerId: number) =>
    `${BASE_PATH}/shop/customers/${customerId}/default-address`,

  // Customer support
  CreateSupportTicket: `${BASE_PATH}/shop/customer-support/ticket`,
  GetSupportTicketSubjects: `${BASE_PATH}/shop/support/subjects`,

  // Guest Auth
  CreateGuestToken: `${BASE_PATH}/shop/request-guest-token`,
  GetGuestOrderDetails: `${BASE_PATH}/shop/request-guest-order-details`,

  // Order
  ApplyCoupon: (cartToken: string | null) => `${BASE_PATH}/shop/orders/${cartToken}`,
  CreateReturnRequest: `${BASE_PATH}/shop/account/orders/request-return`,
  GetOrders: (page: number | null, itemsPerPage: number | null) =>
    `${BASE_PATH}/shop/orders?page=${page}&itemsPerPage=${itemsPerPage}`,
  GetOrder: (tokenValue: string) => `${BASE_PATH}/shop/orders/${tokenValue}`,
  GetOrderItems: (number: string) => `${BASE_PATH}/shop/orders/items-by-number/${number}`,
  GetOrderTrackings: (orderTokenValue: string) =>
    `${BASE_PATH}/shop/account/orders/${orderTokenValue}/shipment-trackings`,
  GetOrderSummary: (orderTokenValue: string, orderId: string) =>
    `${BASE_PATH}/shop/order-summary/${orderTokenValue}/${orderId}`,
  GetReturnableItems: `${BASE_PATH}/shop/account/orders/returnable-items`,
  GetReturnableUnits: (orderItemId: number) =>
    `${BASE_PATH}/shop/account/orders/returnable-units?orderItemId=${orderItemId}`,
  GetReturnedUnits: `${BASE_PATH}/shop/account/orders/returned-units`,
  GetOrderTokenById: (id: number) => `${BASE_PATH}/shop/orders/token-value-by-id/${id}`,
  ApplyPromotionalItems: (cartToken: string) =>
    `${BASE_PATH}/shop/orders/${cartToken}/promotional-items`,

  // Page
  GetPages: `${BASE_PATH}/shop/pages`,
  GetHomePage: (id: string) => `${BASE_PATH}/shop/homepage/${id}`,
  GetPageBySlug: (slug: string) => `${BASE_PATH}/shop/page-by-slug/${slug}`,

  // Payment
  GetCompleteCheckout: (orderTokenValue: string) =>
    `${BASE_PATH}/shop/orders/${orderTokenValue}/complete`,
  GetCompleteSession: `${BASE_PATH}/shop/complete-session`,
  GetPaymentSession: (paymentSessionId: string, orderTokenValue: string) =>
    `${BASE_PATH}/shop/payment-session/${paymentSessionId}/${orderTokenValue}`,
  GetValidatePayment: () => `${BASE_PATH}/shop/payment-session/validate`,
  GetStartPayment: `${BASE_PATH}/shop/payment-started`,
  GetStartSession: `${BASE_PATH}/shop/payment-session`,

  // Product
  GetProducts: `${BASE_PATH}/shop/products`,
  GetProductsById: (query: string) => `${BASE_PATH}/shop/products?${query}`,
  GetMostSoldProductsByTaxon: (taxonId: number) =>
    `${BASE_PATH}/shop/products/most-sold/${taxonId}`,
  GetProductBySlug: (slug?: string) => `${BASE_PATH}/shop/product-by-slug/${slug}`,
  GetCatchOfTheDayProduct: () => `${BASE_PATH}/shop/products/dagpakker`,
  GetProductByCode: (code: string) => `${BASE_PATH}/shop/product-variants/${code}`,
  GetProductPreview: (token: string) => `${BASE_PATH}/shop/products/preview/${token}`,
  GetRecommendedProducts: (location: RecommendedProductLocation) =>
    `${BASE_PATH}/shop/products/recommended-products/${location}`,
  GetProductsSearch: `${BASE_PATH}/shop/search`,
  GetProductsByLocation: `${BASE_PATH}/shop/products/location-search`,
  GetProductCities: `${BASE_PATH}/shop/cities`,
  GetUserLocationFallback: `${BASE_PATH}/shop/customer-location`,
  GetReservationAvailabilitiesForMonth: (variantId: number) =>
    `${BASE_PATH}/shop/reservation/availability/${variantId}`,
  GetReservationTimeSlotsForDate: (variantId: number, date: string) =>
    `${BASE_PATH}/shop/reservation/availability/${variantId}/${date}`,

  // Static content
  GetSitemap: `${BASE_PATH}/shop/sitemap`,
  GetBannersForTaxon: (slug: string) => `${BASE_PATH}/shop/banners/by-taxon-slug/${slug}`,
  GetTextBanners: `${BASE_PATH}/shop/text-banners`,
  CreateNewsletterSubscription: `${BASE_PATH}/shop/newsletter/subscribe`,
  GetFaqArticle: (id: number) => `${BASE_PATH}/shop/faq/articles/${id}`,
  GetFaqArticles: (page: number | null, itemsPerPage: number | null) =>
    `${BASE_PATH}/shop/faq/articles?page=${page}&itemsPerPage=${itemsPerPage}`,
  GetFaqCategories: (page: number | null, itemsPerPage: number | null) =>
    `${BASE_PATH}/shop/faq/categories?page=${page}&itemsPerPage=${itemsPerPage}`,
  GetFaqCategory: (categoryId: number) => `${BASE_PATH}/shop/faq/categories/${categoryId}`,

  // Taxon
  GetTaxons: (page: number, itemsPerPage: number) =>
    `${BASE_PATH}/shop/taxons?page=${page}&itemsPerPage=${itemsPerPage}`,
  GetTaxonBySlug: (slug: string) => `${BASE_PATH}/shop/taxon-by-slug/${slug}`,

  // User
  GetAccount: `${BASE_PATH}/shop/accounts/me`,
  UpdateUser: `${BASE_PATH}/shop/user-update`,
  UpdatePassword: `${BASE_PATH}/shop/user-password-change`,
  ResetPassword: `${BASE_PATH}/shop/user-password-reset`,
  CreatePasswordReset: `${BASE_PATH}/shop/user-request-password-reset`,
};

type ApiEndpointKeys = keyof typeof API_ENDPOINTS;
export type ApiEndpoint = (typeof API_ENDPOINTS)[ApiEndpointKeys];

export default API_ENDPOINTS;
