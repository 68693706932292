import { useEffect, useLayoutEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

export const useResponsive = () => {
  const [isClient, setIsClient] = useState(false);
  const lgBreak = 976;
  const xlBreak = 1370;
  const mdBreak = 768;
  const smBreak = 480;

  // React currently throws a warning when using useLayoutEffect on the server.
  // To get around it, we can conditionally useEffect on the server (no-op) and
  // useLayoutEffect in the browser. We need useLayoutEffect because we want
  // `connect` to perform sync updates to a ref to save the latest props after
  // a render is actually committed to the DOM.
  const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

  const isLg = useMediaQuery({ minWidth: lgBreak });
  const isXl = useMediaQuery({ minWidth: xlBreak });
  const isMd = useMediaQuery({ minWidth: mdBreak });
  const isSm = useMediaQuery({ minWidth: smBreak });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useIsomorphicLayoutEffect(() => {
    if (typeof window !== 'undefined') setIsClient(true);
  }, []);

  return {
    isLg: isClient && isLg,
    isXl: isClient && isXl,
    isMd: isClient && isMd,
    isSm: isClient && isSm,
    isMobile: isClient && isMobile,
    isClient,
    lgBreak,
    xlBreak,
    mdBreak,
    smBreak,
  };
};

export default useResponsive;
