import { v4 as uuidv4 } from 'uuid';

import { parseCookieString } from 'lib/cookie';
import { defaultOptions, GB_UUID } from 'components/growthbook/growthbook.constants';
import { GrowthBook, GrowthBookSSRData } from '@growthbook/growthbook-react';
import config from 'next/config';

interface CreateGrowthBook {
  config: {
    growthBook: {
      apiHost: string;
      clientKey: string;
    };
  };
  cookies: Record<string, string>;
}

export const extractGrowthBookData = (growthBook: GrowthBook): GrowthBookSSRData => ({
  attributes: growthBook.getAttributes(),
  features: growthBook.getFeatures(),
});

export const getUuidCookie = (): string | null => parseCookieString(document.cookie)[GB_UUID];

export const createGrowthBookServerSide = ({ config, cookies }: CreateGrowthBook): GrowthBook => {
  const { growthBook } = config;
  const { clientKey, apiHost } = growthBook;
  const id = cookies[GB_UUID] ?? uuidv4();

  return new GrowthBook({
    ...defaultOptions,
    apiHost,
    clientKey,
    attributes: { id },
  });
};

export const getGrowthBookData = async (cookie: string | undefined): Promise<GrowthBookSSRData> => {
  const { publicRuntimeConfig } = config();
  const cookies = parseCookieString(cookie);
  const growthBook = createGrowthBookServerSide({
    config: publicRuntimeConfig,
    cookies,
  });

  const {
    growthBook: { enabled },
  } = publicRuntimeConfig;

  if (enabled) await growthBook.loadFeatures();

  return extractGrowthBookData(growthBook);
};

export const createGrowthBookFromData = (data: GrowthBookSSRData): GrowthBook => {
  const {
    publicRuntimeConfig: {
      growthBook: { clientKey, apiHost },
    },
  } = config();

  return new GrowthBook({
    apiHost,
    clientKey,
    ...defaultOptions,
    ...data,
  });
};
