import { BannerLocationMap } from 'types/types';
import { doFetch, resolveApiUrl } from 'lib/api';
import { transformTextBanner } from 'lib/transforms';
import { captureTypeErrorIfInvalid } from 'lib/error';
import API_ENDPOINTS from 'constants/routes/api';

export default async function fetchTextBannerLocationMap(): Promise<BannerLocationMap> {
  const endpoint = resolveApiUrl(API_ENDPOINTS.GetTextBanners);
  const res = await doFetch(endpoint, {
    headers: { Accept: 'application/json' },
    method: 'GET',
    redirect: 'follow',
    mode: 'cors',
  });

  if (!res.ok) {
    throw new Error();
  }

  const response = await res.json();
  const locations = response.locations;

  for (const [key, value] of Object.entries(locations)) {
    locations[key] = value !== null ? transformTextBanner(value) : null;
  }

  captureTypeErrorIfInvalid('BannerLocationMap', locations);

  return locations;
}
