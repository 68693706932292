import { Customer } from 'types/types';
import { doFetch, resolveApiUrl } from 'lib/api';
import { CONTENT_TYPES } from 'constants/enums';
import { mapAddressResponseToAddress } from 'lib/transforms';
import { captureTypeErrorIfInvalid } from 'lib/error';
import API_ENDPOINTS from 'constants/routes/api';

export async function fetchAccount(token: string): Promise<Customer> {
  const endpoint = resolveApiUrl(API_ENDPOINTS.GetAccount);
  const res = await doFetch(endpoint, {
    headers: {
      'X-AUTH-TOKEN': token,
      accept: CONTENT_TYPES.APPLICATION_JSON,
      'Content-Type': CONTENT_TYPES.APPLICATION_JSON,
    },
    method: 'GET',
    redirect: 'follow',
    mode: 'cors',
  });

  if (!res.ok) {
    throw new Error();
  }

  const account = await res.json();

  const {
    email,
    firstName,
    lastName,
    fullName,
    phoneNumber,
    birthday,
    defaultAddress,
    addresses,
    customerId,
    gigyaUid,
    userId,
    subscribedToNewsletter,
  } = account;

  const customer = {
    email,
    firstName,
    lastName,
    fullName,
    phoneNumber,
    birthday,
    defaultAddress: defaultAddress ? mapAddressResponseToAddress(defaultAddress) : null,
    addresses,
    customerId,
    gigyaUid,
    userId,
    subscribedToNewsletter,
    isGuest: false,
  };

  captureTypeErrorIfInvalid('Customer', customer);
  return customer;
}

export default fetchAccount;
