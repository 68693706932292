import { Dialog as HeadlessUiDialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { twMerge } from 'tailwind-merge';
import { Z_INDEX } from 'constants/common';

const Dialog = ({
  className,
  children,
  dialogOpen,
  setDialogOpen,
  title,
  titleClassName,
  zIndex = Z_INDEX.level40,
}: {
  children: any;
  className?: string;
  dialogOpen: boolean;
  setDialogOpen?: (value: boolean) => void;
  title?: string;
  titleClassName?: string;
  zIndex?: Z_INDEX;
}) => {
  return (
    <Transition appear show={dialogOpen} as={Fragment}>
      <HeadlessUiDialog
        as="div"
        className={`fixed inset-0 ${zIndex} overflow-y-auto`}
        onClose={() => setDialogOpen && setDialogOpen(false)}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <HeadlessUiDialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={twMerge(
                'inline-block w-full max-w-2xl p-6 mb-32 mt-24 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl',
                className
              )}
            >
              {title ? (
                <HeadlessUiDialog.Title
                  as="h3"
                  className={titleClassName || 'text-lg font-medium leading-6 text-gray-900'}
                >
                  {title}
                </HeadlessUiDialog.Title>
              ) : null}
              {children}
            </div>
          </Transition.Child>
        </div>
      </HeadlessUiDialog>
    </Transition>
  );
};

export default Dialog;
