import { FC, MouseEvent } from 'react';
import { ArrowRightIcon } from '@heroicons/react/solid';
import { useRouterNavigation } from 'hooks/useRouterNavigation';

interface Props {
  text: string;
  href: string;
  rightArrowIcon?: boolean;
  outline?: boolean;
  width?: string;
  onClick?: () => void;
  color?: 'orange' | 'purple';
}

export const BigFilledButton: FC<Props> = ({
  text,
  href,
  rightArrowIcon,
  outline = false,
  width = undefined,
  onClick = undefined,
  color = 'orange',
}) => {
  const { navigateOnClick } = useRouterNavigation();
  const classNames = outline
    ? `border-${color} bg-white text-${color}`
    : `border-transparent bg-${color} text-white`;

  const handleOnClick = (e: MouseEvent<HTMLButtonElement>) => {
    onClick && onClick();
    void navigateOnClick(href, e);
  };

  return (
    <button
      type="button"
      className={`${
        width ? width : 'w-full'
      } text-center font-semibold rounded-md border p-3 shadow-sm ${classNames}`}
      onClick={handleOnClick}
    >
      <span className="flex items-center justify-center">
        {text}
        {rightArrowIcon && <ArrowRightIcon className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />}
      </span>
    </button>
  );
};

export default BigFilledButton;
