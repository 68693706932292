import React, { createContext, ReactNode, useCallback, useMemo } from 'react';
import { Slide, toast, ToastContainer, ToastOptions } from 'react-toastify';
import NotificationToast, {
  NotificationToastProps,
} from 'components/notifications/NotificationToast';
import useResponsive from 'hooks/useResponsive';
import { DEFAULT_TOAST_DURATION } from 'constants/common';

interface NotificationContextType {
  showSuccessNotification: (content: NotificationToastProps | string, opts?: ToastOptions) => void;
  showErrorNotification: (content: NotificationToastProps | string, opts?: ToastOptions) => void;
  showWarningNotification: (content: NotificationToastProps | string, opts?: ToastOptions) => void;
  clearAllToasts: () => void;
}

export const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

interface NotificationProviderProps {
  children: ReactNode;
}

export const getDefaultToastOptions: (isMobile: boolean) => ToastOptions = (isMobile) => ({
  autoClose: DEFAULT_TOAST_DURATION,
  position: isMobile ? 'top-center' : 'top-right',
  theme: 'colored',
  className: 'avdd-toast',
  transition: Slide,
  draggable: isMobile,
  draggablePercent: 50,
  closeOnClick: !isMobile,
  icon: false,
});

export const NotificationProvider = ({ children }: NotificationProviderProps) => {
  const { isMobile } = useResponsive();
  const defaultOpts: ToastOptions = useMemo(() => getDefaultToastOptions(isMobile), [isMobile]);

  const createToastId = (content: NotificationToastProps | string) => {
    return typeof content === 'string' ? content : `{content.title} - ${content.text}`;
  };

  const showNotification = useCallback(
    (content: NotificationToastProps | string, toastProps?: ToastOptions) => {
      const toastId = createToastId(content);

      // In case we do not display same toast message: delete all toast messages. Otherwise: let toast module handle duplicates.
      if (!toast.isActive(toastId)) toast.dismiss();

      const contentElement =
        typeof content === 'string' ? (
          <NotificationToast title={content} isMobile={isMobile} />
        ) : (
          <NotificationToast {...content} isMobile={isMobile} />
        );

      toast(contentElement, { ...defaultOpts, ...toastProps, toastId });
    },
    [defaultOpts, isMobile]
  );

  const showSuccessNotification = useCallback(
    (content: NotificationToastProps | string, opts?: ToastOptions) =>
      showNotification(content, { ...opts, type: 'success' }),
    [showNotification]
  );

  const showErrorNotification = useCallback(
    (content: NotificationToastProps | string, opts?: ToastOptions) =>
      showNotification(content, { ...opts, type: 'error' }),
    [showNotification]
  );

  const showWarningNotification = useCallback(
    (content: NotificationToastProps | string, opts?: ToastOptions) => {
      showNotification(content, { ...opts, type: 'warning' });
    },
    [showNotification]
  );

  const clearAllToasts = useCallback(() => toast.dismiss(), []);

  const contextValue = useMemo(
    () => ({
      showSuccessNotification,
      showErrorNotification,
      showWarningNotification,
      clearAllToasts,
    }),
    [showSuccessNotification, showErrorNotification, showWarningNotification, clearAllToasts]
  );

  return (
    <NotificationContext.Provider value={contextValue}>
      <ToastContainer />
      {children}
    </NotificationContext.Provider>
  );
};
