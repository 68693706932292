import TaxonClickTag from '../TaxonClickTag';
import SubTaxonClickTag from '../SubTaxonClickTag';

export default class TaxonEventFactory {
  static taxonClick({ taxonName }: { taxonName: string }): TaxonClickTag {
    const tag = new TaxonClickTag();
    tag.navigationLabel = taxonName;
    return tag;
  }

  static subTaxonClick({
    mainTaxonName,
    subTaxonName,
  }: {
    mainTaxonName: string;
    subTaxonName: string;
  }): SubTaxonClickTag {
    const tag = new SubTaxonClickTag();
    tag.filterCategory = mainTaxonName;
    tag.filterLabel = subTaxonName;
    return tag;
  }
}
