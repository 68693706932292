import { CONTENT_TYPES } from 'constants/enums';
import { ApiEndpoint } from 'constants/routes/api';
import config from 'next/config';
import { BackendError, NotFoundError } from 'src/types/types';

export const getPublicRuntimeConfig = () => config().publicRuntimeConfig;

export function resolveApiUrl(endpoint: ApiEndpoint): string {
  const { publicRuntimeConfig } = config();

  return `${publicRuntimeConfig.apiUrl}/${endpoint}`;
}

export const jsonHeaders = {
  accept: CONTENT_TYPES.APPLICATION_JSON,
  'Content-Type': CONTENT_TYPES.APPLICATION_JSON,
};

export const isValidEntry = ([_, value]: [key: string, value: any]): boolean =>
  value !== undefined && value !== '' && value !== null;

export const handleResponseErrorIfAny = async (res: Response): Promise<any> => {
  const parsed = await res.json();
  if (!res.ok) {
    if (res.status === 404) throw new NotFoundError();
    if (Array.isArray(parsed.violations)) throw new BackendError(parsed.violations);
    throw new Error();
  }

  return parsed;
};

export const parseQueryToNumber = (value: string[] | string | undefined) =>
  value ? (Array.isArray(value) ? Number(value[0]) : Number(value)) : 0;

export const toQueryString = (query?: { [key: string]: any }) => {
  const hasQuery = query && Object.entries(query)?.filter(isValidEntry).length > 0;
  if (!hasQuery) return '';
  return (
    '?' +
    Object.entries(query)
      .filter(isValidEntry)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')
  );
};

export function doFetch(
  endpoint: string,
  options: RequestInit = {},
  query?: { [key: string]: any }
) {
  options.headers = {
    ...jsonHeaders,
    ...options.headers,
  };
  options.redirect = 'follow';
  options.mode = 'cors';

  const url = endpoint + toQueryString(query);

  return fetch(url, options);
}

export function doPost(url: string, body: {} = {}, options: RequestInit = {}) {
  return fetch(url, {
    headers: {
      ...jsonHeaders,
      ...options.headers,
    },
    method: 'POST',
    redirect: 'follow',
    body: JSON.stringify(body),
    mode: 'cors', // no-cors, *cors, same-origin,
  });
}
