import React from 'react';
import classNames from 'classnames';

export interface NotificationToastProps {
  title: string;
  text?: string;
  isMobile?: boolean;
}

const NotificationToast = ({ title, text, isMobile }: NotificationToastProps) => {
  return (
    <div className={classNames({ 'py-1': !isMobile })}>
      <h4 className="font-semibold">{title}</h4>
      {text && <p className="text-xs">{text}</p>}
    </div>
  );
};

export default NotificationToast;
