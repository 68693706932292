import { doFetch, resolveApiUrl } from 'lib/api';
import API_ENDPOINTS from 'constants/routes/api';
import { transformProductJson } from 'lib/transforms';
import { Coordinates } from 'types/geolocation';
import { Product } from 'types/product';
import { PaginationMetadata } from 'types/pagination';
import { captureTypeErrorIfInvalid } from 'lib/error';
import { PaginationQuery, ProductTaxonLocationQuery } from 'types/types';

export interface ProductLocationSearchReturnType {
  products: Product[];
  meta: {
    query: Coordinates;
    pagination: PaginationMetadata;
  } | null;
}

export async function fetchProductsByLocation(
  inputQuery: PaginationQuery<ProductTaxonLocationQuery>
): Promise<Product[]> {
  const endpoint = resolveApiUrl(API_ENDPOINTS.GetProductsByLocation);

  const res = await doFetch(endpoint, {}, inputQuery);
  if (!res.ok) {
    throw new Error(
      `Endpoint ${endpoint} responded with an unknown response, responseCode ${res.status}`
    );
  }

  const productsJson = await res.json();

  const products = productsJson.products
    ? productsJson.products
        .filter((p: any) => p !== null)
        .map(transformProductJson)
        .filter(Boolean)
    : [];

  products?.forEach((r: Product) => captureTypeErrorIfInvalid('Product', r));

  return products;
}

export default fetchProductsByLocation;
