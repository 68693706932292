import AbstractTag from '../AbstractTag';

export default class UserLoginTag extends AbstractTag {
  constructor() {
    super();

    this.addProp(AbstractTag.EVENT, 'user_login');
  }

  set userId(value: string) {
    this.addProp('user_id', value);
  }
}
