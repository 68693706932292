import AbstractTag from '../AbstractTag';
import { TrackingProduct } from 'types/tracking';

export default class RemoveFromCartClickTag extends AbstractTag {
  constructor() {
    super();

    this.addProp(AbstractTag.EVENT, 'remove_from_cart');
  }

  set product(value: TrackingProduct) {
    this.addProp('product', [value]);
  }
}
