import AbstractTag from '../AbstractTag';

export default class ShareProductClickTag extends AbstractTag {
  constructor({ productId }: { productId: string }) {
    super();

    this.addProp(AbstractTag.EVENT, 'click');
    this.addProp(AbstractTag.EVENT_NAME, 'deel_de_deal');
    this.addProp(AbstractTag.EVENT_ACTION, 'deel_de_deal');
    this.addProp(AbstractTag.EVENT_LABEL, productId);
  }
}
