import { createApi } from '@reduxjs/toolkit/query/react';
import gatewayBaseQuery from 'lib/gateway/gatewayBaseQuery';
import { apiGatewayEndpoints } from 'lib/gateway/endpoints';
import { isHydrateAction } from 'redux/query/common.query';
import { HTTP_METHODS } from 'constants/api';

const productApi = createApi({
  reducerPath: 'productApi',
  baseQuery: gatewayBaseQuery(),
  extractRehydrationInfo(action, { reducerPath }): any {
    if (isHydrateAction(action)) return action.payload[reducerPath];
  },
  endpoints: (builder) => ({
    trackProductView: builder.mutation<void, number>({
      query: (productId: number) => ({
        url: apiGatewayEndpoints.AddToProductViewCount(productId),
        method: HTTP_METHODS.POST,
      }),
    }),
  }),
});

export const { useTrackProductViewMutation } = productApi;

export default productApi;
