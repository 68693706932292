// Google version of locales
export enum Locales {
  NL = 'nl',
  NL_BE = 'nl_BE',
}

// ES6 version of locales
export enum LOCALES {
  NL = 'nl-NL',
  NL_BE = 'nl-BE',
}

export enum Region {
  NL = 'NL',
  BE = 'BE',
}

export enum Language {
  'NL' = 'nl',
  'BE' = 'nl',
}
