const IndicatorBase = ({
  sizeClass,
  borderClass,
  className,
}: {
  sizeClass: string;
  borderClass: string;
  className: string | undefined;
}) => {
  return (
    <div
      data-testid="activityIndicator"
      className={`${sizeClass} border-b-2 ${borderClass} rounded-full animate-spin ${className}`}
    />
  );
};

export const ActivityIndicator = ({
  size = 'small',
  color = 'black',
  className,
  container = false,
  containerClassName = '',
}: {
  size?: 'small' | 'medium' | 'big';
  color?: 'white' | 'black';
  className?: string;
  container?: boolean;
  containerClassName?: string;
}) => {
  const sizeClass = size === 'small' ? 'w-6 h-6' : size === 'medium' ? 'w-16 h-16' : 'w-40 h-40';
  const borderClass = color === 'white' ? 'border-white' : 'border-gray-900';

  if (container) {
    return (
      <div
        className={`p-4 rounded-md shadow-default bg-white flex items-center justify-center ${containerClassName}`}
        suppressHydrationWarning
      >
        <IndicatorBase className={className} borderClass={borderClass} sizeClass={sizeClass} />
      </div>
    );
  }
  return <IndicatorBase className={className} borderClass={borderClass} sizeClass={sizeClass} />;
};

export default ActivityIndicator;
