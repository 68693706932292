import { UAParser } from 'ua-parser-js';
import {
  APP_STORES,
  APPLE_STORE_APP_URL,
  APPSTORE_CAMPAIGN_KEY,
  DESKTOP_APP_URL,
  APP_STORE_EXTERNAL_REDIRECT,
  GOOGLE_PLAY_STORE_APP_URL,
  AppStoreKeys,
} from 'constants/appStores';
import { ParsedUrlQuery } from 'querystring';
import { AppStoreCampaigns } from 'constants/appStores';

const getAppStoreRedirectUrl = ({ campaign }: { campaign: AppStoreCampaigns }): string => {
  const queryParamsString = `${APPSTORE_CAMPAIGN_KEY}=${campaign}`;

  return `${APP_STORE_EXTERNAL_REDIRECT}?${queryParamsString}`;
};

export const getAppStoreByKey = ({
  appStoreKey,
  campaign,
}: {
  appStoreKey: AppStoreKeys;
  campaign: AppStoreCampaigns;
}): (typeof APP_STORES)[AppStoreKeys] => {
  return {
    ...APP_STORES[appStoreKey],
    url: getAppStoreRedirectUrl({ campaign }),
  };
};

export const getAppStoreByUserDevice = ({
  campaign,
}: {
  campaign: AppStoreCampaigns;
}): (typeof APP_STORES)[AppStoreKeys] => {
  const parser = new UAParser();
  const osName = parser.getOS().name;

  let storeKey: AppStoreKeys = AppStoreKeys.desktop; // Default store
  if (osName === 'Android') {
    storeKey = AppStoreKeys.googlePlay;
  } else if (osName === 'iOS') {
    storeKey = AppStoreKeys.appleStore;
  }

  return getAppStoreByKey({ appStoreKey: storeKey, campaign });
};
type GetAppStoreLinkParams = {
  userAgent: string;
  parsedUrlQuery: ParsedUrlQuery;
};

export const getInternalAppLink = ({
  userAgent,
  parsedUrlQuery,
}: GetAppStoreLinkParams): string => {
  const parser = new UAParser(userAgent);
  const osName = parser.getOS().name; // Operating System name

  const queryParams = new URLSearchParams();
  Object.entries(parsedUrlQuery).forEach(([key, value]) => {
    if (value !== undefined) {
      if (Array.isArray(value)) {
        value.forEach((item) => queryParams.append(key, item));
      } else {
        queryParams.append(key, value ?? '');
      }
    }
  });

  const queryString = queryParams.toString();
  const querySuffix = queryString ? `?${queryString}` : '';

  switch (osName) {
    case 'Android':
      const querySuffixAmpersand = queryString
        ? `&referrer=${encodeURIComponent(queryString)}`
        : '';
      return `${GOOGLE_PLAY_STORE_APP_URL}${querySuffixAmpersand}`;

    case 'iOS':
      return `${APPLE_STORE_APP_URL}${querySuffix}`;

    default:
      return `${DESKTOP_APP_URL}${querySuffix}`;
  }
};
