import BannerEventTag from 'modules/tracking/tags/BannerEventTag';

export default class BannerEventFactory {
  static bannerClick({
    id,
    promoName,
    creative,
    position,
  }: {
    id: string;
    promoName: string;
    creative: string;
    position: string;
  }): BannerEventTag {
    return new BannerEventTag({
      promoId: id,
      promoName: promoName,
      promoPosition: position,
      promoCreative: creative,
    });
  }
}
