import { ProductAddress } from 'types/product';
import { Coordinates, NotNullCoordinates } from 'types/geolocation';
import getClosestAddressesToCoordinates from 'lib/address/getClosestAddressesToCoordinates';

export const resolveClosestAddressToUser = (
  addresses: ProductAddress[],
  userCoordinates?: Coordinates | null
): ProductAddress => {
  return userCoordinates &&
    userCoordinates?.longitude !== null &&
    userCoordinates?.latitude !== null
    ? getClosestAddressesToCoordinates(addresses, userCoordinates as NotNullCoordinates, 1)[0]
    : addresses[0];
};

export default resolveClosestAddressToUser;
