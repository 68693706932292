import validation from 'lib/validation';
import { Address, AddressFormValues, CountryCode } from 'types/types';

export function isFullAddress(a: Address | null | undefined): boolean {
  return !!a && Object.keys(a).length > 7;
}

export const isValidZipcode = (zipcode: string, countryCode: string): boolean => {
  let postalCodeRegExp = /^\d{4}\s?\w{2}$/;

  if (countryCode === 'BE') {
    postalCodeRegExp = /^\d{4}$/;
  }

  return postalCodeRegExp.test(zipcode.trim());
};

export function isValidAddressZipcode(newState: {
  houseNumberExtension: string;
  lastApiCall: number;
  city: string;
  street: string;
  countryCode: CountryCode;
  postalCode: string;
  houseNumber: string;
}): boolean {
  const postalCodeRegExpBE = /^\d{4}$/;
  const postalCodeRegExpNL = /^\d{4}\s?\w{2}$/;

  if (newState.houseNumber?.toString().trim().length == 0) {
    return false;
  }

  if (
    ['NL'].includes(newState.countryCode) &&
    postalCodeRegExpNL.test(newState.postalCode?.trim())
  ) {
    return true;
  }

  if (
    ['BE'].includes(newState.countryCode) &&
    postalCodeRegExpBE.test(newState.postalCode?.trim())
  ) {
    return true;
  }

  return false;
}

export function isTheSameAddress(address1?: Address | null, address2?: Address | null): boolean {
  if (!address1 || !address2) {
    return true;
  }

  const keysToBeTheSame = [
    'firstName',
    'lastName',
    'countryCode',
    'street',
    'houseNumber',
    'houseNumberExtension',
    'postalCode',
    'city',
  ] as (keyof Address)[];

  return keysToBeTheSame.reduce(
    (isSame: boolean, key) => isSame && address2[key] === address1[key],
    true
  );
}

export function validateAddressFormValues(
  addressFieldName: string,
  values: AddressFormValues
): any {
  let errors,
    addressFieldErrors = {};

  errors = {
    ...validation.validateAlphaString(
      values.firstName as string,
      'firstName',
      2,
      'app.ui.firstName.incorrect'
    ),
    ...validation.validateAlphaString(
      values.lastName as string,
      'lastName',
      2,
      'app.ui.lastName.incorrect'
    ),
  };

  addressFieldErrors = {
    ...validation.validateAlphaString(
      values.address.city as string,
      'city',
      2,
      'app.ui.address.city.error'
    ),
    ...validation.validateAlphaString(
      values.address.street as string,
      'street',
      2,
      'app.ui.address.street.error'
    ),
    ...validation.validateAlphaString(
      values.address.countryCode as string,
      'countryCode',
      2,
      'app.ui.choose_country'
    ),
    ...validation.validateAlphaNumericString(
      values.address.postalCode as string,
      'postalCode',
      2,
      'app.ui.address.zipcode.error'
    ),
    ...validation.validateAlphaNumericString(
      values.address.houseNumber.toString(),
      'houseNumber',
      1,
      'app.ui.address.housenumber.error'
    ),
  };

  if (Object.keys(addressFieldErrors).length > 0) {
    // @ts-ignore
    errors[addressFieldName] = addressFieldErrors;
  }
  return errors;
}
