import AbstractTag from '../AbstractTag';

export default class UserDetailsChange extends AbstractTag {
  constructor() {
    super();

    this.addProp(AbstractTag.EVENT, 'user_details_change');
    this.addProp(AbstractTag.EVENT_NAME, 'user_details_change');
  }

  set userId(value: string) {
    this.addProp('user_id', value);
  }
}
