import AbstractTag from '../AbstractTag';
import { OrderSummary } from 'types/types';

export default class OrderPurchasedTag extends AbstractTag {
  constructor(order: OrderSummary) {
    super();

    this.addProp(AbstractTag.EVENT, 'purchase');
    this.addOrderSummary(order);
  }
}
