import config from 'next/config';
import { addYears } from 'date-fns';
import { useCookies } from 'react-cookie';
import { FC, PropsWithChildren } from 'react';
import {
  Experiment,
  GrowthBook,
  GrowthBookProvider as Provider,
  GrowthBookSSRData,
  Result,
} from '@growthbook/growthbook-react';

import { isServer } from 'lib/env';
import { getUuidCookie } from 'components/growthbook/growthbook.utils';
import { defaultOptions, GB_UUID } from 'components/growthbook/growthbook.constants';
import useResponsive from 'hooks/useResponsive';
import { trackGrowthBook } from 'modules/tracking/tags/GrowthBookTag';

const { publicRuntimeConfig } = config();

interface Props extends PropsWithChildren {
  data: GrowthBookSSRData;
}

export const GrowthBookProvider: FC<Props> = ({ data, children }) => {
  const [_, setCookie] = useCookies([GB_UUID]);
  const {
    growthBook: { enabled },
  } = publicRuntimeConfig;
  const { isMobile } = useResponsive();

  if (!data || !enabled) return <>{children}</>;

  if (!isServer() && !getUuidCookie()) {
    setCookie(GB_UUID, data.attributes.id, {
      expires: addYears(new Date(), 1),
      secure: true,
      path: '/',
    });
  }

  const growthBook = new GrowthBook({
    ...defaultOptions,
    ...data,
    attributes: {
      ...data.attributes,
      isMobile,
    },
    trackingCallback: (experiment: Experiment<unknown>, result: Result<unknown>) => {
      const experimentId = experiment.key;
      const variationId = result.key;
      const featureId = result.featureId;
      if (!isServer()) {
        trackGrowthBook({ experimentId, variationId, featureId });
      }
    },
  });

  return <Provider growthbook={growthBook}>{children}</Provider>;
};
