import { Coordinates, GeoFilter } from 'types/geolocation';

export const DEFAULT_PRODUCTS_PER_PAGE = 9;
export const MIN_FITTING_BOUNDARIES = 5;
export const DEFAULT_ZOOM_LEVEL = 12;

export const DESKTOP_MAP_HEIGHT = 450;
export const MOBILE_MAP_HEIGHT = 300;

export const EARTH_RADIUS_IN_KM = 6371;

export const CITY_GEO_FILTER_COOKIE_NAME = 'cityGeoFilter';
export const CITY_GEO_FILTER_COOKIE_MAX_AGE = 60 * 60 * 24 * 365;

export const defaultMapOptions: google.maps.MapOptions = {
  mapId: 'd247864c2343c87e',
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: false,
  clickableIcons: false,
};

export enum GEOFILTER_TYPE {
  INIT,
  GPS,
  AUTOCOMPLETE,
  CITY,
  IP,
  CITY_COOKIE,
}

export const defaultGeoFilter: GeoFilter = {
  type: GEOFILTER_TYPE.INIT,
  query: { latitude: null, longitude: null },
  label: null,
  listHeading: null,
  city: null,
};

export const initialClientCoordinates: Coordinates = {
  latitude: null,
  longitude: null,
};

export const URL_QUERY_CENTERPOINT_LATITUDE = 'centerlat';
export const URL_QUERY_CENTERPOINT_LONGITUDE = 'centerlng';

export enum LOCATION_SELECTOR_VARIANT {
  NAVBAR,
  NEWSLETTER,
}

export const isNewsletterLocationSelectorVariant = (variant: LOCATION_SELECTOR_VARIANT) =>
  variant === LOCATION_SELECTOR_VARIANT.NEWSLETTER;

export const OPTIONAL_LOCATION: Coordinates = {
  latitude: 0,
  longitude: 0,
};
