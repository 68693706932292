import { ExtendedProduct, Product } from 'types/types';
import { doFetch, resolveApiUrl } from 'lib/api';
import { transformProductJson } from 'lib/transforms';
import API_ENDPOINTS from 'constants/routes/api';
import { captureError } from 'lib/error';
import { SELLABLE_PRODUCTS_QUERY_IDENTIFIER } from 'constants/product';

export type FetchProductsByIds = {
  productIds: number[];
};

export async function fetchSellableProductsById({
  productIds,
}: FetchProductsByIds): Promise<ExtendedProduct[]> {
  const query = productIds.map((id) => `${SELLABLE_PRODUCTS_QUERY_IDENTIFIER}=${id}`).join('&');

  const url = resolveApiUrl(API_ENDPOINTS.GetProductsById(query));
  let res;
  try {
    res = await doFetch(url);
  } catch (e: unknown) {
    console.error('fetchSellableProductsById error', e);
    captureError(e as Error);

    throw e;
  }

  if (!res.ok) {
    const message = `fetchSellableProductsById response as not ok. statusCode: ${res.status} `;
    console.error(message);
    const error = new Error(message);
    captureError(error);

    throw error;
  }

  const productsJson = await res.json();

  return productsJson
    ? productsJson
        .filter((p: any) => p !== null)
        .map(transformProductJson)
        .filter(Boolean)
        // Sort products by the order of productIds. Hard to do on BE, so implemented in FE.
        .sort((a: Product, b: Product) => productIds.indexOf(a.id) - productIds.indexOf(b.id))
    : [];
}

export default fetchSellableProductsById;
