import { useEffect, useState } from 'react';
import NewsletterDialog from './NewsletterDialog';
import useStore from 'store';
import { useRouter } from 'next/router';
import CheckoutRoutes from 'constants/routes/checkout';

const NewsletterSubscribeProvider = () => {
  const router = useRouter();
  const [hasShownNewsletterPopup, setHasShownNewsletterPopup] = useStore('hasShownNewsletterPopup');
  const [blurred, setBlurred] = useState(false);
  const isCheckoutFlow = Object.values(CheckoutRoutes).some((value: any) =>
    router.asPath.includes(value)
  );

  useEffect(() => {
    const onBlur = () => {
      if (!hasShownNewsletterPopup) {
        setBlurred(true);
      }
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('blur', onBlur);
      return () => window.removeEventListener('blur', onBlur);
    }

    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!blurred || hasShownNewsletterPopup || isCheckoutFlow) return null;
  return (
    <NewsletterDialog
      dialogOpen={true}
      setDialogOpen={async () => {
        await setHasShownNewsletterPopup(true);
      }}
    />
  );
};

export default NewsletterSubscribeProvider;
