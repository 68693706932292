import { Banner, BareProduct, Cart, Product, TextBanner, Variant } from 'types/types';
import {
  CartProduct,
  TrackingProduct,
  TrackingProductList,
  TrackingPromoImpression,
} from 'types/tracking';
import { castToVariantOrNull } from 'lib/products/castToVariantOrNull';

export function getVariant(product: Product): Variant {
  return castToVariantOrNull(product.defaultVariant) || product.variants[0];
}

export function formatTrackingPrice(price?: number): string {
  if (!price) {
    return '0.00';
  }

  return (price / 100).toFixed(2).replace('.', '.');
}

export function mapCartToImpressions({ cart }: { cart: Cart }): TrackingProduct[] {
  return (
    cart.items.map((item, index) =>
      productToImpression(item.product, item.variant, TrackingProductList.cart, index + 1)
    ) || []
  );
}

export function productsToImpressions({
  products,
  productList,
}: {
  products: Product[];
  productList: TrackingProductList;
}): TrackingProduct[] {
  return (
    products.map((product, index) =>
      productToImpression(product, getVariant(product), productList, index + 1)
    ) || []
  );
}

export function productToImpression(
  product: Product,
  variant: Variant,
  productList: TrackingProductList,
  productPosition: number
): TrackingProduct {
  return {
    product_id: product.id.toString(),
    product_sku: (product as any).sku || null,
    product_name: product.name?.toLowerCase(),
    product_category_id: product.mainTaxon?.id?.toString(10).toLowerCase() || null,
    product_category: product.mainTaxon?.name?.toLowerCase() || null,
    product_variant: variant?.name?.toLowerCase() || null,
    product_price: formatTrackingPrice(variant?.price) || null,
    original_product_price: formatTrackingPrice(variant?.originalPrice || 0),
    product_type: 'main',
    product_list: productList,
    product_position: productPosition.toString(10),
    tradetracker_category_id: product.mainTaxon?.tradeTrackerCategory || null,
  };
}

export function bannerToPromoImpression(
  banner: Banner,
  position: number,
  format: string
): TrackingPromoImpression {
  return {
    promo_id: banner.id.toString(),
    promo_name: banner.code.toLowerCase(),
    promo_creative: format,
    promo_position: position,
  };
}

export function productToProductClick(
  product: BareProduct,
  variant: Variant,
  productPosition?: number,
  productList?: TrackingProductList | null
): TrackingProduct {
  const trackingProduct: TrackingProduct = {
    product_id: product.id.toString(10),
    product_name: product.name?.toLowerCase() || '',
    product_category: product.mainTaxon?.name?.toLowerCase() || null,
    product_category_id: product.mainTaxon?.id?.toString(10).toLowerCase() || null,
    product_position: productPosition?.toString(10),
    product_price: formatTrackingPrice(variant?.price) || '',
    original_product_price: formatTrackingPrice(variant?.originalPrice || 0) || null,
    product_sku: variant?.sku?.toLowerCase() || null,
    product_variant: variant?.name?.toLowerCase() || null,
    product_type: 'main',
    tradetracker_category_id: product.mainTaxon?.tradeTrackerCategory?.toLowerCase() || null,
  };

  if (productList) trackingProduct.product_list = productList.toLowerCase();
  if (productPosition) trackingProduct.product_position = productPosition.toString(10);

  return trackingProduct;
}

export function productInCartDetails(
  product: BareProduct,
  variant: Variant,
  productPosition: number,
  quantity: number,
  productList: TrackingProductList | null
): CartProduct {
  return {
    ...productToProductClick(product, variant, productPosition, productList),
    product_quantity: quantity.toString(10),
  };
}

type GetImpressionsFromHomePageData = {
  mainProducts: Product[];
  popularProducts: Product[];
  mainDeal: Product | null;
  highlightedProducts: Product[];
  newProducts: Product[];
  inYourAreaProducts: Product[];
  reminderCartItems: {
    product: Product;
    variant: Variant;
  }[];
};

export function getImpressionsFromHomePageData({
  mainProducts,
  popularProducts,
  mainDeal,
  highlightedProducts,
  newProducts,
  inYourAreaProducts,
  reminderCartItems,
}: GetImpressionsFromHomePageData): TrackingProduct[] {
  const impressions: TrackingProduct[] = [];

  let productPosition = 1;

  reminderCartItems.forEach((cartItem, index) => {
    productPosition++;
    impressions.push(
      productToImpression(
        cartItem.product,
        cartItem.variant,
        TrackingProductList.cartReminderProducts,
        productPosition
      )
    );
  });

  inYourAreaProducts.forEach((product) => {
    productPosition++;
    impressions.push(
      productToImpression(
        product,
        getVariant(product),
        TrackingProductList.locationProducts,
        productPosition
      )
    );
  });

  if (mainDeal) {
    productPosition++;
    impressions.push(
      productToImpression(
        mainDeal,
        getVariant(mainDeal),
        TrackingProductList.mainDeal,
        productPosition
      )
    );
  }

  mainProducts.forEach((product) => {
    productPosition++;
    impressions.push(
      productToImpression(product, getVariant(product), TrackingProductList.home, productPosition)
    );
  });

  popularProducts.forEach((product) => {
    productPosition++;
    impressions.push(
      productToImpression(
        product,
        getVariant(product),
        TrackingProductList.popularProducts,
        productPosition
      )
    );
  });

  newProducts.forEach((product) => {
    productPosition++;
    impressions.push(
      productToImpression(
        product,
        getVariant(product),
        TrackingProductList.newProducts,
        productPosition
      )
    );
  });

  highlightedProducts.forEach((product) => {
    productPosition++;
    impressions.push(
      productToImpression(
        product,
        getVariant(product),
        TrackingProductList.specialForYouProducts,
        productPosition
      )
    );
  });

  return impressions;
}

export function getPromoImpressionsFromHomePageBanners(
  banners: Banner[]
): TrackingPromoImpression[] {
  let index = 0;
  let impressions: TrackingPromoImpression[] = [];
  banners.map((banner) => {
    if (banner) {
      impressions.push(bannerToPromoImpression(banner, index + 1, '304x112'));
      index++;
    }
  });

  return impressions;
}
