import AbstractTag from '../AbstractTag';
import { BareProduct } from 'types/types';
import { TrackingProduct } from 'types/tracking';

export default class PreAddToCartClickTag extends AbstractTag {
  private static EVENT_ACTION_ADD_TO_CART = 'add_to_cart';
  private static EVENT_CATEGORY_CLICK = 'click';

  constructor(product: BareProduct) {
    super();

    this.addProp(AbstractTag.EVENT, PreAddToCartClickTag.EVENT_CATEGORY_CLICK);
    this.addProp(AbstractTag.EVENT_CATEGORY, PreAddToCartClickTag.EVENT_CATEGORY_CLICK);
    this.addProp(AbstractTag.EVENT_ACTION, PreAddToCartClickTag.EVENT_ACTION_ADD_TO_CART);
    this.addProp(AbstractTag.EVENT_LABEL, product.id.toString());
  }

  set product(value: TrackingProduct) {
    this.addProp('product', [value]);
  }
}
