import { NotNullCoordinates } from 'types/geolocation';
import { ProductAddress } from 'types/product';
import getDistance from 'lib/address/getDistance';

const getClosestAddressesToCoordinates = (
  addresses: ProductAddress[],
  coordinates: NotNullCoordinates,
  numberOfAddresses: number
): ProductAddress[] => {
  const addressMap = new Map<string, ProductAddress[]>();

  addresses.forEach((address) => {
    const distance = getDistance(address, coordinates);
    const key = distance.toString();
    if (addressMap.has(key)) {
      const existing = addressMap.get(key) as ProductAddress[];
      addressMap.set(key, [...existing, address]);
    } else addressMap.set(key, [address]);
  });

  const distances = Array.from(addressMap.keys());

  const sortedDistances = distances
    .sort((n1, n2) => parseFloat(n1) - parseFloat(n2))
    .slice(0, numberOfAddresses);

  const sortedProductAddress: ProductAddress[] = [];

  sortedDistances.forEach((distance) => {
    const addresses = addressMap.get(distance) as ProductAddress[];
    sortedProductAddress.push(...addresses);
  });

  return sortedProductAddress;
};

export default getClosestAddressesToCoordinates;
