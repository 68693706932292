import { doFetch, resolveApiUrl } from 'lib/api';
import { transformProductJson } from 'lib/transforms';
import { Product } from 'types/types';
import { captureTypeErrorIfInvalid } from 'lib/error';
import API_ENDPOINTS from 'constants/routes/api';
import { RecommendedProductLocation } from 'constants/product';

export default async function fetchRecommendedProducts(
  location: RecommendedProductLocation,
  orderTokenValue?: string,
  productId?: number
): Promise<Product[]> {
  const endpoint = resolveApiUrl(API_ENDPOINTS.GetRecommendedProducts(location));
  const res = await doFetch(endpoint, {}, { orderTokenValue, productId });

  const productsJson = await res.json();

  const productArray = productsJson?.length
    ? productsJson
        ?.filter((p: any) => p !== null)
        .map(transformProductJson)
        .filter(Boolean)
    : [];

  productArray.forEach((r: Product) => captureTypeErrorIfInvalid('Product', r));

  return productArray;
}
