import { HYDRATE } from 'next-redux-wrapper';
import { createAction } from '@reduxjs/toolkit';
import { GetServerSidePropsResult } from 'next';

import { AppStore, AppThunk, RootState } from 'redux/appStore';

export const APP_HYDRATE = createAction<RootState>(HYDRATE);

export async function fetchPageData(
  store: AppStore,
  actions: AppThunk[]
): Promise<GetServerSidePropsResult<Record<string, unknown>>> {
  try {
    await Promise.all(actions.map((a) => store.dispatch(a)));
  } catch (e) {
    console.error(e);
    throw e;
  }

  return {
    props: {},
  };
}

export default fetchPageData;
