import { PaginationQuery, Product, ProductQuery } from 'types/types';
import { doFetch, resolveApiUrl } from 'lib/api';
import { transformProductJson } from 'lib/transforms';
import { captureTypeErrorIfInvalid } from 'lib/error';
import API_ENDPOINTS from 'constants/routes/api';
import { PAGINATION_REQUEST_DEFAULTS } from 'constants/pagination';
import { throwUnsupportedError } from 'api/utils';

export async function fetchProducts({
  order,
  direction,
  taxonSlug = '',
  productName = '',
  page = PAGINATION_REQUEST_DEFAULTS.page,
  itemsPerPage = PAGINATION_REQUEST_DEFAULTS.itemsPerPage,
}: ProductQuery & PaginationQuery<ProductQuery>): Promise<Product[]> {
  const query = {
    'translations.name': productName,
    'productTaxons.taxon.translation.slug': taxonSlug,
    page,
    itemsPerPage,
    [`order[${order}]`]: order ? direction || 'asc' : undefined,
  };

  const endpoint = resolveApiUrl(API_ENDPOINTS.GetProducts);

  const res = await doFetch(endpoint, {}, query);

  if (!res.ok) throwUnsupportedError(endpoint, res);

  const productsJson = await res.json();

  const products = productsJson
    ? productsJson
        .filter((p: any) => p !== null)
        .map(transformProductJson)
        .filter(Boolean)
    : [];

  products?.forEach((r: Product) => captureTypeErrorIfInvalid('Product', r));
  return products;
}

export default fetchProducts;
