import { useSession as useNextSession } from 'next-auth/react';
import { Session } from 'types/types';

export const useSession = (required?: boolean): Session => {
  const { data, status } = useNextSession({
    required: required || false,
  });
  const session = data as unknown as Session['session'] | null;
  const token = session?.token || null;

  return { session, status, token };
};

export default useSession;
