import { createContext, PropsWithChildren, useEffect, useRef } from 'react';

export const ResizeContext = createContext<{
  registerResizeFunction: (fn: () => void) => void;
  unregisterResizeFunction: (fn: () => void) => void;
} | null>(null);

export const ResizeProvider = ({ children }: PropsWithChildren) => {
  const resizeFunctions = useRef<(() => void)[]>([]);

  useEffect(() => {
    const handleResize = () => {
      resizeFunctions.current.forEach((fn) => fn());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const registerResizeFunction = (fn: () => void) => {
    resizeFunctions.current.push(fn);
  };

  const unregisterResizeFunction = (fn: () => void) => {
    resizeFunctions.current = resizeFunctions.current.filter((f) => f !== fn);
  };

  return (
    <ResizeContext.Provider value={{ registerResizeFunction, unregisterResizeFunction }}>
      {children}
    </ResizeContext.Provider>
  );
};
