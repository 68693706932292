import { isDevelopment } from 'lib/react-with-native-env/isDevelopment';
import { captureError } from 'lib/error/newrelic';

export const captureTypeErrorIfInvalid = (type: any, value: any) => {
  try {
    // validate(type)(value);
  } catch (error) {
    const message = `(type: ${type}, value: ${JSON.stringify(value)}): ${error}`;
    if (isDevelopment()) {
      if (process.env.throwOnValidate === 'true') throw Error(message);
    } else {
      captureError(new Error('Invalid type found'), {
        type,
        value,
        error,
        windowLocationHref: typeof window !== 'undefined' && window.location.href,
      });
    }
  }
};
