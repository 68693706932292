import { createApi } from '@reduxjs/toolkit/query/react';
import { Cart } from 'types/types';
import gatewayBaseQuery from 'lib/gateway/gatewayBaseQuery';
import { apiGatewayEndpoints } from 'lib/gateway/endpoints';
import { transformCartJson } from 'lib/transforms';
import { isHydrateAction } from 'redux/query/common.query';
import { HTTP_METHODS } from 'constants/api';

const cartApi = createApi({
  reducerPath: 'cartApi',
  baseQuery: gatewayBaseQuery(),
  extractRehydrationInfo(action, { reducerPath }): any {
    if (isHydrateAction(action)) return action.payload[reducerPath];
  },
  endpoints: (builder) => ({
    createCart: builder.mutation<Cart, void>({
      query: () => ({
        url: apiGatewayEndpoints.CreateCart,
        method: HTTP_METHODS.POST,
      }),
      transformResponse: transformCartJson,
    }),
    fetchCart: builder.query<Cart, string>({
      query: (tokenValue) => apiGatewayEndpoints.GetCart(tokenValue),
      transformResponse: transformCartJson,
    }),
    deleteCart: builder.mutation<Cart, string>({
      query: (tokenValue) => ({
        url: apiGatewayEndpoints.DeleteCart(tokenValue),
        method: HTTP_METHODS.DELETE,
      }),
      transformResponse: transformCartJson,
    }),
    applyCoupon: builder.mutation<Cart, string>({
      query: (tokenValue) => ({
        url: apiGatewayEndpoints.CreatePromotion(tokenValue),
        method: HTTP_METHODS.POST,
      }),
      transformResponse: transformCartJson,
    }),
    deleteCoupon: builder.mutation<Cart, string>({
      query: (tokenValue) => ({
        url: apiGatewayEndpoints.DeletePromotion(tokenValue),
        method: HTTP_METHODS.DELETE,
      }),
      transformResponse: transformCartJson,
    }),
  }),
});

export const {
  useCreateCartMutation,
  useFetchCartQuery,
  useDeleteCartMutation,
  useApplyCouponMutation,
  useDeleteCouponMutation,
} = cartApi;

export default cartApi;
