import { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { captureError, captureResponseException, newRelicErrorMessages } from 'lib/error';
import { getDefaultToastOptions } from 'src/contexts/notifications/ToastContext';
import { getSession } from 'next-auth/react';
import { SessionValue } from 'types/types';
import { toast } from 'react-toastify';
import { ErrorResponse } from 'types/errors';

const isMobile = typeof window === 'undefined' ? false : window?.innerWidth < 768;
const toastOptions = getDefaultToastOptions(isMobile);

const RequestInterceptor = {
  async success(config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> {
    try {
      const session = (await getSession()) as SessionValue | null;
      if (session?.token) config.headers['Authorization'] = `Bearer ${session.token}`;
    } catch (error: unknown) {
      captureError(Error(newRelicErrorMessages.reqAuthFail(config.url)), { data: error });
    }
    return config;
  },
  async failure(error: unknown) {
    captureError(Error(newRelicErrorMessages.reqInterceptorFail), { data: error });
    return Promise.reject(error);
  },
};

const ResponseInterceptor = {
  success(value: any) {
    return value;
  },
  async failure(err: AxiosError) {
    const error = new Error(newRelicErrorMessages.respInterceptorFail(err.message), err);

    const errorResponse = err?.response?.data as ErrorResponse;
    const message = errorResponse?.detail || err.message;

    await toast.error(message, toastOptions);
    captureResponseException(error);
    return Promise.reject(err);
  },
};

export { RequestInterceptor, ResponseInterceptor };
