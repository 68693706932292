export const makeNumber = (string: string | number | null | undefined) => {
  return typeof string === 'number'
    ? string
    : !string || isNaN(parseInt(string))
    ? -1
    : parseInt(string);
};

export const areFloatsApproximatelyEqual = (
  first: number | null,
  second: number | null,
  dp: number = -3
): boolean => {
  if (!first || !second) return false;
  return `${first}`.slice(0, dp) === `${second}`.slice(0, dp);
};

export function nFormatter(input: number, digits: number = 1): string {
  if (!input || isNaN(input)) return '0';

  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];

  const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((item) => input >= item.value);

  return item ? (input / item.value).toFixed(digits).replace(regexp, '').concat(item.symbol) : '0';
}
