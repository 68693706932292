import fetchAccount from 'api/user/fetchAccount';
import updateUser from 'api/user/updateUser';
import updatePassword from 'api/user/updatePassword';
import resetPassword from 'api/user/resetPassword';
import createPasswordReset from 'api/user/createPasswordReset';

export const userApi = {
  fetchAccount,
  updateUser,
  updatePassword,
  resetPassword,
  createPasswordReset,
};

export default userApi;
