import { doPost, resolveApiUrl } from 'lib/api';
import API_ENDPOINTS from 'constants/routes/api';
import { Coordinates } from 'types/geolocation';
import { removeNullValueKeys } from 'lib/object';

export default async function subscribeToNewsletter(
  email: string,
  location: string,
  coordinates: Coordinates | {} = {}
): Promise<boolean> {
  const endpoint = resolveApiUrl(API_ENDPOINTS.CreateNewsletterSubscription);
  const body = removeNullValueKeys({ email, location, ...coordinates });

  const response = await doPost(endpoint, body);

  return response.ok;
}
