import axios, { AxiosInstance, RawAxiosRequestHeaders } from 'axios';
import { getPublicRuntimeConfig } from 'lib/api';
import { RequestInterceptor, ResponseInterceptor } from 'lib/gateway/interceptors';

const {
  apiGateway: { url: apiGatewayURL, version, channel },
} = getPublicRuntimeConfig();

const PLATFORM = 'web';

export const gatewayDefaultHeaders: RawAxiosRequestHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'X-CHANNEL-CODE': channel,
  'Accept-Version': version,
  'X-PLATFORM': PLATFORM,
};

export const gateway: AxiosInstance = axios.create({
  baseURL: apiGatewayURL,
  headers: gatewayDefaultHeaders,
});

gateway.interceptors.request.use(RequestInterceptor.success, RequestInterceptor.failure, {
  synchronous: false,
});

gateway.interceptors.response.use(ResponseInterceptor.success, ResponseInterceptor.failure, {
  synchronous: false,
});

export default gateway;
