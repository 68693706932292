import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { trackBannerClick } from 'modules/tracking/events/events';
import { Banner } from 'types/types';
import Image from 'next/image';
import { APP_PROMO_BANNER_ID, AppStoreDetails } from 'src/constants/appStores';

type AppPromoStoreLinkProps = {
  appStore: AppStoreDetails;
  trackingPosition: number;
  width: number;
  height: number;
  shouldOpenInNewTab?: boolean;
};

export const AppPromoStoreLink: FC<AppPromoStoreLinkProps> = ({
  appStore,
  trackingPosition,
  width,
  height,
  shouldOpenInNewTab = false,
}) => {
  const { t } = useTranslation();

  const handleClick = () => {
    void trackBannerClick({
      banner: {
        id: APP_PROMO_BANNER_ID,
        code: appStore.trackingCodes.logo,
      } as Banner,
      width: width.toString(),
      height: height.toString(),
      position: trackingPosition,
    });
  };

  if (!appStore.imgSrc) return null;

  return (
    <a
      href={appStore.url}
      className="whitespace-nowrap outline-none"
      target={shouldOpenInNewTab ? '_blank' : undefined}
      rel={shouldOpenInNewTab ? 'noopener noreferrer' : undefined}
      onClick={handleClick}
    >
      <Image src={appStore.imgSrc} width={width} height={height} alt={t(appStore.description)} />
    </a>
  );
};
