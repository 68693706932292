import { TrackingProductList, TrackingValue } from 'types/tracking';
import { OrderItem, OrderSummary } from 'types/types';
import formatMoneyForTag from 'modules/tracking/util/formatMoneyForTag';
import { productInCartDetails } from 'modules/tracking/transforms/transforms';

export default class AbstractTag {
  static EVENT = 'event';
  static EVENT_NAME = 'event_name';
  static EVENT_CATEGORY = 'event_category';
  static EVENT_LABEL = 'event_label';
  static EVENT_ACTION = 'event_action';

  private readonly props: Record<string, TrackingValue | null | string>;

  constructor() {
    this.props = {};
  }

  addProp(name: string, value: TrackingValue | null | string) {
    if (!name) {
      return this;
    }

    this.props[name] = value;
    return this;
  }

  getData() {
    return { ...this.props };
  }

  addOrderSummary(order: OrderSummary) {
    const orderTotal = order.total - order.shippingTotal;
    this.addProp('order_id', order.id);
    this.addProp('order_total', formatMoneyForTag(orderTotal));
    this.addProp(
      'order_payment_type',
      order.payments.length > 0 ? order.payments[0].pspMethod : null
    );
    this.addProp('order_total_paid', formatMoneyForTag(order.total));
    this.addProp(
      'product',
      order.items.map((item: OrderItem, index: number) => {
        return productInCartDetails(
          item.product,
          item.variant,
          index + 1,
          item.quantity,
          TrackingProductList.purchase
        );
      })
    );
  }
}
