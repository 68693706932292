import AbstractTag from './tags/AbstractTag';
import Publisher from './publishers/Publisher';
import { Customer } from 'types/types';
import { TrackingContext } from 'types/tracking';

export default class Tracker {
  private readonly publishers: Publisher[] = [];

  private _userId: string | null = null;

  private _gigyaUid: string | null = null;

  private _customer: Customer | null = null;

  private _hasAcceptedMarketingCookies: boolean | null = null;

  addPublisher(publisher: Publisher) {
    this.publishers.push(publisher);
  }

  async publish(tag: AbstractTag) {
    const context = {
      customer: this._customer,
      hasAcceptedMarketingCookies: this._hasAcceptedMarketingCookies,
    } as TrackingContext;

    for (const publisher of this.publishers) {
      await publisher.publish(tag, context);
    }
  }

  set userId(value: string | null) {
    this._userId = value;
  }

  set gigyaUid(value: string | null) {
    this._gigyaUid = value;
  }

  set customer(customer: Customer | null) {
    this._customer = customer;
  }

  set hasAcceptedMarketingCookies(value: boolean) {
    this._hasAcceptedMarketingCookies = value;
  }
}
