import { DateValuePiece } from 'types/date';
import { LOCALES } from 'constants/locales';
import { getPublicRuntimeConfig } from 'lib/api';
import validation from 'lib/validation';
import { isValidDate } from 'lib/validation/date';

const { isNL } = getPublicRuntimeConfig();

export const prettyDate = (date: Date, opts?: Intl.DateTimeFormatOptions): string => {
  if (!date || !validation.isValidDate(date)) return '';

  const locale = isNL ? LOCALES.NL : LOCALES.NL_BE;
  return date.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    ...opts,
  });
};

export const prettyDateTime = (date: Date, opts?: Intl.DateTimeFormatOptions): string => {
  if (!date || !validation.isValidDate(date)) return '';

  const locale = isNL ? LOCALES.NL : LOCALES.NL_BE;
  return date.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long',
    hour: '2-digit',
    minute: '2-digit',
    ...opts,
  });
};

/*
Format dates for the API in the format Y-m-d
Defaults to current date if date arg is null
 */
export const formatDateForApi = (date: DateValuePiece): string => {
  if (!date) date = new Date();
  const year = date.getFullYear();
  const month = `${date.getMonth() + 1}`.padStart(2, '0');
  const day = `${date.getDate()}`.padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const formatDateTimeForApi = (
  dateStr: string | null,
  timeStr: string | null
): string | undefined => {
  if (!dateStr || !timeStr) return undefined;
  return `${dateStr} ${timeStr}`;
};

// given a time string HH:MM:SS => return HH:MM
const timeSeparator = ':';
export const trimSecondsFromTime = (time: string | null): string | null => {
  if (!time) return null;

  const hasSeconds = time.match(new RegExp(timeSeparator, 'g'))?.length === 2;
  if (!hasSeconds) return time;
  // find the index of the last ':'
  const lastIndex = time.lastIndexOf(timeSeparator);
  return time.substring(0, lastIndex);
};

// convert a 12h time format to the 24h equivalent if applicable
export const convert12hTimeTo24h = (timeStr: string): string => {
  const [time, modifier] = timeStr.split(' ');
  if (!modifier || (modifier !== 'AM' && modifier !== 'PM')) return timeStr;
  let [hours, minutes] = time.split(':');
  if (hours === '12') hours = '00';
  if (modifier === 'PM') hours = `${parseInt(hours, 10) + 12}`;
  return `${hours}:${minutes}`;
};

// given a valid date trime string get the 24h time from it
export const get24hTimeFromDateString = (dateTime: string): string => {
  if (!dateTime) return '';
  const date = new Date(dateTime);
  const hour = `${date.getHours()}`.padStart(2, '0');
  const mins = `${date.getMinutes()}`.padStart(2, '0');
  return `${hour}:${mins}`;
};

export const isFutureDate = (dateStr: string): boolean => {
  if (!dateStr) return false;
  const now = Date.now();
  const date = new Date(dateStr).getTime();
  return date - now > 0;
};

export const isPastDate = (dateStr: string): boolean => {
  if (!dateStr) return false;
  const date = new Date(dateStr).getTime();
  const now = Date.now();
  return date - now < 0;
};

export const createDateFromString = (dateString: string): Date | null => {
  if (!dateString) return null;

  const date = new Date(dateString);

  if (!isValidDate(date)) return null;

  return date;
};
