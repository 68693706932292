import AbstractTag from './AbstractTag';

export type NavigationOtherEventActions =
  | typeof NavigationOtherTag.EVENT_ACTION_GO_TO_MAPS
  | typeof NavigationOtherTag.EVENT_ACTION_GO_TO_LIST;

export class NavigationOtherTag extends AbstractTag {
  static EVENT_ACTION_GO_TO_MAPS = 'bekijk-op-de-kaart';
  static EVENT_ACTION_GO_TO_LIST = 'bekijk-lijst-weergave';

  constructor({ eventAction }: { eventAction: NavigationOtherEventActions }) {
    super();

    this.addProp(AbstractTag.EVENT, 'generic');
    this.addProp(AbstractTag.EVENT_NAME, 'regiodeals');
    this.addProp(AbstractTag.EVENT_ACTION, eventAction);
  }
}
