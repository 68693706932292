import axios from 'axios';
import { captureError } from 'lib/error/newrelic';

export const base = 'API Gateway response error';
export function captureResponseException(e: any) {
  let error: Error;

  if (axios.isAxiosError(e) || e instanceof Error) {
    error = Error(`${base}: ${e.message}`);
  } else if (typeof e === 'string') {
    error = Error(`${base}: ${e}`);
  } else {
    error = Error(base);
  }

  captureError(error, { data: e });
}

export default captureResponseException;
