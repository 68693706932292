import { Product } from 'types/product';
import { transformProductJson } from 'lib/transforms/product';
import { transformBanners } from 'lib/transforms/index';
import { captureTypeErrorIfInvalid } from 'lib/error';

export default function transformHomePageData(json: any) {
  const mainProducts: Product[] = json.mainProducts
    .filter((p: any) => p !== null)
    .map(transformProductJson)
    .filter(Boolean);

  const banners = transformBanners(json.banners);

  const mainDeal = json.mainDeal ? transformProductJson(json.mainDeal) : null;

  if (mainDeal) captureTypeErrorIfInvalid('Product', mainDeal);

  const highlightedProducts: Product[] = json.highlightedProducts
    .filter((p: any) => p !== null)
    .map(transformProductJson)
    .filter(Boolean);

  // TODO: REMOVE when migration to Gateway is complete => gateway vs sylius!!!
  const popular = json?.populairProducts ?? json.popularProducts;
  const populairProducts: Product[] = popular
    .filter((p: any) => p !== null)
    .map(transformProductJson)
    .filter(Boolean);

  const newProducts: Product[] =
    json.newProducts
      ?.filter((p: any) => p !== null)
      .map(transformProductJson)
      .filter(Boolean) || [];

  [...mainProducts, ...highlightedProducts, ...populairProducts, ...newProducts].forEach((p) =>
    captureTypeErrorIfInvalid('Product', p)
  );

  return {
    mainProducts,
    banners,
    mainDeal,
    highlightedProducts,
    populairProducts,
    newProducts,
  };
}
