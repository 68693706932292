import { PaginationQuery, Product, ProductQuery } from 'types/types';
import { doFetch, resolveApiUrl } from 'lib/api';
import { transformProductJson } from 'lib/transforms';
import { captureTypeErrorIfInvalid } from 'lib/error';
import API_ENDPOINTS from 'constants/routes/api';
import { PAGINATION_REQUEST_DEFAULTS } from 'constants/pagination';
import { throwUnsupportedError } from 'api/utils';

export async function fetchProductsSearch({
  page = 1,
  itemsPerPage = PAGINATION_REQUEST_DEFAULTS.itemsPerPage,
  productName = '',
  order,
  direction,
}: ProductQuery & PaginationQuery<ProductQuery>): Promise<Product[]> {
  const endpoint = resolveApiUrl(API_ENDPOINTS.GetProductsSearch);
  const query = { term: productName, page, itemsPerPage, sortBy: order, sortOrder: direction };

  const res = await doFetch(endpoint, {}, query);

  if (!res.ok) throwUnsupportedError(endpoint, res);

  const productsJson = await res.json();

  const products = productsJson
    ? productsJson
        .filter((p: any) => p !== null)
        .map(transformProductJson)
        .filter(Boolean)
    : [];

  products.forEach((r: Product) => captureTypeErrorIfInvalid('Product', r));

  return products;
}

export default fetchProductsSearch;
