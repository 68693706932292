import useSession from 'hooks/useSession';
import { signOut } from 'next-auth/react';
import { useEffect } from 'react';
import useStore from 'store';
import { trackUserLogin } from 'modules/tracking/events/events';
import useCallbackUrl from 'hooks/useCallbackUrl';
import userApi from 'api/user';

const AccountProvider = () => {
  const session = useSession();
  const [_, setCustomer] = useStore('customer');
  const { clearCallbackUrl } = useCallbackUrl();

  const handleError = (e: Error) => {
    setCustomer(null);
    signOut();
  };

  useEffect(() => {
    if (session.session?.token) {
      userApi
        .fetchAccount(session.session?.token)
        .then(async (response) => {
          await trackUserLogin({
            customer: response,
          });

          await setCustomer(response);
          await clearCallbackUrl();
        })
        .catch((e) => handleError(e));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.session?.token]);

  return null;
};

export default AccountProvider;
