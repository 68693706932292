import { doPost, resolveApiUrl } from 'lib/api';
import { CONTENT_TYPES } from 'constants/enums';
import API_ENDPOINTS from 'constants/routes/api';

export async function createPasswordReset(email?: string, captcha?: string | null): Promise<any> {
  const endpoint = resolveApiUrl(API_ENDPOINTS.CreatePasswordReset);
  const res = await doPost(
    endpoint,
    { email, captcha },
    {
      headers: {
        accept: CONTENT_TYPES.APPLICATION_JSON,
        'Content-Type': CONTENT_TYPES.APPLICATION_JSON,
      },
      mode: 'cors',
    }
  );

  return res.json();
}

export default createPasswordReset;
