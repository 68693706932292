import { createAsyncThunk } from '@reduxjs/toolkit';
import { FetchVariantAvailabilitiesForMonthQuery } from 'api/product/fetchAvailabilitiesForMonth';
import productApi from 'api/product';
import { FetchAvailabilityTimeslotsForDateQuery } from 'api/product/fetchAvailabilityTimeslotsForDate';
import { setLoading, setReservationForVariantAndDates } from 'redux/cart/reservation.slice';
import isReservationEnabled from 'lib/reservation/isReservationEnabled';
import { AppThunk } from 'redux/appStore';
import { formatDateForApi } from 'lib/date';
import { Loading } from 'constants/common';

export const fetchAvailableDates = createAsyncThunk(
  'cart/reservation/fetchAvailableDates',
  async (args: FetchVariantAvailabilitiesForMonthQuery) =>
    productApi.fetchAvailabilitiesForMonth(args)
);

export const fetchAvailableTimeslots = createAsyncThunk(
  'cart/reservation/fetchAvailableTimeslots',
  async (args: FetchAvailabilityTimeslotsForDateQuery) =>
    productApi.fetchAvailabilityTimeslotsForDate(args)
);

export const fetchReservationForVariant = (): AppThunk => async (dispatch, getState) => {
  const state = getState();
  const variant = state.addToCart.variant;
  const quantity = state.addToCart.quantity;
  if (!variant) return;
  try {
    let dates: string[] = [];

    if (isReservationEnabled(variant)) {
      dispatch(setLoading(Loading.PENDING));
      const availabilities = await productApi.fetchAvailabilitiesForMonth({
        quantity,
        variantId: variant.id,
        showFirstAvailability: true,
        date: formatDateForApi(new Date()),
      });

      dates = availabilities.map(({ date }) => date);
    }

    dispatch(setLoading(Loading.SUCCEEDED));
    dispatch(setReservationForVariantAndDates({ variant, dates }));
  } catch (error) {
    console.error(error);
    throw error;
  }
};
