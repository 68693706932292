import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Product } from 'types/product';
import { Variant } from 'types/types';
import { TrackingProductList } from 'types/tracking';
import { RootState } from 'redux/appStore';
import { APP_HYDRATE } from 'redux/actions';
import { QUANTITY_LABEL } from 'constants/enums';

export interface AddToCartState {
  product: Product | null;
  variant: Variant | null;
  quantity: number;
  showSlider?: boolean;
  submitted?: boolean;
  submitting?: boolean;
  productList?: TrackingProductList | null;
  previousProduct?: Product | null;
  isEdit: boolean;
  editOrderItemId: number | null;
  // TODO: Create a task to migrate ATC error from useCart hook to redux
  error: string | null;
}

const initialState: AddToCartState = {
  product: null,
  variant: null,
  quantity: 1,
  showSlider: false,
  submitted: false,
  submitting: false,
  previousProduct: null,
  productList: null,
  isEdit: false,
  editOrderItemId: null,
  error: null,
};

export const addToCartSlice = createSlice({
  name: 'addToCart',
  initialState,
  reducers: {
    setAddToCartProduct: (state, { payload }: PayloadAction<Product | null>) => {
      state.product = payload;
    },
    setAddToCartVariant: (state, { payload }: PayloadAction<Variant | null>) => {
      state.variant = payload;
    },
    setQuantity: (state, { payload }: PayloadAction<number | 1>) => {
      state.quantity = payload;
    },
    setShowSlider: (state, { payload }: PayloadAction<boolean>) => {
      state.showSlider = payload;
    },
    setSubmitted: (state, { payload }: PayloadAction<boolean>) => {
      state.submitted = payload;
    },
    setSubmitting: (state, { payload }: PayloadAction<boolean>) => {
      state.submitting = payload;
    },
    setProductList: (state, { payload }: PayloadAction<TrackingProductList | null>) => {
      state.productList = payload;
    },
    setPreviousProduct: (state, { payload }: PayloadAction<Product | null>) => {
      state.previousProduct = payload;
    },
    setIsEdit: (state, { payload }: PayloadAction<boolean>) => {
      state.isEdit = payload;
    },
    setEditOrderItemId: (state, { payload }: PayloadAction<number | null>) => {
      state.editOrderItemId = payload;
    },
    setAddToCartError: (state, { payload }: PayloadAction<string | null>) => {
      state.error = payload;
    },
    resetAddToCart: (state) => {
      state.product = initialState.product;
      state.variant = initialState.variant;
      state.quantity = initialState.quantity;
      state.showSlider = initialState.showSlider;
      state.submitted = initialState.submitted;
      state.submitting = initialState.submitting;
      state.previousProduct = initialState.previousProduct;
      state.productList = null;
      state.isEdit = initialState.isEdit;
      state.editOrderItemId = initialState.editOrderItemId;
      state.error = initialState.error;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(APP_HYDRATE, (clientState, { payload }) => {
      if (!payload.addToCart.product) return;
      return {
        ...clientState,
        ...payload.addToCart,
      };
    });
  },
});

const slice = (state: RootState) => state.addToCart;

export const AddToCartActions = addToCartSlice.actions;
export const {
  setAddToCartProduct,
  setAddToCartVariant,
  setQuantity,
  setShowSlider,
  setSubmitted,
  setSubmitting,
  setProductList,
  setPreviousProduct,
  resetAddToCart,
} = addToCartSlice.actions;

export const getAddToCartContext = (state: RootState) => state.addToCart;
export const getAddToCartProduct = createSelector(slice, (state) => state.product);
export const getAddToCartVariant = createSelector(slice, (state) => state.variant);
export const getAddToCartQuantity = createSelector(slice, (state) => state.quantity);
export const getAddToCartSubmitting = createSelector(slice, (state) => state.submitting);
export const getAddToCartIsEdit = createSelector(slice, (state) => state.isEdit);

export const selectTotalPrice = createSelector(slice, (state: AddToCartState) => {
  if (!state.product) return 0;

  return state.variant ? state.variant.price : state.product.defaultVariant.price;
});
export const selectTotalOriginalPrice = createSelector(slice, (state: AddToCartState) => {
  if (!state.product) return 0;

  return state.variant ? state.variant.originalPrice : state.product.defaultVariant.originalPrice;
});

export const getQuantityLabel = createSelector(slice, (state: AddToCartState) => {
  if (!state.product) return QUANTITY_LABEL.SET;

  return state.variant ? state.variant.quantityLabel : state.product.defaultVariant.quantityLabel;
});

export default addToCartSlice.reducer;
