import { useTranslation } from 'react-i18next';
import Usp from 'components/usp/Usp';

export function AppPromoUsps() {
  const { t } = useTranslation();

  return (
    <>
      <Usp usp={t('app.app_promo.usp1')} justify={'start'} />
      <Usp usp={t('app.app_promo.usp2')} justify={'start'} />
      <Usp usp={t('app.app_promo.usp3')} justify={'start'} />
    </>
  );
}
