import { FaqCategory } from 'types/types';
import { doFetch, resolveApiUrl } from 'lib/api';
import { captureError, captureTypeErrorIfInvalid } from 'lib/error';
import API_ENDPOINTS from 'constants/routes/api';

export default async function fetchFaqCategory(
  categoryId: number,
  channel?: string | null
): Promise<FaqCategory[]> {
  const endpoint = resolveApiUrl(API_ENDPOINTS.GetFaqCategory(categoryId));

  const res = await doFetch(endpoint, {
    headers: channel ? { 'x-sylius-channel': channel } : {},
  });

  if (!res.ok) {
    captureError(new Error(`Failed to fetch faq category ${categoryId}`));

    return [];
  }

  const category = await res.json();

  captureTypeErrorIfInvalid('FaqCategory', category);
  return [category];
}
