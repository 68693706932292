const CheckoutRoutes = {
  cart: '/cart',
  loginFromCart: '/login?from=cart',
  loginFromCheckout: '/login?from=checkout',
  loginFromCheckoutWEmail: (email?: string): string => `/login?from=checkout&email=${email}`,
  address: '/checkout/address',
  pay: '/checkout/pay',
  thankYou: '/checkout/thankyou',
  thankYouWithOrderDetails: (orderTokenValue?: string | null, orderId?: string | null): string =>
    `/checkout/thankyou/${orderTokenValue}?orderId=${orderId}`,
};

export default CheckoutRoutes;
