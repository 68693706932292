import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Product } from 'types/product';
import { Banner, TextBanner } from 'types/types';
import { RootState } from 'redux/appStore';
import { APP_HYDRATE } from 'redux/actions';
import { Loading } from 'constants/common';

export interface HomePageState {
  loadingStatus: Loading;
  mainProducts: Product[];
  banners: {
    primary: Banner[];
    secondary: Banner[];
  };
  mainDeal: Product | null;
  highlightedProducts: Product[];
  populairProducts: Product[];
  newProducts: Product[];
  inYourArea: {
    status: Loading;
    products: Product[];
  };
}

const initialState: HomePageState = {
  loadingStatus: Loading.IDLE,
  mainProducts: [],
  banners: {
    primary: [],
    secondary: [],
  },
  mainDeal: null,
  highlightedProducts: [],
  populairProducts: [],
  newProducts: [],
  inYourArea: {
    status: Loading.IDLE,
    products: [],
  },
};

export const homePageSlice = createSlice({
  name: 'homePage',
  initialState,
  reducers: {
    setHomePageLoadingStatus: (state, { payload }: PayloadAction<Loading>) => {
      state.loadingStatus = payload;
    },
    setMainProducts: (state, { payload }: PayloadAction<Product[]>) => {
      state.mainProducts = payload;
    },
    setMainDeal: (state, { payload }: PayloadAction<Product>) => {
      state.mainDeal = payload;
    },
    setNewProducts: (state, { payload }: PayloadAction<Product[]>) => {
      state.newProducts = payload;
    },
    setHighlightedProducts: (state, { payload }: PayloadAction<Product[]>) => {
      state.highlightedProducts = payload;
    },
    setPopularProducts: (state, { payload }: PayloadAction<Product[]>) => {
      state.populairProducts = payload;
    },
    setBanners: (state, { payload }: PayloadAction<{ primary: Banner[]; secondary: Banner[] }>) => {
      state.banners = payload;
    },
    setProductsInYourArea: (state, { payload }: PayloadAction<Product[]>) => {
      state.inYourArea.products = payload;
    },
    setInYourAreaStatus: (state, { payload }: PayloadAction<Loading>) => {
      state.inYourArea.status = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(APP_HYDRATE, (clientState, { payload }) => {
      if (!payload.homePage.mainProducts.length) return;
      return {
        ...clientState,
        ...payload.homePage,
      };
    });
  },
});

export const {
  setHomePageLoadingStatus,
  setBanners,
  setPopularProducts,
  setHighlightedProducts,
  setNewProducts,
  setMainProducts,
  setMainDeal,
  setProductsInYourArea,
  setInYourAreaStatus,
} = homePageSlice.actions;

export default homePageSlice.reducer;

export const slice = (state: RootState) => state.homePage;
export const selectMainProducts = createSelector(slice, (s) => s.mainProducts);
export const selectInYourAreaProducts = createSelector(slice, (s) => s.inYourArea.products);
export const selectInYourAreaStatus = createSelector(slice, (s) => s.inYourArea.status);
export const selectIsProductsInYourAreaPending = createSelector(
  selectInYourAreaStatus,
  (status) => status === Loading.PENDING
);
export const selectIsProductsInYourAreaIdle = createSelector(
  selectInYourAreaStatus,
  (status) => status === Loading.IDLE
);
export const selectIsProductsInYourAreaFinished = createSelector(
  selectInYourAreaStatus,
  (status) => status === Loading.SUCCEEDED || status === Loading.FAILED
);

export const selectPopularProducts = createSelector(slice, (s) => s.populairProducts);
export const selectNewProducts = createSelector(slice, (s) => s.newProducts);
export const selectHighlightedProducts = createSelector(slice, (s) => s.highlightedProducts);
export const selectBanners = createSelector(slice, (s) => s.banners);
export const selectMainDeal = createSelector(slice, (s) => s.mainDeal);
export const selectPrimaryBanner = createSelector(
  selectBanners,
  (s) => (s.primary && s.primary.length > 0 && s.primary[0]) || null
);
export const selectSecondaryBanners = createSelector(selectBanners, (s) => s.secondary);
export const selectAllBanners = createSelector(slice, (homePageState): Banner[] => {
  const allBanners = [];
  if (homePageState.banners.primary) allBanners.push(...homePageState.banners.primary);
  if (homePageState.banners.secondary) allBanners.push(...homePageState.banners.secondary);

  return allBanners;
});
export const selectIsHomePageDataLoading = createSelector(
  slice,
  (state) => state.loadingStatus === Loading.PENDING
);
export const selectIsHomePageDataLoadingError = createSelector(
  slice,
  (state) => state.loadingStatus === Loading.FAILED
);
