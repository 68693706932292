import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

const resources = {
  nl: {
    translation: require('../translations/nl.json'),
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: 'nl',
  interpolation: {
    escapeValue: false,
  },
  returnNull: false,
});

export default i18next;
