import { Coordinates, GeoCity } from 'src/types/geolocation';
import { capitalizeFirstLetter } from 'lib/strings';

export const mapCityToSlug = (city: string): string => {
  const cityWithoutSpaces = city.trim().split(' ').join('-');

  return encodeURIComponent(cityWithoutSpaces.toLowerCase());
};

export const mapSlugToCity = (slug: string): string => {
  return slug
    .trim()
    .split('-')
    .map((cityPart: string) => capitalizeFirstLetter(decodeURIComponent(cityPart)))
    .join(' ');
};

export const getGeoCityByExactCoordinates = (
  coordinates: Coordinates,
  cities: GeoCity[]
): GeoCity | undefined => {
  return cities.find((city: GeoCity) => {
    return city.latitude === coordinates.latitude && city.longitude === coordinates.longitude;
  }) as GeoCity;
};
