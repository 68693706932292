import { PaymentAction } from '@adyen/adyen-web/dist/types/types';
import { E164Number } from 'libphonenumber-js';
import { Nullable } from 'types/common';
import {
  BareProduct,
  BareVariant,
  CartReminderProduct,
  ExtendedProduct,
  Product,
  ProductAddress,
  ReservationAvailability,
  Variant,
} from 'types/product';
import { BareTaxon, Taxon } from 'types/taxons';
import { AlertType } from './alertType';
import { PaginatedData } from './pagination';
import { TrackingProductList } from './tracking';
import {
  Invoice,
  Order,
  OrderDirection,
  OrderItem,
  OrderItemForContactPage,
  OrderItemUnitShipmentTracking,
  OrderItemUnitShipmentTrackingStatus,
  OrderSummary,
  OrderSummaryCustomer,
  Payment,
  ReservationCallOnlyInfo,
  ReturnableItem,
  ReturnableUnit,
  ReturnedUnit,
  Shipment,
  Voucher,
} from 'types/order';

export type { PaginatedData };

export interface CloseableElement {
  close: () => void;
}

//NB: these keys have this format because it needs to be also set on a cookie in this format
export type CookieSettings = {
  NECESSARY: boolean;
  FUNCTIONAL: boolean;
  ANALYTICS: boolean;
  MARKETING: boolean;
  SOCIAL_MEDIA: boolean;
};

export type AutoCompleteResult = {
  context: string;
  description: string;
  precision: string;
  highlights: any;
  label: string;
  value: string;
};

export interface AddressApiResult {
  address: {
    country: string;
    locality: string;
    street: string;
    postcode: string;
    building: string;
    buildingNumber: number;
    buildingNumberAddition: null | string;
  };
  mailLines: string[];
  location: {
    latitude: number;
    longitude: number;
  };
  country: {
    name: string;
    iso3Code: string;
  };
  details: any;
}

export type Violation = {
  propertyPath: string;
  message: string;
};

export class NotFoundError extends Error {}

export class BackendError extends Error {
  constructor(violations: Violation[]) {
    super();

    this.violations = violations;
  }

  violations!: Violation[];
}

export class InsufficientStockError extends BackendError {
  constructor(violations: Violation[]) {
    super(violations);
  }
}

export class PaymentGatewayUnprocessableError extends Error {
  constructor() {
    super();
  }

  errorCode(): string {
    return 'could_not_start_payment';
  }
}

export class UnsellableItemsInCart extends PaymentGatewayUnprocessableError {
  constructor() {
    super();
  }

  errorCode(): string {
    return 'could_not_start_payment_unsellable_deals';
  }
}

export class PaymentValidationError extends PaymentGatewayUnprocessableError {
  errorMessage = '';

  constructor(errorMessage: string) {
    super();
    this.errorMessage = errorMessage;
  }

  errorCode(): string {
    return this.errorMessage;
  }
}

export interface ProductQuery {
  order?: ProductsOrder;
  direction?: OrderDirection;
  taxonSlug?: string;
  productName?: string;
}

export interface ProductTaxonLocationQuery {
  taxonId?: number;
  latitude: Nullable<number>;
  longitude: Nullable<number>;
}

// @ts-ignore
export interface PaginationQuery<T> extends T {
  page: number;
  itemsPerPage: number;
}

export interface ImageType {
  src: string;
  alt: string;
}

export interface Image {
  src: string;
  alt: string;
  variants: string[];
}

export interface ProductInfo {
  isDisabled: boolean;
  sellable: boolean;
}

export interface HomePageData {
  mainProducts: Product[];
  banners: BannerLocationMap;
  mainDeal: Product | null;
  highlightedProducts: Product[];
  populairProducts: Product[];
  newProducts: Product[];
}

export interface Banner {
  id: string;
  code: string;
  imageSrc: string;
  mobileSrc: string;
  imageAlt: string;
  mainText: string;
  url: string;
}

export type ProductsOrder = 'code' | 'price' | 'createdAt' | 'margin' | 'discount';

export interface AffiliateConfig {
  destinationUrl: string;
}

export interface CartItem {
  /** can be null for optimistic ui */
  id: number | null;
  variant: Variant;
  quantity: number;
  product: Product;
  sellable?: boolean; // undefined for existing carts in localstorage
  reservation: Reservation | null;
  booking: Booking | null;
  originalUnitPrice: number;
  unitPrice: number;
  isWhiteLabelDeal: boolean;
}

export interface Cart {
  /**can be null for loading cart (optimistic ui)*/
  id: number | null;
  /** can be null for loading cart */
  tokenValue: string | null;
  items: CartItem[];
  shippingTotal: number;
  hasFreeShippingPromotion: boolean;
  shippingTotalBeforePromotion: number;
  taxTotal: number;
  total: number;
  orderPromotionTotal: number;
  shippingAddress?: Address | null;
  billingAddress?: Address | null;
  isLoading?: boolean;
  canBeShippedDigitally?: boolean;
  promotionCoupon?: string | null;
  originalTotal?: null | number;
  savingsTotal?: null | number;
  guestEmail?: null | string;
  hasWhiteLabelDeal: boolean;
}

export enum ReservationStates {
  NEW = 'new',
  CONFIRMED = 'confirmed',
  CANCELLED = 'cancelled',
  FAILED = 'failed',
}

export interface CartDraftReservation {
  date: string;
  time: string;
  phone: E164Number | null;
  comments: string;
  quantity: number;
}

export interface CartReservation {
  dateTime: string;
  comments: string;
  phone: E164Number | undefined;
  quantity: number;
}

export interface CartRecommendation {
  id: string;
}

export interface Reservation {
  id: number;
  state: ReservationStates;
  dateTime: Date;
  persons: number;
  phone: string;
  comments: string;
  isAvailable: boolean;
}

export interface Booking {
  id: number;
  externalId: string;
  title: string;
  descriptions: string[];
  isAvailable: boolean;
}

export interface Customer {
  email: string;
  firstName: null | string;
  lastName: null | string;
  fullName?: string | null;
  phoneNumber: string | null;
  birthday: string | null;
  defaultAddress: Address | null;
  addresses: Address[] | null;
  customerId: number;
  subscribedToNewsletter: boolean;
  gigyaUid: string;
  userId: number;
  isGuest: boolean;
}

export interface PersonalDetails {
  email: string;
  firstName: string;
  lastName: string;
}

export interface AddToCartPayload {
  cartId: string;
  item: {
    productVariantCode: string;
    quantity: number;
    reservation?: CartReservation;
    recommendation?: CartRecommendation;
  };
  authToken: string | null;
}

export interface AddBookingToCartPayload {
  cartId: string;
  item: {
    productVariantCode: string;
    externalBookingId: string;
  };
  authToken: string | null;
}

export interface Address {
  id?: number;
  firstName?: string;
  lastName?: string;
  phoneNumber?: null | string;
  company?: null | string;
  countryCode?: string | null;
  street?: null | string;
  city?: null | string;
  postalCode?: string | null;
  houseNumber?: string | number | null;
  houseNumberExtension?: string | null;
  latitude?: number | null;
  longitude?: number | null;
}

export interface PostalAddress {
  postcode: string;
  houseNumber: string;
  city: string;
  latitude: number;
  longitude: number;
}

export interface Page {
  id: number;
  code: string;
  publishAt: string | null;
  enabled: boolean;
  slug: string;
  image?: {
    type: string;
    code: string;
    path: string;
    width: number;
    height: number;
    alt?: string;
  } | null;
  name: string;
  content: string;
  footer: string;
  viewAllProductsLink: string;
  viewAllProductsTitle: string;
  bottomSidebar: boolean | null;
  metaKeywords: string | null;
  metaDescription: string | null;
  nameWhenLinked: string | null;
  descriptionWhenLinked: string | null;
  breadcrumb: string | null;
  title: string | null;
  products: Product[];
}

export interface Account {
  name: string;
  birthday: string | null;
  email: string | null;
  firstName: string;
  fullName: string | null;
  lastName: string;
  phoneNumber: string | null;
}

export interface SessionValue {
  token: string;
}

export interface Session {
  token: string | null;
  session: SessionValue | null;
  status: 'authenticated' | 'loading' | 'unauthenticated';
}

export interface SessionRequired extends Session {
  session: SessionValue;
}

export interface PaymentSession {
  paymentEnvironment: string;
  totalPrice: number;
  id: string;
  sessionData: string;
}

export interface AdyenSessionCheckout {
  id: string;
  sessionData: string;
}

export interface PaymentResponse extends Response {
  action?: PaymentAction;
}

export interface FaqArticle {
  id: number;
  title: string;
  description: string;
}

export interface FaqArticleInCategory {
  id: number;
  title: string;
}

export interface FaqCategory {
  id: number;
  name: string;
  description: string | null;
  articles: FaqArticleInCategory[];
  createdAt: Date;
  updatedAt: Date;
}

export interface Alert {
  type: AlertType;
  message: string;
}

export interface TextBanner {
  code: string;
  id: number;
  destinationUrl: string;
  htmlText: string;
  locations: string[];
  position: number;
}

export interface BannerLocationMap {
  [location: string]: Banner | TextBanner | null;
}

export class Banners {
  private readonly locations: BannerLocationMap;

  constructor(locations: BannerLocationMap) {
    this.locations = locations;
  }

  getBannerForLocation(location: string): Banner | TextBanner | null {
    if (!this.locations) return null;
    return location in this.locations ? this.locations[location] : null;
  }
}

export interface AvddHistory {
  backLink: string;
  shouldShowBackButton: boolean;
}

export interface AddToCartContext {
  showSlider?: boolean;
  product: Product;
  variant: Variant | null;
  quantity: number;
  submitted?: boolean;
  submitting?: boolean;
  productList?: TrackingProductList;
  previousProduct?: Product | null;
}

export interface UserRegistration {
  email: string;
  password?: string | null;
  passwordRepeat?: string | null;
}

export interface AddressValue {
  houseNumber: string;
  street: string;
  houseNumberExtension: string;
  postalCode: string;
  city: string;
  countryCode: CountryCode;
}

export type CountryCode = 'NL' | 'BE';

export interface AddressFormValues {
  firstName: string | undefined;
  lastName: string | undefined;
  address: AddressValue;
}

export interface DigitalAddressFormValues {
  email: string;
  firstName: string;
  lastName: string;
  subscribeToNewsletter: boolean;
  password?: string | null;
  passwordRepeat?: string | null;
}

export interface PhysicalAddressFormValues extends DigitalAddressFormValues {
  shippingAddress: AddressValue;
  billingAddress: AddressValue;
  firstNameBillingAddress: string | undefined;
  lastNameBillingAddress: string | undefined;
  selectBillingAddress: { label: string; value: string };
  selectShippingAddress: { label: string; value: string };
  useShippingAddressAsBillingAddress: boolean;
}

export interface SortingSelection {
  option: string;
  direction: string;
}

export interface PaymentMethodIcon {
  name: string;
  path: string;
}

export interface ImpersonateResponse {
  token: string;
}

export interface CredentialsProviderInput {
  token?: string;
  email?: string;
  password?: string;
}

export interface BrowserHistoryItem {
  path: string;
  shouldRedirect: boolean;
}

export enum OrderPaymentState {
  cart = 'cart',
  awaitingPayment = 'awaiting_payment',
  partiallyAuthorized = 'partially_authorized',
  authorized = 'authorized',
  partiallyPaid = 'partially_paid',
  cancelled = 'cancelled',
  paid = 'paid',
  partiallyRefunded = 'partially_refunded',
  refunded = 'refunded',
}

export enum ReturnStatus {
  failed = 'failed',
  requested = 'requested',
  received = 'received',
  awaitingRefund = 'awaiting_refund',
  refunded = 'refunded',
}

export enum VoucherStatus {
  VALID = 'VALID',
  BLOCKED = 'BLOCKED',
  RESERVED = 'RESERVED',
  CANCELLED = 'CANCELLED',
  GRACE_PERIOD = 'GRACE_PERIOD',
  EXPIRED = 'EXPIRED',
  USED = 'USED',
}

export enum PaymentState {
  processing = 'processing',
  authorized = 'authorized',
  completed = 'completed',
  failed = 'failed',
  cancelled = 'cancelled',
  refunded = 'refunded',
  new = 'new',
}

export type {
  BareProduct,
  BareVariant,
  CartReminderProduct,
  ExtendedProduct,
  BareTaxon,
  Product,
  ProductAddress,
  ReservationAvailability,
  Taxon,
  Variant,
  Order,
  OrderItem,
  OrderItemForContactPage,
  OrderItemUnitShipmentTracking,
  OrderSummary,
  OrderItemUnitShipmentTrackingStatus,
  OrderSummaryCustomer,
  OrderDirection,
  Shipment,
  Invoice,
  ReturnableItem,
  ReturnableUnit,
  ReservationCallOnlyInfo,
  ReturnedUnit,
  Voucher,
  Payment,
};
