import { BareTaxon, Product } from 'types/types';
import { doFetch, resolveApiUrl } from 'lib/api';
import { transformProductJson } from 'lib/transforms';
import { captureError, captureTypeErrorIfInvalid } from 'lib/error';
import API_ENDPOINTS from 'constants/routes/api';
import { ALL_DEALS_TAXON_ID } from 'constants/taxon';

type Props = {
  taxon: BareTaxon | null;
};

export async function fetchMostSoldProducts({ taxon }: Props): Promise<Product[]> {
  const endpoint = resolveApiUrl(
    API_ENDPOINTS.GetMostSoldProductsByTaxon(taxon?.id ?? ALL_DEALS_TAXON_ID)
  );

  const res = await doFetch(endpoint, {});

  if (!res.ok) {
    captureError(
      new Error(
        `Endpoint ${endpoint} responded with an unknown response, responseCode ${res.status}`
      )
    );

    return [];
  }

  const productsJson = await res.json();

  const productArray = productsJson
    ? productsJson
        .filter((p: any) => p !== null)
        .map(transformProductJson)
        .filter(Boolean)
    : null;

  productArray?.forEach((r: Product) => captureTypeErrorIfInvalid('Product', r));

  return productArray;
}
