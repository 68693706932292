import AbstractTag from '../AbstractTag';
import { TrackingProduct } from 'types/tracking';

export default class ProductClickTag extends AbstractTag {
  constructor() {
    super();

    this.addProp(AbstractTag.EVENT, 'product_click');
  }

  set clickedProduct(value: TrackingProduct) {
    this.addProp('product', [value]);
  }
}
