export enum EMAIL_CHECK_STATUS {
  init = 'init',
  error = 'error',
  exists = 'exists',
  notFound = 'notFound',
}

export enum CONTENT_TYPES {
  APPLICATION_JSON = 'application/json',
}

export enum ALLOWED_JAVASCRIPT_URLS_PARTS {
  checkin = 'checkin',
}

export enum VOUCHER_LINK_TYPES {
  EXTERNAL_DOWNLOADABLE = 'EXTERNAL_DOWNLOADABLE',
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export enum TRACKING_CHECKOUT_STEP {
  cartOverview = '10',
  address = '20',
  payment = '30',
  thankYou = '40',
}

export enum QUANTITY_LABEL {
  PRODUCT = 'PRODUCT',
  PERSON = 'PERSON',
  VOUCHER = 'VOUCHER',
  SET = 'SET',
}
