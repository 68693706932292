import { isEmail, validateEmail } from './email';
import {
  containsNumbers,
  isEmpty,
  isEqualOrLongerThenMinimumLength,
  isNumberBiggerThanMinimumValue,
  validateAlphaNumericString,
  validateAlphaString,
  validatePassword,
  validatePasswords,
  isValidCoordinateInput,
  validateCoordinateInput,
} from 'lib/validation/string';
import {
  isFullAddress,
  isTheSameAddress,
  isValidAddressZipcode,
  isValidZipcode,
  validateAddressFormValues,
} from 'lib/validation/address';
import { isValidDate } from 'lib/validation/date';
import { isValidPhone } from 'lib/validation/phone';
import { isValidCoordinates } from 'lib/validation/location';

const validation = {
  validateEmail,
  validatePasswords,
  validatePassword,
  validateAlphaNumericString,
  validateAlphaString,
  isEmpty,
  isEmail,
  isEqualOrLongerThenMinimumLength,
  isFullAddress,
  isTheSameAddress,
  isValidZipcode,
  isValidAddressZipcode,
  validateAddressFormValues,
  containsNumbers,
  isNumberBiggerThanMinimumValue,
  isValidPhone,
  isValidDate,
  validateCoordinateInput,
  isValidCoordinateInput,
  isValidCoordinates,
};

export default validation;
