import { doFetch, resolveApiUrl } from 'lib/api';
import API_ENDPOINTS from 'constants/routes/api';
import { GeoCity } from 'types/geolocation';
import { transformProductCitiesJson } from 'lib/transforms/location';

export async function fetchProductCities(): Promise<GeoCity[]> {
  const url = resolveApiUrl(API_ENDPOINTS.GetProductCities);
  const res = await doFetch(url);

  if (!res.ok) {
    return [];
  }

  const citiesJson = await res.json();

  return transformProductCitiesJson(citiesJson);
}

export default fetchProductCities;
