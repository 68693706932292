export const MAX_PRODUCT_QUANTITY_PER_CUSTOMER = 31;

export const PRODUCT_MINIMUM_CART_QUANTITY_DEFAULT = 1;

export const HOMEPAGE_NUM_LOCATION_BASED_PRODUCTS = 6;

export const STOCK_ALERT_THRESHOLD = 10;

export enum RecommendedProductLocation {
  CART = 'cart',
  SLIDER = 'slider',
  PRODUCT = 'product_page',
  CHANNEL = 'channel',
}

export const QuantityPickerErrorCauses = {
  MAX_REACHED: 'max reached' as const,
  MIN_REACHED: 'min reached' as const,
  ZERO_REACHED: 'zero reached' as const,
  DISABLED: 'disabled' as const,
};

export type QuantityPickerError =
  (typeof QuantityPickerErrorCauses)[keyof typeof QuantityPickerErrorCauses];

export const SELLABLE_PRODUCTS_QUERY_IDENTIFIER = 'ids[]';

export const LAST_SEEN_PRODUCTS_STORAGE_NAME = 'lastSeenProducts';
export const LAST_SEEN_PRODUCTS_STORAGE_LIST_NAME = 'products' as const;
export const LAST_SEEN_PRODUCTS_ITEM_ID = 'id';
export const LAST_SEEN_PRODUCTS_ITEM_SET_AT = 'setAt';
export const LAST_SEEN_PRODUCTS_MAX_LENGTH = 20;
export const LAST_SEEN_DESKTOP_CHUNK_SIZE_DEFAULT = 3;
export const MOST_SOLD_DESKTOP_CHUNK_SIZE_DEFAULT = 4;
export const MOST_SOLD_PRODUCTS_MIN_LENGTH = 6;
export const MOST_SOLD_PRODUCTS_MAX_LENGTH = 12;
