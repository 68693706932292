import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import validation from 'lib/validation';
import handleSubscribeToNewsletter from 'lib/handleSubscribeToNewsletter';
import ActivityIndicator from 'components/ActivityIndicator';
import { useTranslation } from 'react-i18next';
import { XIcon } from '@heroicons/react/outline';
import useStore from 'store';
import { Z_INDEX } from 'constants/common';
import { LOCATION_POPUP } from 'constants/newsletterSubscriptionLocation';

const NewsletterDialog = ({
  dialogOpen,
  setDialogOpen,
}: {
  dialogOpen: boolean;
  setDialogOpen: (value: boolean) => void;
}) => {
  const { t } = useTranslation();

  const [, setHasShownNewsletterPopup] = useStore('hasShownNewsletterPopup');
  const [email, setEmail] = useState('');
  const [invalid, setInvalid] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const submit = (e: any) => {
    e?.preventDefault();
    if (success) return;

    if (validation.isEmail(email)) {
      setLoading(true);

      handleSubscribeToNewsletter(email, LOCATION_POPUP, LOCATION_POPUP).then((response) => {
        setLoading(false);
        setSuccess(response);
        setHasShownNewsletterPopup(true);
      });
    } else {
      setInvalid(true);
    }
  };

  const onClose = () => {
    setDialogOpen(false);
    setHasShownNewsletterPopup(true);
  };

  return (
    <Transition.Root show={dialogOpen} as={Fragment}>
      <Dialog
        as="div"
        className={`fixed inset-0 ${Z_INDEX.level45} overflow-y-auto`}
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
              <div className="absolute top-0 right-0 pt-4 pr-4 block">
                <button
                  type="button"
                  className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={onClose}
                >
                  <span className="sr-only">{t('app.ui.close')}</span>
                  <XIcon className="w-6 h-6" aria-hidden="true" />
                </button>
              </div>
              <div className="bg-white">
                <div className="px-4 py-12 mx-auto max-w-12xl sm:px-6 lg:py-16 lg:px-8">
                  <h2 className="inline text-2xl font-extrabold tracking-tight text-gray-900 sm:block">
                    {t('app.ui.newsletter.title')}
                  </h2>
                  <p className="inline text-xl font-extrabold tracking-tight text-indigo-600 sm:block">
                    {' '}
                    {t('app.ui.newsletter.subtitle')}
                  </p>
                  {invalid && (
                    <p className="text-red-800">{t('app.ui.newsletter.invalid_email')}</p>
                  )}
                  {success && <p className="text-green-800">{t('app.ui.newsletter.success')}</p>}
                  <form className="mt-8 sm:flex" onSubmit={submit}>
                    <label htmlFor="email-address" className="sr-only">
                      {t('app.ui.newsletter.email')}
                    </label>
                    <input
                      type="email"
                      name="email"
                      required
                      className="w-full px-5 py-3 placeholder-gray-500 border-2 border-indigo-500 rounded-md ring-indigo-500 sm:max-w-xs"
                      placeholder={t('app.ui.newsletter.email')}
                      value={email}
                      onChange={(e) => {
                        if (invalid) setInvalid(!validation.isEmail(e.target.value));
                        setEmail(e.target.value);
                      }}
                    />
                    <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                      <button
                        type="submit"
                        className="flex items-center justify-center w-full px-5 py-3 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={submit}
                        disabled={success}
                      >
                        {loading ? <ActivityIndicator className="ml-4" color="white" /> : null}
                        {t('app.ui.newsletter.subscribe')}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default NewsletterDialog;
