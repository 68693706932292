import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { FC, PropsWithChildren, useEffect } from 'react';
import { setPreserve } from 'redux/products/products.slice';
import { ProductRoutes } from 'constants/routes/product';

export const PreserveInfiniteScrollProvider: FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    // handle back button change
    router.beforePopState(({ as }) => {
      if (
        (as.includes(ProductRoutes.listing) || as.includes(ProductRoutes.search)) &&
        !!router.query.productSlug
      ) {
        dispatch(setPreserve(true));
      }

      return true;
    });
  }, [router, dispatch]);

  return <>{children}</>;
};
