export const PLACEHOLDER_TRANS_TEXT = 'text to be replaced by translation';

const env = process.env.NODE_ENV;
export const IS_DEV = env === 'development';

export enum Z_INDEX {
  levelAboveMuiDrawer = 'z-1210',
  levelBelowCookieBar = 'z-1205',
  level50 = 'z-50',
  level45 = 'z-45',
  level40 = 'z-40',
  level35 = 'z-35',
  level30 = 'z-30',
  level20 = 'z-20',
  level10 = 'z-10',
  level5 = 'z-5',
  reset = 'z-0',
}

export const DEFAULT_DEBOUNCE_TIME = 500; // in ms

export const DEFAULT_ADYEN_API_VERSION = 'v71';

export enum Loading {
  IDLE = 'idle',
  PENDING = 'pending',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export const DEFAULT_TOAST_DURATION = 7000; // in ms

export type ErrorType = 'error';

export const APP_DEEPLINK_SCHEME = 'avdd';

export const CART_REMINDER_STORAGE_NAME = 'lastCartItemsCounter';
export const CART_REMINDER_STORAGE_KEY_COUNTER = 'counter';
export const CART_REMINDER_STORAGE_KEY_LAST_SET = 'lastSetDateTime';
export const CART_REMINDER_MAX_VIEW_COUNT = 3;
