import { Booking, Cart, CartItem, Reservation } from 'types/types';
import { transformProductJson } from 'lib/transforms/product';
import { backendAddressToAddress } from 'lib/transforms/address';
import { getPublicRuntimeConfig } from 'lib/api';

export const transformReservationJson = (reservation: any): Reservation => {
  return {
    id: reservation.id,
    state: reservation.state,
    dateTime: reservation.dateTime,
    persons: reservation.persons,
    isAvailable: reservation.isAvailable,
    phone: reservation.phone,
    comments: reservation.comments,
  };
};

export const transformBookingJson = (booking: any): Booking => {
  return {
    id: booking.id,
    externalId: booking.externalId,
    title: booking.title,
    descriptions: booking.descriptions,
    isAvailable: booking.isAvailable,
  };
};

export function transformCartJson(result: any): Cart {
  return {
    id: result.id,
    tokenValue: result.tokenValue,
    hasFreeShippingPromotion: result.hasFreeShippingPromotion || false,
    shippingTotal: result.shippingTotal || 0,
    shippingTotalBeforePromotion: result.shippingTotalBeforePromotion || 0,
    taxTotal: result.taxTotal,
    orderPromotionTotal: result.orderPromotionTotal,
    total: result.total,
    items: result.items.map((item: any): CartItem => {
      return {
        id: item.id,
        variant: {
          id: item.variant.id,
          sku: item.variant.sku,
          code: item.variant.code,
          src: getPublicRuntimeConfig().imageUrl + '/media/image/' + item.variant.image,
          name: item.variant.name,
          originalPrice: item.variant.originalPrice,
          price: item.variant.price,
          enabled: item.variant.enabled,
          isPhysical: item.variant.isPhysical === true,
          isReservationDeal: item.variant.isReservationDeal || false,
          isMergedVouchersDeal: item.variant.isMergedVouchersDeal || false,
          isCallOnlyDeal: item.variant.isCallOnlyDeal || false,
          quantityLabel: item.variant.quantityLabel,
          hasLowStock: false,
        },
        isWhiteLabelDeal: item.isPromotionalItem || false,
        quantity: item.quantity,
        product: transformProductJson(item.product),
        sellable: item.productSellable,
        reservation: item.reservation ? transformReservationJson(item.reservation) : null,
        booking: item.booking ? transformBookingJson(item.booking) : null,
        unitPrice: item.unitPrice,
        originalUnitPrice: item.originalUnitPrice,
      };
    }),
    billingAddress: backendAddressToAddress(result.billingAddress),
    shippingAddress: backendAddressToAddress(result.shippingAddress),
    canBeShippedDigitally:
      typeof result.canBeShippedDigitally !== 'undefined' ? result.canBeShippedDigitally : false,
    promotionCoupon: result.promotionCoupon,
    savingsTotal: result.savingsTotal,
    originalTotal: result.originalTotal,
    hasWhiteLabelDeal: result.containsCouponOnlyPurchasableDeals ?? false,
  };
}
