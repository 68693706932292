import useStore from '../store';
import { useEffect } from 'react';
import { tracker } from '../modules/tracking';
import { useCookies } from 'react-cookie';

const TrackingCustomerProvider = () => {
  const [customer] = useStore('customer');
  const [cookies] = useCookies(['GdprConsentByUser']);

  useEffect(() => {
    tracker.customer = customer;
    if (cookies?.GdprConsentByUser) {
      tracker.hasAcceptedMarketingCookies = cookies.GdprConsentByUser.purposes.MARKETING;
    }
  }, [customer, cookies]);

  return null;
};

export default TrackingCustomerProvider;
