import AbstractTag from './AbstractTag';

export default class SubTaxonClickTag extends AbstractTag {
  constructor() {
    super();

    this.addProp(AbstractTag.EVENT, 'category_filter_click');
    this.addProp(AbstractTag.EVENT_NAME, 'category_filter_click');
  }

  set filterCategory(value: string) {
    this.addProp('filter_category', value);
  }

  set filterLabel(value: string) {
    this.addProp('filter_label', value);
  }
}
