import { createSelector, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { GEOFILTER_TYPE, initialClientCoordinates } from 'constants/geolocation';
import { Coordinates, GeoFilter } from 'types/geolocation';
import { RootState } from 'redux/appStore';
import isEqual from 'lodash.isequal';
import areCoordinatesApproximatelyEqual from 'lib/geolocation/areCoordinatesApproximatelyEqual';
import { LocationPermissionStates } from 'types/locationPermissionStates';
import { getDefaultLocationFilter } from 'src/lib/geolocation/mapToGeoFilter';
import { APP_HYDRATE } from 'redux/actions';

export interface GeoLocationState {
  client: Coordinates;
  filter: GeoFilter;
  locationPermissionState: LocationPermissionStates;
}

const initialState: GeoLocationState = {
  client: initialClientCoordinates,
  filter: getDefaultLocationFilter(),
  locationPermissionState: LocationPermissionStates.notInitiated,
};

const shouldUpdateLocation = (existing: Coordinates, payload: Coordinates): boolean =>
  !areCoordinatesApproximatelyEqual(existing, payload);

export const geolocationSlice = createSlice({
  name: 'geolocation',
  initialState,
  reducers: {
    setClientLocation: (state, { payload }: PayloadAction<Coordinates>) => {
      if (!shouldUpdateLocation(state.client, payload)) {
        return;
      }

      state.client = payload;
    },
    setLocationPermission: (state, { payload }: PayloadAction<LocationPermissionStates>) => {
      state.locationPermissionState = payload;
    },
    setGeoFilter: (state, { payload }: PayloadAction<GeoFilter>) => {
      if (isEqual(state.filter, payload)) {
        return;
      }

      state.filter = payload;
    },
    resetFilter: (state) => {
      state.filter = getDefaultLocationFilter();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(APP_HYDRATE, (clientState, { payload }) => {
      if (payload.geolocation.filter.city === null) return;
      return {
        ...clientState,
        ...payload.geolocation,
      };
    });
  },
});

// Action creators are generated for each case reducer function
export const { setClientLocation, setLocationPermission, setGeoFilter } = geolocationSlice.actions;

// Selectors
export const selectGeolocationSlice = (state: RootState) => state.geolocation;
export const getClientLocation = createSelector(selectGeolocationSlice, (s) => s.client);

export const getHasUserPermissionPrompt = (state: RootState): boolean =>
  state.geolocation.locationPermissionState === LocationPermissionStates.prompt;

export const getIsNotInitiated = (state: RootState): boolean =>
  state.geolocation.locationPermissionState === LocationPermissionStates.notInitiated;

export const getNeedsUserRequest = (state: RootState): boolean =>
  state.geolocation.locationPermissionState === LocationPermissionStates.needsUserRequest;

export const getHasUserDeniedPermission = (state: RootState): boolean =>
  state.geolocation.locationPermissionState === LocationPermissionStates.denied;

export const getHasUserGrantedPermission = (state: RootState): boolean =>
  state.geolocation.locationPermissionState === LocationPermissionStates.granted;

export const selectGeoFilter = createSelector(selectGeolocationSlice, (s) => s.filter);
export const getIsGeoFilterTypeCity = createSelector(
  selectGeoFilter,
  (filter) => (filter && filter.type === GEOFILTER_TYPE.CITY) || false
);
export const getGeoFilterQuery = createSelector(selectGeoFilter, (s) => s.query);
export const getGeoFilterLabel = createSelector(selectGeoFilter, (s) => s.label);
export const getIsAutocompleteEnabled = createSelector(
  selectGeoFilter,
  (filter) => filter.type === GEOFILTER_TYPE.AUTOCOMPLETE
);
export const getIsGeoFilterTypeGps = createSelector(
  selectGeoFilter,
  (filter) => filter.type === GEOFILTER_TYPE.GPS
);

export default geolocationSlice.reducer;
