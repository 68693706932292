import { ReservationAvailability } from 'types/product';
import api from 'constants/routes/api';
import { doFetch, resolveApiUrl } from 'lib/api';
import { transformReservationAvailability } from 'lib/transforms/product';

export interface FetchAvailabilityTimeslotsForDateQuery {
  date: string;
  variantId: number;
  quantity: number;
}

export async function fetchAvailabilityTimeslotsForDate({
  date,
  variantId,
  quantity,
}: FetchAvailabilityTimeslotsForDateQuery): Promise<ReservationAvailability> {
  const endpoint = resolveApiUrl(api.GetReservationTimeSlotsForDate(variantId, date));
  const res = await doFetch(endpoint, {}, { persons: quantity });

  if (!res.ok) {
    console.warn('Failed to fetch reservation timeslot availabilities');
    return { date, timeslots: [] };
  }

  const reservations = await res.json();

  return transformReservationAvailability(reservations);
}

export default fetchAvailabilityTimeslotsForDate;
