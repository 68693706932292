import AbstractTag from '../AbstractTag';
import { TrackingProduct } from 'types/tracking';

export default class ProductImpressionTag extends AbstractTag {
  constructor() {
    super();

    this.addProp(AbstractTag.EVENT, 'product_impression');
  }

  set products(value: Array<TrackingProduct>) {
    this.addProp('product_impression', value);
  }
}
