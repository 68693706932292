import { BareVariant, Variant } from 'types/product';

export const castToVariantOrNull = (variant: Variant | BareVariant | null): Variant | null => {
  if (!variant) return null;

  // a key which is present in interface Variant but not in interface BareVariant
  const keyInVariantInterface = 'id';

  return keyInVariantInterface in variant ? variant : null;
};
