import { useEffect, useState } from 'react';

type NoSSRProps = {
  children: any;
};

export default function NoSSR({ children }: NoSSRProps) {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return isClient ? children && children : null;
}
