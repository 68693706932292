import { PhoneNumber } from 'react-phone-number-input';
import { parsePhoneNumber } from 'libphonenumber-js';

export const parsePhoneValue = (value: string | null): null | PhoneNumber => {
  if (!value) return null;
  try {
    return parsePhoneNumber(value) || null;
  } catch (e) {
    console.warn('Could not parse phone number', e);
    return null;
  }
};
