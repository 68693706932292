import { trackNewsletterSubscribe } from 'modules/tracking/events/events';
import staticContentApi from 'api/static-content';
import { Coordinates } from 'types/geolocation';

export async function handleSubscribeToNewsletter(
  email: string,
  location: string,
  trackingLocation: string,
  coordinates?: Coordinates
): Promise<boolean> {
  await trackNewsletterSubscribe(trackingLocation);
  return staticContentApi.subscribeToNewsletter(email, location, coordinates);
}

export default handleSubscribeToNewsletter;
