import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import { ArrowRightIcon } from '@heroicons/react/solid';
import {
  APP_PROMO_BANNER_ID,
  APP_PROMO_TRACKING_CODES,
  APP_STORE_CAMPAIGNS,
  AppStoreKeys,
} from 'constants/appStores';
import { trackBannerClick } from 'modules/tracking/events/events';
import { AppPromoUsps } from 'components/appPromoBanner/AppPromoUsps';
import { Banner } from 'types/types';
import { useMemo } from 'react';
import { AppPromoStoreLink } from 'components/appPromoBanner/AppPromoStoreLink';
import { getAppStoreByKey } from 'lib/appPromo/appPromo';

export function AppPromoDesktop() {
  const { t } = useTranslation();

  const appStore = useMemo(() => {
    return getAppStoreByKey({
      appStoreKey: AppStoreKeys.desktop,
      campaign: APP_STORE_CAMPAIGNS.popOver,
    });
  }, []);

  const handleClick = () => {
    void trackBannerClick({
      banner: {
        id: APP_PROMO_BANNER_ID,
        code: APP_PROMO_TRACKING_CODES.desktopButton,
      } as Banner,
      width: '120',
      height: '40',
      position: 3,
    });
  };

  return (
    <>
      <h2 className={'text-primary text-center text-2xl mb-4'}>{t('app.app_promo.title')}</h2>
      <div className="flex flex-row items-start justify-start">
        <div className="mr-4">
          <Image src={'/logo-turned-9-degrees.svg'} width={100} height={100} alt="Logo" />
        </div>
        <div className="flex-grow mt-2.5">
          <AppPromoUsps />
        </div>
      </div>
      <div className="flex flex-row items-center justify-between w-full mt-5">
        <div className="flex-1 flex justify-start items-center">
          <AppPromoStoreLink
            appStore={getAppStoreByKey({
              appStoreKey: AppStoreKeys.appleStore,
              campaign: APP_STORE_CAMPAIGNS.popOver,
            })}
            trackingPosition={1}
            width={155}
            height={160}
            shouldOpenInNewTab={true}
          />
        </div>
        <div className="flex-1 flex justify-center items-center">
          <AppPromoStoreLink
            appStore={getAppStoreByKey({
              appStoreKey: AppStoreKeys.googlePlay,
              campaign: APP_STORE_CAMPAIGNS.popOver,
            })}
            trackingPosition={2}
            width={200}
            height={100}
            shouldOpenInNewTab={true}
          />
        </div>
        <div className="flex-1 flex justify-end items-center">
          <a
            href={appStore.url}
            className={'flex text-purple hover:text-purple whitespace-nowrap pt-0.5 outline-none'}
            onClick={handleClick}
            target={'_blank'}
            rel={'noopener noreferrer'}
          >
            {t(appStore.description)}
            <ArrowRightIcon className="ml-1 h-5 w-5" aria-hidden="true" />
          </a>
        </div>
      </div>
    </>
  );
}
