import {
  Order,
  OrderItem,
  OrderItemForContactPage,
  OrderItemUnitShipmentTracking,
  OrderSummary,
  Payment,
} from 'types/types';
import {
  transformBookingJson,
  transformProductJson,
  transformReservationCallOnlyInfoJson,
  transformReservationJson,
} from 'lib/transforms/index';
import { backendAddressToAddress } from 'lib/transforms/address';
import { getPublicRuntimeConfig } from 'lib/api';
import { VoucherAction } from 'types/order';

export const mapVoucherActions = (json: any): VoucherAction[] => {
  if (!json?.length) return [];
  return json.map(({ text, type, target, expiresAt, ...rest }: any) => ({
    label: text,
    actionType: type,
    action: target,
    date: expiresAt,
    ...rest,
  }));
};

export const mapOrderSummaryResponseToOrderSummary = (result: any): OrderSummary => {
  return {
    id: result.id,
    items: result.items.map((item: any): OrderItem => transformOrderItemJson(item)),
    payments: result.hasOwnProperty('payments')
      ? result.payments.map((item: any) => {
          return {
            pspMethod: item.pspMethod,
          };
        })
      : [],
    total: result.total,
    shippingTotal: result.shippingTotal,
    tokenValue: result.tokenValue,
    customer: {
      email: result.customer?.email,
      isGuest: result.customer?.isGuest,
      isEmailAlreadyRegistered: result.customer?.isEmailAlreadyRegistered,
    },
    checkoutCompleted: new Date(result.checkoutCompletedAt),
    isAppCreated: Boolean(result.isMobileCreated),
    hasWhiteLabelDeal: result.containsCouponOnlyPurchasableDeals ?? false,
  };
};

export const transformOrdersJson = (json: any): Order[] => {
  if (!json?.length) return [];
  return json.map(transformOrderJson);
};

export const transformOrderJson = (result: any): Order => {
  return {
    invoice: result.invoice,
    shippingState: result.shippingState,
    paymentState: result.paymentState,
    number: result.number,
    id: result.id,
    billingAddress: backendAddressToAddress(result.billingAddress),
    orderPromotionTotal: result.orderPromotionTotal,
    shippingAddress: backendAddressToAddress(result.shippingAddress),
    shippingTotal: result.shippingTotal,
    hasFreeShippingPromotion: result.hasFreeShippingPromotion || false,
    shippingTotalBeforePromotion: result.shippingTotalBeforePromotion,
    taxTotal: result.taxTotal,
    tokenValue: result.tokenValue,
    total: result.total,
    // Backwards compatability: AVDD-6635. Shipments from API can come back as null
    shipments: Array.isArray(result.shipments)
      ? result.shipments.map((shipment: any) => {
          return {
            item: shipment.orderItem,
            id: shipment.id,
            status: shipment.status,
            // Backwards compatability: AVDD-6635
            method: typeof shipment.method === 'string' ? shipment.method : shipment.method.name,
            shippedAt: shipment.shippedAt ? new Date(shipment.shippedAt) : null,
          };
        })
      : [],
    checkoutCompletedAt: new Date(result.checkoutCompletedAt),
    isReadyForPayment: result.isReadyForPayment,
    hasProcessingPayments: result.hasProcessingPayments,
    payments: result.hasOwnProperty('payments')
      ? result.payments.map((item: any): Payment => {
          return {
            method: {
              name: item.method.name,
            },
            pspBankId: item.pspBankId,
            pspMethod: item.pspMethod,
            id: item.id,
            status: item.state,
            createdAt: new Date(item.createdAt),
            amount: item.amount,
          };
        })
      : [],
    items: result.items?.map(transformOrderItemJson) || [],
    originalTotal: result.originalTotal,
    savingsTotal: result.savingsTotal,
    hasWhiteLabelDeal: result.containsCouponOnlyPurchasableDeals,
  };
};

export const transformOrderItemJson = (item: any): OrderItem => {
  return {
    id: item.id,
    variant: {
      id: item.variant.id,
      sku: item.variant.sku,
      code: item.variant.code,
      src: getPublicRuntimeConfig().imageUrl + '/media/image/' + item.variant.image,
      name: item.variant.name,
      originalPrice: item.variant.originalPrice,
      price: item.variant.price,
      enabled: item.variant.enabled,
      isPhysical: item.variant.isPhysical === true,
      isCallOnlyDeal: item.variant.isCallOnlyDeal || false,
      quantityLabel: item.variant.quantityLabel,
      hasLowStock: false,
    },
    isWhiteLabelDeal: item.isPromotionalItem || false,
    quantity: item.quantity,
    vouchers: item.vouchers.map((voucherItem: any) => {
      return {
        id: voucherItem.id,
        redeemable: voucherItem.redeemable,
        status: voucherItem.status,
        token: voucherItem.token,
        linkType: voucherItem.linkType,
        redeemUrl: voucherItem.redeemUrl,
        downloadUrl: voucherItem.downloadUrl,
        expiresAt: voucherItem.expiresAt ? new Date(voucherItem.expiresAt) : null,
        relatedVouchersCount: voucherItem.relatedVouchersCount,
      };
    }),
    voucherActions: mapVoucherActions(item.voucherActions),
    returnableUnits: item.returnableUnits.map(({ id }: any) => ({ id })),
    product: transformProductJson(item.product),
    productSellable: item.productSellable,
    isReturnRequestAllowed: item.isReturnRequestAllowed,
    units: item.units,
    unitPrice: item.unitPrice,
    originalUnitPrice: item.originalUnitPrice,
    reservation: item.reservation ? transformReservationJson(item.reservation) : null,
    booking: item.booking ? transformBookingJson(item.booking) : null,
    reservationCallOnlyInfo: item.reservationCallOnlyInfo
      ? transformReservationCallOnlyInfoJson(item.reservationCallOnlyInfo)
      : null,
  };
};

export const transformOrderItemForContactPageJson = (item: any): OrderItemForContactPage => {
  return {
    id: item.id,
    variant: {
      name: item.variant.name,
    },
  };
};

export const transformOrderItemUnitShipmentTrackingJson = (
  result: any
): OrderItemUnitShipmentTracking => {
  return {
    code: result.code,
    url: result.url,
    units: result.units,
  };
};
