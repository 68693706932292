import { isDevelopment } from 'lib/react-with-native-env/isDevelopment';

export function getNewRelicInstance() {
  if (isDevelopment()) return undefined; // only use in prod
  if (typeof window === 'undefined') return require('newrelic');
  else if (window?.hasOwnProperty('newrelic')) return (window as any).newrelic;
  return undefined;
}

export function captureError(err: Error, data?: Object) {
  const newRelic = getNewRelicInstance();
  if (newRelic) newRelic.noticeError(err, data);
}
