import { createApi } from '@reduxjs/toolkit/query/react';
import { Order } from 'types/types';
import gatewayBaseQuery from 'lib/gateway/gatewayBaseQuery';
import { apiGatewayEndpoints } from 'lib/gateway/endpoints';
import { isHydrateAction } from 'redux/query/common.query';
import { transformOrderJson, transformOrdersJson } from 'lib/transforms';

const orderApi = createApi({
  reducerPath: 'orderApi',
  baseQuery: gatewayBaseQuery(),
  extractRehydrationInfo(action, { reducerPath }): any {
    if (isHydrateAction(action)) return action.payload[reducerPath];
  },
  endpoints: (builder) => ({
    fetchOrders: builder.query<Order[], string | void>({
      query: (token) => ({
        url: apiGatewayEndpoints.GetOrders(),
        headers: token ? { Authorization: `Bearer ${token}` } : undefined,
      }),
      transformResponse: transformOrdersJson,
    }),
    fetchOrder: builder.query<Order, string>({
      query: (tokenValue) => apiGatewayEndpoints.GetOrder(tokenValue),
      transformResponse: transformOrderJson,
    }),
  }),
});

export const { useFetchOrdersQuery, useFetchOrderQuery } = orderApi;

export default orderApi;
