import AbstractTag from './AbstractTag';
import { tracker } from 'modules/tracking';

export interface GrowthBookTagData {
  experimentId: string;
  variationId: string;
  featureId: string | null;
}

export default class GrowthBookTag extends AbstractTag {
  constructor({ experimentId, variationId, featureId }: GrowthBookTagData) {
    super();

    this.addProp(AbstractTag.EVENT, 'growthbook');
    this.addProp('feature_id', featureId);
    this.addProp('experiment_id', experimentId);
    this.addProp('variation_id', variationId);
  }
}

export const trackGrowthBook = (data: GrowthBookTagData): void => {
  void tracker.publish(new GrowthBookTag(data));
};
