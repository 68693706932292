import Tracker from './tracker';
import { isServer } from 'lib/env';
import TealiumPublisher from './publishers/TealiumPublisher';

export const tracker = new Tracker();

let tealiumPublisher = null;

if (!isServer()) {
  tealiumPublisher = new TealiumPublisher();
  tracker.addPublisher(tealiumPublisher);
}
