import { ReactNode } from 'react';
import ActivityIndicator from './ActivityIndicator';
import { CssStyleProps } from 'types/styles';

interface Props extends CssStyleProps {
  onClick?: (e: any) => void;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  available?: boolean;
  hoverEnabled?: boolean;
  children: ReactNode;
}

export const SubmitButton = ({
  onClick,
  disabled,
  loading,
  className,
  available = true,
  hoverEnabled = true,
  widthClass = 'w-full',
  fontClass = 'font-medium',
  textSizeClass = 'text-sm',
  textColorClass = 'text-white',
  paddingClass = 'px-4 py-2',
  bgColorClass,
  children,
}: Props) => {
  let hover = '';
  if (hoverEnabled) hover = available ? 'hover:bg-orange-darker' : 'hover:bg-gray-400';

  const bgClass = available && !bgColorClass ? `bg-orange ${hover}` : `bg-gray-300 ${hover}`;

  return (
    <button
      disabled={disabled}
      type="submit"
      className={`inline-flex justify-center ${widthClass} ${textSizeClass} ${fontClass} ${textColorClass} ${paddingClass} ${
        bgColorClass || bgClass
      } border border-transparent rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${className}`}
      onClick={onClick}
      data-testid={'submitButton'}
    >
      {children}
      {loading && (
        <div className="pl-2">
          <ActivityIndicator />
        </div>
      )}
    </button>
  );
};

export default SubmitButton;
