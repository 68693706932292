import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { AxiosError, AxiosRequestConfig } from 'axios';
import gateway from 'lib/gateway/axios';
import { APIGatewayEndpoint } from 'lib/gateway/endpoints';

interface FetchBaseQueryFnArgs {
  url: APIGatewayEndpoint;
  method?: AxiosRequestConfig['method'];
  data?: AxiosRequestConfig['data'];
  params?: AxiosRequestConfig['params'];
  headers?: AxiosRequestConfig['headers'];
}

const gatewayBaseQuery =
  (): BaseQueryFn<FetchBaseQueryFnArgs | string> =>
  async (arg?: FetchBaseQueryFnArgs | string | void) => {
    try {
      const config =
        typeof arg === 'string' ? { url: arg } : { ...arg, url: (arg?.url as string) || '' };
      const result = await gateway(config);
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export default gatewayBaseQuery;
