import { doFetch, resolveApiUrl } from 'lib/api';
import { BannerLocationMap } from 'types/types';
import { transformBanners } from 'lib/transforms';
import API_ENDPOINTS from 'constants/routes/api';

export default async function fetchBannersForTaxon(taxonSlug: string): Promise<BannerLocationMap> {
  const endpoint = resolveApiUrl(API_ENDPOINTS.GetBannersForTaxon(taxonSlug));

  const res = await doFetch(endpoint);

  return transformBanners(await res.json());
}
