import { createSelector, createSlice } from '@reduxjs/toolkit';
import { APP_HYDRATE } from 'redux/actions';
import { FaqArticle, FaqCategory } from 'types/types';
import { RootState } from 'redux/appStore';

export interface FaqState {
  categories: FaqCategory[];
  article: FaqArticle | null;
}

const initialState: FaqState = {
  categories: [],
  article: null,
};

export const faqSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    setCategories: (state, { payload }) => {
      state.categories = payload;
    },
    setArticle: (state, { payload }) => {
      state.article = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(APP_HYDRATE, (clientState, { payload }) => {
      return {
        ...clientState,
        ...payload.faq,
      };
    });
  },
});

export default faqSlice.reducer;
export const { setCategories, setArticle } = faqSlice.actions;

export const slice = (state: RootState) => state.faq;
export const selectCategories = createSelector(slice, (s) => s.categories);
export const selectArticle = createSelector(slice, (s) => s.article);
