import { Product, Variant } from 'types/product';

export const findVariantImageIndex = (
  product: Product | null,
  selectedVariant: Variant | null
): number => {
  if (!selectedVariant || !product) {
    return 0;
  }

  const index = product.images.findIndex((x) => x.variants.includes(selectedVariant.code));

  if (index === -1) {
    return 0;
  }

  return index;
};
