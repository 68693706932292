import fetchRecommendedProducts from 'api/product/fetchRecommendedProducts';
import fetchProduct from 'api/product/fetchProduct';
import fetchProductPreview from 'api/product/fetchProductPreview';
import fetchProductByCode from 'api/product/fetchProductByCode';
import fetchProducts from 'api/product/fetchProducts';
import fetchProductsSearch from 'api/product/fetchProductsSearch';
import fetchProductsByLocation from 'api/product/fetchProductsByLocation';
import fetchProductCities from 'api/product/fetchProductCities';
import fetchAllProductsByLocation from 'api/product/fetchAllProductsByLocation';
import fetchAvailabilitiesForMonth from 'api/product/fetchAvailabilitiesForMonth';
import fetchAvailabilityTimeslotsForDate from 'api/product/fetchAvailabilityTimeslotsForDate';
import fetchSellableProductsById from 'api/product/fetchSellableProductsByIds';
import fetchCatchOfTheDayProduct from 'api/product/fetchCatchOfTheDayProduct';
import { fetchMostSoldProducts } from 'api/product/fetchMostSoldProducts';

const productApi = {
  fetchProduct,
  fetchProductByCode,
  fetchProductPreview,
  fetchProducts,
  fetchRecommendedProducts,
  fetchProductsSearch,
  fetchProductsByLocation,
  fetchAllProductsByLocation,
  fetchProductCities,
  fetchAvailabilitiesForMonth,
  fetchAvailabilityTimeslotsForDate,
  fetchSellableProductsById,
  fetchCatchOfTheDayProduct,
  fetchMostSoldProducts,
};

export default productApi;
