import { doFetch, resolveApiUrl } from 'lib/api';
import API_ENDPOINTS from 'constants/routes/api';
import { transformProductJson } from 'lib/transforms';
import { Product } from 'types/product';
import { throwUnsupportedError } from 'api/utils';

interface AllProductsLocationSearchQuery {
  taxonId?: number | null;
}

export default async function fetchAllProductsByLocation(
  query?: AllProductsLocationSearchQuery
): Promise<Product[]> {
  const endpoint = resolveApiUrl(API_ENDPOINTS.GetProductsByLocation);

  const res = await doFetch(endpoint, {}, query);

  if (!res.ok) throwUnsupportedError(endpoint, res);

  const json = await res.json();
  if (!json.products) return [];

  return (
    json.products
      .filter((p: any) => p !== null)
      .map(transformProductJson)
      .filter(Boolean) || []
  );
}
