import React from 'react';
import { useRouter } from 'next/router';

export function useRouterNavigation() {
  const router = useRouter();

  const navigateOnClick = (url: string, e?: React.MouseEvent<HTMLElement> | MouseEvent) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    return router.push(url);
  };

  return { navigateOnClick };
}
