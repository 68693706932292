import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APP_HYDRATE } from 'redux/actions';
import { Banner, Taxon, TextBanner } from 'types/types';
import { RootState } from 'redux/appStore';
import config from 'next/config';
import { ALL_DEALS_TAXON_ID } from 'constants/taxon';
import { Product } from 'types/product';
import { selectGeolocationSlice } from '../geolocation/geolocation.slice';
import { isAddressWithinRadius } from 'lib/address/isAddressWithinRadius';
import { DISPLAY_NEWSLETTER_SIGNUP_RADIUS } from 'constants/newsletterSubscriptionLocation';
import { resolveClosestAddressToUser } from 'lib/address/resolveClosestAddressToUser';
import { MOST_SOLD_PRODUCTS_MIN_LENGTH } from 'constants/product';
import { selectProductSlice } from 'redux/products/products.slice';

export interface TaxonState {
  textBanner: TextBanner | null;
  banners: {
    primary: Banner[];
    secondary: Banner[];
  };
  taxon: Taxon | null;
  locationSlug: string | null;
  mostSoldProducts: Product[];
}

const initialState: TaxonState = {
  taxon: null,
  textBanner: null,
  banners: {
    primary: [],
    secondary: [],
  },
  locationSlug: null,
  mostSoldProducts: [],
};

export const taxonSlice = createSlice({
  name: 'taxon',
  initialState,
  reducers: {
    setBanners: (state, { payload }) => {
      state.banners = payload;
    },
    setTaxon: (state, { payload }: PayloadAction<Taxon | null>) => {
      state.taxon = payload;
    },
    setLocationSlug: (state, { payload }: PayloadAction<string | null>) => {
      state.locationSlug = payload;
    },
    setMostSoldProducts: (state, { payload }: PayloadAction<Product[]>) => {
      state.mostSoldProducts = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(APP_HYDRATE, (clientState, { payload }) => {
      if (payload.taxon.taxon === null && payload.taxon.locationSlug === null) return;
      return {
        ...clientState,
        ...payload.taxon,
      };
    });
  },
});

export const { setMostSoldProducts, setLocationSlug, setBanners, setTaxon } = taxonSlice.actions;

export default taxonSlice.reducer;

export const slice = (state: RootState) => state.taxon;
export const selectLocationSlug = createSelector(slice, (s) => s.locationSlug);
export const selectTaxon = createSelector(slice, (s) => s.taxon);
export const selectIsAllDealsTaxon = createSelector(
  selectTaxon,
  (taxon) => !!taxon && taxon.id === ALL_DEALS_TAXON_ID
);
export const selectShowLocationSubMenu = createSelector(
  selectTaxon,
  (taxon) => (!!taxon && taxon.hasLocationSupport) || (taxon && taxon.id === ALL_DEALS_TAXON_ID)
);

export const selectTaxonFullTitle = createSelector(selectTaxon, (taxon) => {
  const {
    publicRuntimeConfig: { defaultTitleSuffix },
  } = config();

  return taxon?.name ? `${taxon?.name} | ${defaultTitleSuffix}` : defaultTitleSuffix;
});
export const selectBanners = createSelector(slice, (s) => s.banners);
export const selectPrimaryBanners = createSelector(selectBanners, (s) => s.primary);
export const selectSecondaryBanners = createSelector(selectBanners, (s) => s.secondary);

export const getMostSoldProducts = createSelector(slice, (state) => state.mostSoldProducts);
export const hasGreaterOrEqualMostSoldProducts = createSelector(
  slice,
  (state) => state.mostSoldProducts.length > MOST_SOLD_PRODUCTS_MIN_LENGTH
);

export const selectShowNewsletterSignupBox = createSelector(
  slice,
  selectProductSlice,
  selectIsAllDealsTaxon,
  selectGeolocationSlice,
  (taxonSlice, productsSlice, isAllDealsTaxon, geolocationSlice) => {
    const taxonProducts = productsSlice.data;
    if (
      isAllDealsTaxon ||
      !taxonSlice.taxon ||
      !taxonSlice.taxon?.hasLocationSupport ||
      !taxonProducts.length ||
      !taxonProducts[0].addresses.length ||
      !geolocationSlice.filter.query
    )
      return false;

    return !isAddressWithinRadius(
      geolocationSlice.filter.query,
      resolveClosestAddressToUser(taxonProducts[0].addresses, geolocationSlice.filter.query),
      DISPLAY_NEWSLETTER_SIGNUP_RADIUS
    );
  }
);
