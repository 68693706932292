import { ExtendedProduct } from 'types/types';
import { doFetch, resolveApiUrl } from 'lib/api';
import { transformExtendedProductJson } from 'lib/transforms';
import { captureTypeErrorIfInvalid } from 'lib/error';
import API_ENDPOINTS from 'constants/routes/api';

export type FetchProduct = {
  slug: string | undefined;
};

export async function fetchProduct({ slug }: FetchProduct): Promise<ExtendedProduct | null> {
  const url = resolveApiUrl(API_ENDPOINTS.GetProductBySlug(slug));
  const res = await doFetch(url);

  if (!res.ok) {
    return null;
  }

  const productJson = await res.json();

  const data = transformExtendedProductJson(productJson);

  captureTypeErrorIfInvalid('ExtendedProduct', data);

  return data;
}

export default fetchProduct;
