import { CoordinateType } from 'types/geolocation';

export const isEmpty = (value: string): boolean => {
  return !value || value.length === 0;
};

export const isEqualOrLongerThenMinimumLength = (value: string, minLength: number): boolean => {
  return value.length >= minLength;
};

export const containsNumbers = (value: string): boolean => {
  return /\d/.test(value);
};

export const isNumberBiggerThanMinimumValue = (value: string, minimum: number = 0): boolean => {
  return !(
    isNaN(parseInt(value)) ||
    parseInt(value) < minimum + 1 ||
    parseInt(value) !== Math.round(parseInt(value))
  );
};

export function containsLessThenNAlphaCharacters(value: string, minLength: number) {
  const matches = value.match(/[a-zA-Z]/g);
  if (null === matches) {
    return true;
  }
  return matches.length < minLength;
}

export function validateAlphaString(
  value: string,
  fieldName: string,
  minLength: number,
  errorMessage: string
) {
  const errors: Record<string, string> = {};

  if (
    isEmpty(value) ||
    !isEqualOrLongerThenMinimumLength(value, minLength) ||
    containsNumbers(value) ||
    containsLessThenNAlphaCharacters(value, minLength)
  ) {
    errors[fieldName] = errorMessage;
  }

  return errors;
}

export function validateAlphaNumericString(
  value: string,
  fieldName: string,
  minLength: number,
  errorMessage: string
) {
  const errors: Record<string, string> = {};

  if (isEmpty(value) || !isEqualOrLongerThenMinimumLength(value, minLength)) {
    errors[fieldName] = errorMessage;
  }

  return errors;
}

export function validatePassword(password: string) {
  const minPasswordLength = 7;
  const errors: Record<string, string> = {};

  if (!password || password.length === 0) {
    errors.password = 'app.ui.login.password.empty';
  }

  if (password && password?.length < minPasswordLength) {
    errors.password = 'app.ui.password_too_short';
  }
  return errors;
}

export function validatePasswords(password: string, passwordRepeat: string) {
  let errors = {};

  const passwordError = validatePassword(password as string).password;
  if (passwordError) {
    // @ts-ignore
    errors.password = passwordError;
  }

  if (!passwordRepeat || String(passwordRepeat).length === 0) {
    // @ts-ignore
    errors.passwordRepeat = 'app.ui.login.password.empty';
  }

  if (passwordRepeat !== password) {
    // @ts-ignore
    errors.passwordRepeat = 'app.ui.passwords_do_not_match';
  }

  return errors;
}

export function validateCoordinateInput(
  type: CoordinateType,
  coordinate?: string
): Record<string, string> {
  const errors: Record<string, string> = {};

  const parsedCoordinate = coordinate && parseFloat(coordinate);

  if (!parsedCoordinate || Number.isNaN(parsedCoordinate)) {
    errors.coordinate = 'app.ui.invalid_coordinate';

    return errors;
  }

  if (type === 'latitude' && (parsedCoordinate < -90 || parsedCoordinate > 90)) {
    errors.coordinate = 'app.ui.invalid_latitude';
  }

  if (type === 'longitude' && (parsedCoordinate < -180 || parsedCoordinate > 180)) {
    errors.coordinate = 'app.ui.invalid_longitude';
  }

  return errors;
}

export function isValidCoordinateInput(type: CoordinateType, coordinate?: string): boolean {
  return Object.keys(validateCoordinateInput(type, coordinate)).length === 0;
}
