import ProductClickTag from './ProductClickTag';
import { BareProduct, Customer, Product, Variant } from 'types/types';
import {
  getVariant,
  productInCartDetails,
  productToProductClick,
} from '../../transforms/transforms';
import { TrackingProductList } from 'types/tracking';
import AddToCartClickTag from './AddToCartClickTag';
import UserLoginTag from './UserLoginTag';
import UserRegisterTag from './UserRegisterTag';
import UserDetailsChange from './UserDetailsChange';
import RemoveFromCartClickTag from './RemoveFromCartClickTag';
import ProductImpressionTag from './ProductImpressionTag';
import NewsletterSubscribe from './NewsletterSubscribe';
import AffiliateProductClickTag from 'modules/tracking/tags/productClick/AffiliateProductClickTag';
import PreAddToCartClickTag from 'modules/tracking/tags/productClick/PreAddToCartClickTag';
import { castToVariantOrNull } from 'lib/products/castToVariantOrNull';

export default class ProductClickEventFactory {
  static productClick({
    product,
    productPosition,
    productList,
  }: {
    product: Product;
    productPosition?: number;
    productList?: TrackingProductList;
  }): ProductClickTag {
    const tag = new ProductClickTag();
    tag.clickedProduct = productToProductClick(
      product,
      getVariant(product),
      productPosition,
      productList
    );
    return tag;
  }

  static productImpressions({
    products,
    productList,
    productIndexStart,
  }: {
    products: Array<Product>;
    productList?: TrackingProductList;
    productIndexStart: number;
  }): ProductImpressionTag {
    const tag = new ProductImpressionTag();
    tag.products = products.map((product, index) => {
      return productToProductClick(
        product,
        getVariant(product),
        productIndexStart + (index + 1),
        productList
      );
    });
    return tag;
  }

  static addToCartClick({
    product,
    variant,
    productPosition,
    productList,
    quantity,
  }: {
    product: BareProduct;
    variant: Variant;
    productPosition: number;
    quantity: number;
    productList: TrackingProductList | null;
  }): AddToCartClickTag {
    const tag = new AddToCartClickTag();
    tag.product = productInCartDetails(product, variant, productPosition, quantity, productList);
    return tag;
  }

  static preAddToCartClick({
    product,
    productList,
    productPosition,
  }: {
    product: Product;
    productPosition: number;
    productList?: TrackingProductList;
  }): PreAddToCartClickTag {
    const tag = new PreAddToCartClickTag(product);

    const defaultVariant = castToVariantOrNull(product.defaultVariant) || product.variants[0];

    tag.product = productToProductClick(product, defaultVariant, productPosition, productList);

    return tag;
  }

  static removeFromCartClick({
    product,
    variant,
    productPosition,
    productList,
    quantity,
  }: {
    product: BareProduct;
    variant: Variant;
    productPosition: number;
    quantity: number;
    productList?: TrackingProductList;
  }): RemoveFromCartClickTag {
    const tag = new RemoveFromCartClickTag();
    tag.product = productInCartDetails(
      product,
      variant,
      productPosition,
      quantity,
      productList || null
    );
    return tag;
  }

  static trackUserLogin({ customer }: { customer: Customer }): UserLoginTag {
    const tag = new UserLoginTag();
    tag.userId = customer.customerId.toString();
    return tag;
  }

  static trackUserRegister({ customer }: { customer: Customer }): UserRegisterTag {
    const tag = new UserRegisterTag();
    tag.userId = customer.customerId.toString();
    return tag;
  }

  static trackUserDetailsChange({ customer }: { customer: Customer }): UserDetailsChange {
    const tag = new UserDetailsChange();
    tag.userId = customer.customerId.toString();
    return tag;
  }

  static newsletterSubscribe(location: string): NewsletterSubscribe {
    const tag = new NewsletterSubscribe();
    tag.location = location;
    return tag;
  }

  static affiliateProductClick({ buttonText }: { buttonText: string }): AffiliateProductClickTag {
    return new AffiliateProductClickTag(buttonText);
  }
}
