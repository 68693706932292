import { captureTypeErrorIfInvalid } from 'lib/error';
import { omitUndefinedRecursively } from 'lib/object';
import {
  backendAddressToAddress,
  createAddressBody,
  createAddressFromPostCodeBody,
  makeAddressValue,
  mapAddressResponseToAddress,
  transformPostalCodeJson,
} from 'lib/transforms/address';
import {
  transformBookingJson,
  transformCartJson,
  transformReservationJson,
} from 'lib/transforms/cart';
import { transformUserCoordinatesJson } from 'lib/transforms/location';

import { transformExtendedProductJson, transformProductJson } from 'lib/transforms/product';
import { transformParentTaxon, transformTaxonJson } from 'lib/transforms/taxon';
import config from 'next/config';
import {
  mapOrderSummaryResponseToOrderSummary,
  transformOrderItemForContactPageJson,
  transformOrderItemJson,
  transformOrderItemUnitShipmentTrackingJson,
  transformOrderJson,
  transformOrdersJson,
} from 'lib/transforms/order';
import {
  Banner,
  BannerLocationMap,
  Page,
  Product,
  ReservationCallOnlyInfo,
  ReturnableItem,
  ReturnedUnit,
  TextBanner,
} from 'types/types';

const { publicRuntimeConfig } = config();

export const transformPageJson = (pageJson: any): Page | null => {
  if (!pageJson || pageJson.error) {
    return null;
  }

  const translation = {
    footer: pageJson.footer,
    viewAllProductsLink: pageJson.viewAllProductsLink,
    viewAllProductsTitle: pageJson.viewAllProductsTitle,
    slug: pageJson.slug,
    image: pageJson.image,
    name: pageJson.name,
    content: pageJson.content,
    metaKeywords: pageJson.metaKeywords,
    metaDescription: pageJson.metaDescription,
    nameWhenLinked: pageJson.nameWhenLinked,
    descriptionWhenLinked: pageJson.descriptionWhenLinked,
    breadcrumb: pageJson.breadcrumb,
    title: pageJson.title,
  };

  return {
    id: pageJson.id,
    code: pageJson.code,
    publishAt: pageJson.publishAt,
    breadcrumb: translation.breadcrumb,
    content: translation.content,
    descriptionWhenLinked: translation.descriptionWhenLinked,
    enabled: pageJson.enabled,
    image: translation.image
      ? {
          ...translation.image,
          path: publicRuntimeConfig.pagesImageUrl + translation.image.path,
        }
      : null,
    metaDescription: translation.metaDescription,
    metaKeywords: translation.metaKeywords,
    name: translation.name,
    nameWhenLinked: translation.nameWhenLinked,
    slug: translation.slug,
    title: translation.title,
    footer: translation.footer || '',
    viewAllProductsLink: translation.viewAllProductsLink || '',
    viewAllProductsTitle: translation.viewAllProductsTitle || '',
    products: pageJson.products.map((p: Product) => transformProductJson(p)),
    bottomSidebar: pageJson.bottomSidebar,
  };
};

export const transformReservationCallOnlyInfoJson = (
  reservationCallOnlyInfo: any
): ReservationCallOnlyInfo => {
  return {
    phoneNumber: reservationCallOnlyInfo.phoneNumber,
  };
};

export const transformBanners = (bannersJson: Array<any> | Object): BannerLocationMap => {
  let bannerObject = {};

  // convert old array structure to remain backwards compatible
  if (Array.isArray(bannersJson)) {
    for (const banner of bannersJson) {
      // @ts-ignore
      bannerObject[banner.code] = banner;
    }
  } else {
    bannerObject = bannersJson ?? {};
  }

  const banners: BannerLocationMap = {};
  for (const [position, banner] of Object.entries(bannerObject)) {
    banners[position] = transformBannerJson(banner);
    captureTypeErrorIfInvalid('Banner', banners[position]);
  }

  return banners;
};

export const transformBannerJson = (bannerJson: any): Banner => {
  const mainText = bannerJson.mainText || '';

  const banner = {
    id: bannerJson.id,
    url: bannerJson.url,
    code: bannerJson.code,
    imageSrc: publicRuntimeConfig.imageUrl + '/banner_images/' + bannerJson.path,
    mobileSrc: publicRuntimeConfig.imageUrl + '/banner_images/' + bannerJson.mobilePath,
    imageAlt: bannerJson.imageAlt || mainText,
    mainText: mainText,
  };

  omitUndefinedRecursively(banner);
  return banner;
};

export const transformReturnableItemJson = (result: any): ReturnableItem => {
  return {
    id: result.id,
    name: result.name,
    image: publicRuntimeConfig.imageUrl + '/media/image/' + result.image,
    units: result.units.map((unit: any) => ({
      id: unit.id,
      returnDaysLeft: unit.returnDaysLeft,
    })),
    orderId: result.orderId,
  };
};

export const transformReturnedUnitJson = (result: any): ReturnedUnit => {
  return {
    id: result.id,
    name: result.name,
    image: publicRuntimeConfig.imageUrl + '/media/image/' + result.image,
    returnReason: result.returnReason,
    returnComment: result.returnComment,
    returnStatus: result.returnStatus,
    returnRequestedAt: result.returnRequestedAt,
    canDownloadReturnLabel: result.canDownloadReturnLabel,
    orderTokenValue: result.orderTokenValue,
  };
};

export const transformTextBanner = (result: any): TextBanner => {
  const translation = {
    destinationUrl: result.destinationUrl,
    htmlText: result.htmlText,
  };

  return {
    code: result.code,
    id: result.id,
    locations: result.locations,
    position: result.position,
    destinationUrl: translation.destinationUrl,
    htmlText: translation.htmlText,
  };
};

export {
  transformProductJson,
  transformExtendedProductJson,
  transformUserCoordinatesJson,
  transformOrdersJson,
  transformOrderJson,
  mapOrderSummaryResponseToOrderSummary,
  transformOrderItemJson,
  transformOrderItemForContactPageJson,
  transformOrderItemUnitShipmentTrackingJson,
  backendAddressToAddress,
  createAddressBody,
  createAddressFromPostCodeBody,
  makeAddressValue,
  mapAddressResponseToAddress,
  transformPostalCodeJson,
  transformCartJson,
  transformBookingJson,
  transformReservationJson,
  transformParentTaxon,
  transformTaxonJson,
};
