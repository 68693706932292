import { createStoreProvider, createUseStore } from 'lib/react-with-native-store';
import { Alert, Cart, Customer } from 'types/types';

export type StoreType = {
  cart: Cart | null;
  customer: Customer | null;
  hasShownNewsletterPopup: boolean;
  alerts: Array<Alert>;
  newRegistration: boolean;
  cookiePreferencesModalOpen: boolean;
  callbackUrl: string | null;
  avddHistory: Array<string>;
  forgotPasswordEmail: string | null;
  cartError: string;
  trackedPurchaseEvents: Array<number>;
  searchHistory: string[];
};

export const initialValues: StoreType = {
  cart: null,
  hasShownNewsletterPopup: false,
  customer: null,
  alerts: [],
  newRegistration: false,
  cookiePreferencesModalOpen: false,
  callbackUrl: null,
  avddHistory: [],
  forgotPasswordEmail: null,
  cartError: '',
  trackedPurchaseEvents: [],
  searchHistory: [],
};

export const StoreProvider = createStoreProvider({
  initialValues,
  nonPersistentKeys: ['cartError', 'alerts'],
});
export const useStore = createUseStore(initialValues);
export default useStore;
