import { FaqCategory } from 'types/types';
import { doFetch, resolveApiUrl } from 'lib/api';
import { captureTypeErrorIfInvalid } from 'lib/error';
import API_ENDPOINTS from 'constants/routes/api';

export default async function fetchFaqCategories(
  page: number | null,
  itemsPerPage: number | null,
  channel?: string | null
): Promise<FaqCategory[]> {
  const endpoint = resolveApiUrl(API_ENDPOINTS.GetFaqCategories(page, itemsPerPage));

  const res = await doFetch(endpoint, {
    headers: channel ? { 'x-sylius-channel': channel } : {},
  });

  const categories = await res.json();

  if (!categories || categories.length === 0) {
    return [];
  }

  categories.forEach((c: FaqCategory) => captureTypeErrorIfInvalid('FaqCategory', c));
  return categories;
}
