import { Taxon } from 'types/taxons';
import { createTaxonHref } from 'lib/taxon/taxonUtils';

export function transformParentTaxon(taxonJson?: any): Taxon | undefined {
  if (!taxonJson || typeof taxonJson !== 'object') {
    return undefined;
  }

  return {
    id: taxonJson.id,
    code: taxonJson.code,
    icon: taxonJson.icon || null,
    href: createTaxonHref(taxonJson.slug),
    name: taxonJson.name,
    slug: taxonJson.slug,
    description: taxonJson.description,
    metaDescription: taxonJson.metaDescription,
    enabledChildren: [],
    enabledSiblings: [],
    position: taxonJson.position,
    hasLocationSupport: taxonJson.hasLocationSupport,
    tradeTrackerCategory: taxonJson.tradeTrackerCategory,
    imageUrl: taxonJson.imageUrl || '',
  };
}

export const transformTaxonJson = (taxonJson: any, parent?: Taxon | undefined): Taxon | null => {
  if (!taxonJson || taxonJson.error) {
    console.error({ error: taxonJson?.error });
    return null;
  }

  if (taxonJson === false || taxonJson === null || typeof taxonJson !== 'object') {
    return null;
  }

  let enabledChildren = [];

  parent = parent ? transformParentTaxon(parent) : undefined;

  if (Array.isArray(taxonJson.enabledChildren)) {
    enabledChildren = taxonJson.enabledChildren
      ?.filter((x: any) => !!x && x.isShownInMenu)
      .map((child: any) => transformTaxonJson(child, taxonJson));
  } else if (typeof taxonJson.enabledChildren === 'object' && taxonJson.enabledChildren !== null) {
    enabledChildren = Object.values(taxonJson.enabledChildren)
      .filter((x: any) => !!x && x.isShownInMenu)
      .map((child: any) => transformTaxonJson(child, taxonJson));
  }

  let enabledSiblings = [];

  if (Array.isArray(taxonJson.enabledSiblings)) {
    enabledSiblings = taxonJson.enabledSiblings
      .filter((x: any) => !!x)
      .map((sibling: any) => transformTaxonJson(sibling));
  } else if (typeof taxonJson.enabledSiblings === 'object' && taxonJson.enabledSiblings !== null) {
    enabledSiblings = Object.values(taxonJson.enabledSiblings)
      .filter((x: any) => !!x)
      .map((sibling: any) => transformTaxonJson(sibling, taxonJson.parent));
  }

  const translations = {
    name: taxonJson.name,
    slug: taxonJson.slug,
    description: taxonJson.description,
    metaDescription: taxonJson.metaDescription,
  };

  if (!translations.name || !translations.slug) return null;

  const response: Taxon = {
    id: taxonJson.id,
    code: taxonJson.code,
    icon: taxonJson.icon || null,
    href: createTaxonHref(translations.slug),
    name: translations.name,
    slug: translations.slug,
    description: translations.description,
    metaDescription: translations.metaDescription,
    enabledChildren: enabledChildren,
    enabledSiblings: enabledSiblings,
    position: taxonJson.position,
    hasLocationSupport: taxonJson.hasLocationSupport,
    tradeTrackerCategory: taxonJson.tradeTrackerCategory,
    imageUrl: taxonJson.imageUrl || '',
  };

  if (parent) response.parent = parent;

  return response;
};
