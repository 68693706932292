import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

import commonReducer from 'redux/common/common.slice';
import geolocationReducer from 'redux/geolocation/geolocation.slice';
import geoProductsReducer from 'redux/geolocation/geoProducts.slice';
import productsReducer from 'redux/products/products.slice';
import productPageReducer from 'redux/products/productPage.slice';
import addToCartReducer from 'redux/cart/addToCart.slice';
import reservationReducer from 'redux/cart/reservation.slice';
import homePageReducer from 'redux/homePage/homePage.slice';
import taxonReducer from 'redux/taxon/taxon.slice';
import faqReducer from 'redux/faq/faq.slice';
import commonApi from 'redux/query/common.query';
import cartApi from 'redux/query/cart.query';
import newsletterApi from 'redux/query/newsletter.query';
import productApi from 'redux/query/product.query';
import orderApi from 'redux/query/order.query';

const makeStore = () =>
  configureStore({
    reducer: {
      common: commonReducer,
      geolocation: geolocationReducer,
      geoProducts: geoProductsReducer,
      products: productsReducer,
      productPage: productPageReducer,
      addToCart: addToCartReducer,
      reservation: reservationReducer,
      homePage: homePageReducer,
      taxon: taxonReducer,
      faq: faqReducer,
      [commonApi.reducerPath]: commonApi.reducer,
      [cartApi.reducerPath]: cartApi.reducer,
      [orderApi.reducerPath]: orderApi.reducer,
      [productApi.reducerPath]: productApi.reducer,
      [newsletterApi.reducerPath]: newsletterApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ thunk: true })
        .concat(commonApi.middleware)
        .concat(cartApi.middleware)
        .concat(orderApi.middleware)
        .concat(productApi.middleware)
        .concat(newsletterApi.middleware),
  });

export const appStore = makeStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type AppStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<AppStore['getState']>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof appStore.dispatch;

// Use throughout app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export type AppThunk<ReturnType = Promise<void>> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action
>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const wrapper = createWrapper<AppStore>(makeStore);
