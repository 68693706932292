import { Coordinates } from 'types/geolocation';
import { areFloatsApproximatelyEqual } from 'lib/numbers';

export default function areCoordinatesApproximatelyEqual(
  first: Coordinates,
  second: Coordinates
): boolean {
  return (
    areFloatsApproximatelyEqual(first.latitude, second.latitude) &&
    areFloatsApproximatelyEqual(first.longitude, second.longitude)
  );
}
